import React, {useEffect, useState} from 'react'
import ErrorMessage from "./errorMessage";
import {useAppSelector} from "../../hooks/useStore";
import Select from 'react-dropdown-select';
import Magnify from '../../assets/images/icons/magnify-icon.svg'
import {useSelector} from "react-redux";
import {getCurrentLocation} from "../../redux/slices/locationSlice";

const CallCodeInput = ( props:any )=> {

    const { country }:any = useAppSelector((state) => state.common);
    const currentLocation = useSelector(getCurrentLocation)
    const [callCode, setCallCode] = useState<any>({});

    const currentCallCode = () => {
        let currentCountry = country?.find((item: any) => item.name === currentLocation.dialCode)
        setCallCode(currentCountry);
    }

    useEffect(() => {
        if (currentLocation && currentLocation?.dialCode && country && Array.isArray(country)) currentCallCode();
    }, [country]);

    return <>

        <Select
            values={callCode ? [callCode] : []}
            options={country}
            labelField='name'
            searchBy='searchText'
            searchable={true}
            onChange={(values: any) => {
                props?.onChange(values)
            }}
            contentRenderer={({props, state}:any) => {
                return (
                    <div className="flex items-center justify-between">
                        <div className="mr-2 border-solid border-[0.5px] border-[#919191] w-[30px]">
                            <img
                                alt={state?.values[0]?.name}
                                src={state?.values[0]?.flag}
                                className="w-full h-full"
                            />
                        </div>
                        <div>{state?.values[0]?.name}</div>
                    </div>
            )
            }}

            dropdownRenderer={
                ({ props:innerProps, state, methods }) => {
                    const regexp = new RegExp(state.search, 'i');
                    return (
                        <>
                            <div className='country-search'>
                                <div className='country-search--inner'>
                                    <input
                                    className='w-full h-full'
                                    type="text"
                                    value={state.search}
                                    onChange={methods.setSearch}
                                    />
                                    <img src={Magnify} className='p-[4px]' alt=""/>
                                </div>
                            </div>
                            {innerProps.options
                                .filter((item: any) => regexp.test(item.searchText || item[props.labelField]))
                                .map((option: any) => {
                                    return (
                                        // @ts-ignore
                                        <div key={option.value} onClick={() => methods.addItem(option)} className="flex align-items-center w-[282px] py-[12px] px-[20px]">
                                            <div className='truncate mr-[10px]'>{option.country}</div>
                                            <div>{option.name}</div>
                                        </div>
                                    )
                                })
                            }
                        </>
                    )
                }
            }
            className="aria-react-dropdown"
            />
            {props?.errors && props?.touched ? (
                <ErrorMessage text={props?.errors}/>
            ) : null}
        </>
        }

export default CallCodeInput;