import { SplideSlide } from "@splidejs/react-splide";
import { useEffect } from "react";
import CarouselWrapper from "../../../components/CarouselWrapper";
import { Link, useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mantine/hooks";
import { useTranslation } from "react-i18next";
import DangerouslySetInnerHTML from '../../../components/DangerouslySetInnerHTML';
import formatDateStartEnd from "../../../utils/formatDateStartEnd";

interface Event {
  _id: string
  organisation_id: any;
  event_category_id: any
  event_occurrence_id: any
  event_viewability_type_id: any
  event_timezone_id: any
  event_venue_id: any
  start_date: string
  start_time: string
  end_date: string
  end_time: string
  slug: string
  cover_image_mobile: string
  cover_image_desktop: string
  view_count: number
  title: string
  description: string
  status: boolean
  createdAt: string
  updatedAt: string
  __v: number
}

export default function UpcomingEventCarousel({ data, show, max }: { data: Array<Event>, show: boolean, max: number }) {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width: 1024px)");
  const navigate = useNavigate()


  useEffect(() => {
    const container = document.getElementById("upcoming-event");
    if (!container) return;

    const leftArrow = container.querySelector(".splide__arrow--prev");
    const rightArrow = container.querySelector(".splide__arrow--next");

    const handleMouseMove = (e: MouseEvent) => {
      const threshold = 133;
      if (e.target instanceof HTMLElement) {
        if (e.x <= threshold || e.target.closest(".is-prev")) {
          leftArrow?.setAttribute("style", "background-color: rgba(33,31,46,0.9)");
        } else if (
            e.x >= container.offsetWidth - threshold ||
            e.target.closest(".is-next")
        ) {
          rightArrow?.setAttribute("style", "background-color: rgba(33,31,46,0.9)");
        } else {
          leftArrow?.setAttribute("style", " background-color: rgba(33,31,46,0.6)");
          rightArrow?.setAttribute("style", "background-color: rgba(33,31,46,0.6)");
        }
      }
    };
    container?.addEventListener("mousemove", handleMouseMove);

    return () => {
      container.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  if (!show || data.length < 1) return null

  return (
      <div
          className="bg-[#15141E] py-14 carousel--centered carousel--light"
          id="upcoming-event"
      >
        <div className="container mx-auto xl:max-w-[1080px] max-w-[calc(100%-68px)] ">
          <h3 className="text-[26px] sm:text-[34px] font-semibold text-center text-ar-purple">
            {t('UpcomingEventsHeader')}
          </h3>
        </div>

        {<CarouselWrapper
            options={{
              type: "loop",
              // perPage: 3,
              // padding:
              //   (window.innerWidth -
              //     (window.innerWidth <= 1024 ? (80 / 100) * 874 : 874) -
              //     70 * 2) /
              //   2,
              // padding: window.innerWidth - 874 - 70 * 2,
              // padding: '10%',
              focus: "center",
              autoWidth: true,
              gap: "70px",
              breakpoints: {
                // 1024: {
                //   // gap: 40,
                //   // focus: "left",
                // },
                640: {
                  gap: 30,
                  arrows: false,
                  autoWidth: false,
                  padding: 0,
                },
              },
            }}
            controlStyle="dark"
            customClass="custom-slider--centered"
        >
          {data
              .map((event, i) => (
                  <SplideSlide key={i}>
                    <div className="sm:mt-14 pb-[50px] sm:pb-[70px] sm:w-[640px] xl:w-[874px] cursor-pointer" onClick={() => navigate(`/events/${event.slug}`)}>
                      <div className="items-center justify-between block p-[34px] sm:p-10 pb-0 transition duration-700 sm:shadow-[0_9px_10px_3px_rgba(0,0,0,0.50)] sm:bg-ar-black sm:flex hover:sm:scale-105 w-full">
                        <div className=" text-center sm:text-left flex-1 basis-[40%] grow-1 shrink-0 sm:pr-8">
                          <h3 className=" font-bold text-[20px] text-ar-purple">
                            {event.title}
                          </h3>
                          <div className="mt-1 text-white">
                            <b> {formatDateStartEnd(event.start_date, null)}</b>
                            <DangerouslySetInnerHTML
                                classNames={'break-words editor-formatting overflow-hidden line-clamp-6 max-h-[144px]'}
                                htmlContent={event.description}
                            />
                          </div>
                        </div>
                        <div className="flex-1 shrink-0 aspect-square sm:min-w-[300px] xl:min-w-[437px] bg-slate-300 mt-8 sm:mt-0 relative grow-0">
                          <img
                              src={event.cover_image_desktop}
                              alt=""
                              className="basis-[60%] grow-0 shrink max-w-full w-full h-full object-cover aspect-square"
                          />
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
              ))}
        </CarouselWrapper>}

        <div className="mt-8 text-sm text-center sm:mt-14">
          <Link
              to={`/events`}
              className="bg-ar-primary border-ar-primary py-[13px] px-[19px] border rounded-full hover:opacity-60 transition-all"
          >
            {t('ViewMoreEventsButton')}
          </Link>
        </div>
      </div>
  );
}
