import React, {useState} from 'react';
import AriaLogoWhite from '../assets/images/aria-logo-white.svg';
import Ig from '../assets/images/icons/ig.svg';
import Tiktok from '../assets/images/icons/tiktok.svg';
import Fb from '../assets/images/icons/fb.svg';
import twitter from '../assets/images/icons/twitter.svg';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import Modal from "./Modal";



const Footer = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const [customerCareModal, setCustomerCareModal] = useState(false);
    return (
        <div className='footer text-white px-[36px] pt-[36px] pb-[52px]'>
            <div className='max-w-[1080px] mx-auto flex flex-col'>
                <div className='mb-[26px]'>
                    <img className='cursor-pointer' src={AriaLogoWhite} alt="" />
                </div>


                <div className='flex flex-col md:flex-row text-[14px] md:text-[16px] mb-[42px]'>
                    <div className='flex flex-row md:flex-col items-center md:items-start mb-[32px] md:mr-[50px]'>
                        <div className='mr-[29px] md:mb-[23px] cursor-pointer'>{t('LetsConnectHeader')}</div>
                        <div className='flex'>
                            <a href="https://www.instagram.com/witharia.co" target="_blank" className='mr-[28px] cursor-pointer'><img src={Ig} alt="" className='h-[27px] w-[27px] md:h-[29px] md:w-[29px] ' /></a>
                            <a href="https://www.tiktok.com/@witharia.co" target="_blank" className='mr-[28px] cursor-pointer'><img src={Tiktok} alt="" className='h-[27px] w-[27px] md:h-[29px] md:w-[29px] ' /></a>
                            <a href="https://twitter.com/witharia_co" target="_blank" className='mr-[28px] cursor-pointer'><img src={twitter} alt="" className='h-[27px] w-[27px] md:h-[29px] md:w-[29px] ' /></a>
                        </div>
                    </div>
                    <div className='flex'>
                        <div className='mr-[80px]'>
                            {/*<h5 className='mb-[25px] md:mb-[22px] cursor-pointer'>{t('CorporateHeader')}</h5>*/}
                            <ul className='list'>
                                <li onClick={() => navigate('/about-us')}>{t('AboutUsSubHeader')}</li>
                                {/*<li>{t('MediaSubHeader')}</li>*/}
                                {/*<li>{t('CareersSubHeader')}</li>*/}
                                {/*<li>{t('FestivalInfoSubHeader')}</li>*/}
                            </ul>
                        </div>
                        <div>
                            <h5 onClick={()=> setCustomerCareModal(true)} className='mb-[25px] md:mb-[22px] cursor-pointer'>{t('CustomerCareHeader')}</h5>
                            <ul className='list'>
                                {/*<li>{t('ContactUsSubHeader')}</li>*/}
                                {/*<li onClick={()=> navigate('/term-and-condition')}>{t('TermsOfUseSubHeader')}</li>*/}
                                {/*<li>{t('NewsSubHeader')}</li>*/}
                                {/*<li>{t('FAQSSubHeader')}</li>*/}
                            </ul>
                        </div>
                    </div>
                </div>


                <div>
                    <hr />
                    <div className='flex flex-col md:flex-row justify-between text-[12px] pt-[31px] md:pt-[11px]'>
                        <div className='mb-[30px] md:mb-0 flex md:justify-between flex-wrap md:min-w-[170px]'>
                            {/*<div className='cursor-pointer'>{t('PurchasePolicyHeader')}</div>*/}
                            {/*<div className='mx-[7.5px]'>|</div>*/}
                            <div className='cursor-pointer' onClick={() => navigate('/privacy-policy')}>{t('PrivacyPolicyHeader')}</div>
                            <div className='mx-[7.5px]'>|</div>
                            <div className='cursor-pointer' onClick={() => navigate('/term-and-condition')}>{t('TermsAndConditionsHeader')}</div>
                        </div>
                        <div>
                            <span>{t('AllRightsReserved')}</span>
                        </div>
                    </div>
                </div>
            </div>
            {customerCareModal &&
                <Modal
                    backdrop={false}
                    width={'max-w-[480px]'}
                    className={'customer-care'}
                    showModal={customerCareModal}
                    setShowModal={(bool: boolean) => {
                        setCustomerCareModal(bool)
                    }}
                    footer={false}
                    header={true}
                    closeBtnPosition={'right-[15px] top-[15px] md:right-[34px] md:top-[31px]'}
                    showCloseBtn={true}
                    // noModalPadding={noModalPadding}
                    borderRadius={'md:rounded-[15px]'}
                >
                    <div className="bg-ar-black md:p-[50px] md:pb-[35px] p-[20px] pt-[40px]">
                        <p>Please email us at <a className='text-ar-primary' href="mailto:admin@witharia.co">admin@witharia.co</a> if you have any problems.</p>
                    </div>
                </Modal>
            }
        </div>
    )

}

export default Footer;