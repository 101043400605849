import { Link, useLocation, useMatches } from "react-router-dom";

interface PageObject {
  label?: string;
  url?: string;
}

interface Props {
  color?: string;
  detailInfo?: PageObject;
}

export default function BreadCrumb({ detailInfo, color }: Props) {
  let matches = useMatches();
  let model = matches
    .filter((match: any) => Boolean(match.handle?.crumb))
    .map((match: any) => {
      return match.handle?.crumb?.needOverwrite
        ? detailInfo
        : match.handle?.crumb;
    });

  return (
    <ul className="flex items-center">
      <BreadCrumbItem
        withSeparator
        color={color}
        page={{ label: "Home", url: "/" }}
      />

      {model && model.length
        ? model.map((page, i) => (
            <BreadCrumbItem
              page={page}
              color={color}
              withSeparator={i + 1 !== model.length}
              key={i}
            />
          ))
        : null}
    </ul>
  );
}

const BreadCrumbItem = ({
  color,
  page,
  withSeparator = true,
}: {
  color?: string;
  page: PageObject;
  withSeparator?: boolean;
}) => {
  const location = useLocation();

  return (
    <li
      className={`flex items-center text-xs font-normal capitalize`}
      style={{
        color: color ? color : "#9B8BFF",
      }}
    >
      {page?.url && location.pathname !== page?.url ? (
        <Link to={page?.url ?? ""}>{page.label}</Link>
      ) : (
        <span>{page?.label}</span>
      )}
      {withSeparator && <span className="mx-2">/</span>}
    </li>
  );
};
