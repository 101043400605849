import React, {useEffect, useState} from 'react';
import {FileUpload} from "primereact/fileupload";
import {useAppDispatch, useAppSelector} from "../../hooks/useStore";
import {useNavigate} from "react-router-dom";
import {uploadProfileImage} from "../../utils/apiRequestList/profileService";
import {setProfileInfo} from "../../redux/slices/authSlice";
import Pencil from "../../assets/images/icons/pencil.png";
import Location from "../../assets/images/icons/location-profile.png"
import EventPanel from "./components/EventPanel";
import {getCustomerAttendancesEvent} from "../../utils/apiRequestList/authServices";
import moment from "moment/moment";
import AllEventsAttendances from "./components/AllEventsAttendances";

const SProfile = (props:any) => {
    const navigate = useNavigate();
    const { user }: any = useAppSelector((state) => state.userAuth);
    const dispatch = useAppDispatch();
    const [profileImage, setProfileImage] = useState<any>(user?.profile_image_url ? user?.profile_image_url : '' );
    const [socialImages, setSocialImages] = useState<any>([]);
    const [upcomingAttendances, setUpcomingAttendances] = useState<any>([])
    const [viewAllEvents, setViewAllEvents] = useState(false)
    const [month, setMonth] = useState<any>()
    const [latestUpComingEvent, setLatestUpComingEvent] = useState<any>()

    // upload profile image START
    const editBtnStyle= {
        'backgroundColor':'#15141E',
        'backgroundImage': `url(`+Pencil+`)`,
        'backgroundRepeat': 'no-repeat',
        'backgroundPosition': '50%'
    }

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'edit-btn w-[30px] h-[30px] rounded-[50%] p-[0]', style: editBtnStyle };
    const uploadProfileImageHandle = async (value:any) => {
        console.log('uploadProfileImageHandle', value)
        try{
            const response = await uploadProfileImage (value)
            if (response?.data?.data && response?.data?.success) {
                // toast.success('Uploaded')
                dispatch(setProfileInfo(response?.data?.data))
            }
        }catch (e){
            console.log(e)
        }
    }
    // END

    // Upcoming Attendances START
    const getAttaendacesEvents = async () => {
        try {
            const {data} = await getCustomerAttendancesEvent();
            console.log('data?.data',data?.data)
            if(data?.success && data?.data) {
                setUpcomingAttendances(data?.data)
            }
        } catch (e) {
            console.log(e)
        }
    }
    const formattedMonth = (date:any) => {
        return moment(date).format("MMM");
    }
    const formattedDay = (date:any) => {
        return moment(date).format("D")
    }
    useEffect(() => {
        if (upcomingAttendances && upcomingAttendances.eventByDate && Object.keys(upcomingAttendances.eventByDate).length > 0) {
            const firstKey = Object.keys(upcomingAttendances.eventByDate)[0];
            const firstEvent = upcomingAttendances.eventByDate[firstKey][0];
            setLatestUpComingEvent(firstEvent);
            setMonth(Object.keys(upcomingAttendances.eventByDate))
        }
    }, [upcomingAttendances]);
    // END


    useEffect(() => {
        getAttaendacesEvents();
    }, []);


    return (
        <>
            <div className='w-full py-[24px] md:py-[15px] px-[18px] md:pt-[32px] md:pr-[52px] md:pb-[68px] md:pl-[35px]'>
                <div className='mb-[15px] flex'>
                    {/*<div className='relative w-[120px] h-[120px]'>*/}
                    {/*    <img className='object-cover w-full h-full rounded-[50%]  border-solid border-[#9B8BFF] border-[1.5px]'*/}
                    {/*         src={profileImage ? profileImage : 'https://placehold.co/100x100/png'} alt=""*/}
                    {/*    />*/}
                    {/*    <FileUpload mode="basic" name="profilePicture" className='absolute bottom-[5%] right-0'*/}
                    {/*                url={'https://primereact.org/api/upload/'}*/}
                    {/*                accept="image/*" maxFileSize={5000000}*/}
                    {/*                chooseOptions={chooseOptions}*/}
                    {/*                onUpload={async (e: any) => {*/}
                    {/*                    const file = e.files[0];*/}
                    {/*                    if (file) {*/}
                    {/*                        const reader = new FileReader();*/}
                    {/*                        let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url*/}
                    {/*                        reader.readAsDataURL(blob);*/}
                    {/*                        reader.onloadend = function () {*/}
                    {/*                            const base64data = reader.result;*/}
                    {/*                            setProfileImage(base64data);*/}
                    {/*                        };*/}
                    {/*                        uploadProfileImageHandle(file);*/}
                    {/*                    }*/}
                    {/*                }}*/}
                    {/*                auto chooseLabel="Upload"*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className='md:max-w-[250px]'>
                        <p className='text-[26px] font-[600] leading-[28px] mb-[8px]'><span>{user?.first_name} </span><span>{user?.last_name}</span></p>
                        <p className='flex items-center'>
                            <span className='max-w-[10px] mr-[7px] '><img className='w-full' src={Location} /></span>
                            <span className='text-[14px] text-[#7E7E7E] leading-[21px]'>{user?.country_id?.name}</span>
                        </p>
                        {/*<p className='text-[14px] leading-[21px]'>Music Lover, Art Director, Festival Goer.*/}
                        {/*    Big on living life to the fullest! </p>*/}
                    </div>
                </div>

                <hr className="h-px mb-[16px] bg-[#7e7e7e] border-0" />

                <div className='body'>
                    {!viewAllEvents
                        ?
                        <div className={`mb-[15px] flex flex-col w-full ${upcomingAttendances.totalCount > 0 ? 'md:max-w-[392px]' : '' }`}>
                            <div className='flex justify-between mb-[20px]'>
                                <p className='font-bold'>Upcoming Attendances</p>
                                {upcomingAttendances.totalCount > 1 &&
                                    <p className='text-ar-purple text-[14px] cursor-pointer' onClick={()=> setViewAllEvents(true)}>See All</p>
                                }
                            </div>

                            {upcomingAttendances.totalCount
                                ?
                                <div className='flex cursor-pointer'>
                                    <div className='min-w-[320px] mr-[10px] '>
                                        <div className='flex mb-[15px] rounded-[10px] overflow-hidden'
                                             onClick={() => navigate(`/events/${latestUpComingEvent.slug}`)}>
                                            <div className='p-[15px] bg-ar-purple min-w-[64px]'>
                                                {/*<p className='text-center font-bold text-[26px] leading-[26px] mb-[5px]'>{formattedDay(month[0].split(',')[1])}</p>*/}
                                                <p className='text-center font-bold text-[26px] leading-[26px] mb-[5px]'>{latestUpComingEvent && formattedDay(latestUpComingEvent.start_date)}</p>
                                                <p className='text-center leading-[14px] uppercase'>{latestUpComingEvent && formattedMonth(latestUpComingEvent.start_date)}</p>
                                            </div>
                                            <div
                                                className='py-[15px] px-[23px] pr-[8px] bg-[#332B64] overflow-hidden leading-[26px] w-full flex flex-col justify-center'>
                                                <p className='font-bold text-[14px] leading-[26px] truncate mb-[5px]'>{latestUpComingEvent && latestUpComingEvent.title}</p>
                                                <p className='text-[14px] text-[#7E7E7E] flex items-center leading-[14px]'>
                                                    <span className='min-w-[10px] max-w-[10px]'><img className='mt-[-2px] w-full' src={Location}/></span>
                                                    <p className='truncate pl-[7px]'>{latestUpComingEvent && latestUpComingEvent.venue_name.name}</p>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    {(upcomingAttendances.totalCount > 1) &&
                                        <div
                                            className='relative hidden md:block min-w-[64px] w-full mb-[15px] rounded-[10px] overflow-hidden cursor-pointer'
                                            onClick={() => setViewAllEvents(true)}>
                                            <div
                                                className='absolute w-full h-full flex justify-center items-center bg-black bg-opacity-65'>
                                                <span>+{upcomingAttendances.totalCount - 1}</span>
                                            </div>
                                            <div
                                                className='flex flex-col w-full h-full justify-center items-center  bg-ar-purple'>
                                                {/*<p className='text-center font-bold text-[26px] leading-[26px] mb-[5px]'>{latestUpComingEvent && formattedDay(latestUpComingEvent.start_date)}</p>*/}
                                                {/*<p className='text-center leading-[14px] uppercase'>{latestUpComingEvent && formattedMonth(latestUpComingEvent.start_date)}</p>*/}
                                            </div>
                                        </div>
                                    }
                                </div>
                                :

                                <div className='text-center'>No Upcoming Attendances</div>
                            }
                        </div>
                        :
                        <div>
                            <AllEventsAttendances
                                back={(v: any) => setViewAllEvents(v)}
                                month={viewAllEvents ? month : ''}
                                upcomingAttendances={viewAllEvents ? upcomingAttendances : ''}
                            />
                        </div>
                    }
                </div>

                {/*<div className="mb-[15px]">
                    <p>Memories</p>
                    <div className="card">
                        {
                            socialImages.map((item: any, index:number) => {
                                return (
                                    <img src={item} alt="" className='w-[100px]' key={index}/>
                                )
                            })
                        }
                        {
                            socialImages.length < 3 &&
                            <FileUpload mode="basic" name="profilePicture"
                                        url={'https://primereact.org/api/upload/'}
                                        accept="image/*" maxFileSize={5000000}
                                        onUpload={async (e: any) => {
                                            const file = e.files[0];
                                            console.log('file', file)
                                            if (file) {
                                                const reader = new FileReader();
                                                let blob = await fetch(file.objectURL).then((r) => r.blob()); //blob:url
                                                reader.readAsDataURL(blob);
                                                reader.onloadend = function () {
                                                    const base64data = reader.result;
                                                    setSocialImages((prev: any) => [...prev, base64data]);
                                                    console.log(socialImages.length)
                                                };
                                            }
                                        }}
                                        auto chooseLabel="Upload"
                            />
                        }
                    </div>
                </div>

                <div className="mb-[15px]">
                    <p className='cursor-pointer' onClick={() => navigate("/my-tickets")}>My Ticket Page</p>
                    <p className='cursor-pointer' onClick={() => navigate("/profile")}>My Profile</p>
                </div>*/}

            </div>
        </>
    )
}

export default SProfile;