import { get, post } from '../AxiosRequest'

const getEventTicketList = async (slug: string, promoCode: string = '', initialCall: boolean, dTwo: string = '', dOne: string = '') => {
    return await get(`/event/public/${slug}/ticket-list?${promoCode ? '&promoCode=' + promoCode : ''}${initialCall ? '&initialCall=1' : ''}${(dTwo && dOne) ? '&dTwo=' + encodeURI(dTwo) + '&dOne=' + encodeURI(dOne) : ''}`)
}

const prepareCheckout = async (slug: string, payload: object) => {
    return await post(`event/public/${slug}/prepare-checkout`, payload);
};

const ticketSummary = async (slug: any, params: any) => {
    return post(`/event/public/${slug}/order-summary`, params)
}

const purchasedCustomContent = async (slug: string) => {
    return await get(`/event/public/${slug}/checkout-ticket-custom-content`);
}

const policyCustomContent = async (slug: string) => {
    return await get(`/event/public/${slug}/policy-custom-content`);
}

const receiptContent = async (id: string) => {
    return await get(`/event/purchase/${id}`);
}

const releaseCheckoutTickets = async () => {
    return post(`/event/public/release-checkout-tickets`, {})
}

export {
    getEventTicketList,
    prepareCheckout,
    ticketSummary,
    purchasedCustomContent,
    releaseCheckoutTickets,
    receiptContent,
    policyCustomContent
}