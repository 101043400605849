import React from 'react';
import axios from "axios";
import { setLoggedIn } from '../../redux/slices/commonSlice';
import { useAppDispatch } from '../../hooks/useStore';

import ExceptionToast from "./ExceptionToast";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ExceptionHandler = () => {
    const dispatch = useAppDispatch();

    axios.interceptors.response.use(response => {
            return response;
        },
        error => {
            if(error.response && !error.response?.data?.hide) {
                const { status } = error.response;
                if (status === 400) {
                    toast(<ExceptionToast message={error.response?.data?.message}/>)
                }else if (status === 401) {
                    dispatch(setLoggedIn(true));
                } else if (error.response?.status === 403 && (typeof error.response?.data?.message === 'string')) {
                    toast(<ExceptionToast message={error.response?.data?.message}/>)
                } else if ((typeof error.response?.data?.message === 'string' || typeof error.response?.data?.data === 'string' || typeof error.response?.data?.data === 'object') && !error.response?.data?.hide) {
                    toast(<ExceptionToast message={error.response?.data?.message}/>)
                }
            }
            // if (error.response?.data?.errors[0]?.msg){
            //     toast(<ExceptionToast message={error.response?.data?.errors[0]?.msg}/>)
            // }
            return Promise.reject(error);
        });

    return <>
        <div className='aria-toast-wrapper'>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeButton={true}
                closeOnClick={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                className="aria-toast"
                // progressClassName={() => "aria-progress-bar"}
            />
        </div>
    </>
}

export default ExceptionHandler