import { useTranslation } from "react-i18next";

const PaymentDetails = (props: any) => {
    const { t } = useTranslation()
    const { payment_method, payment_status } = props
    return <>
        <div className="text-[16px] text-[#CACACA] font-[400] font-['Nunito'] mb-[13px] ml-[9px]">{t('PaymentDetails')}</div>
        <div className="p-[16px] md:p-[20px] rounded-[12px] border-solid border-[#9B8BFF] border-[0.8px] flex flex-col gap-y-[20px] mb-[20px]">
            <div className="flex justify-start items-center flex-nowrap gap-x-[10px] text-[14px] text-[#919191] font-[400] font-['Nunito'] leading-3">
                <div>{t("PaymentMethod")} :</div>
                <div className='text-[#FFFFFF] capitalize'>{
                    payment_method ? payment_method : '-'
                }</div>
            </div>

            <div className="flex justify-start items-center flex-nowrap gap-x-[10px] text-[14px] text-[#919191] font-[400] font-['Nunito'] leading-3">
                <div>{t('PaymentStatus')} :</div>
                <div className='text-ar-cyan capitalize'>{
                    payment_status ? payment_status : '-'
                }</div>
            </div>
        </div>
    </>
}

export default PaymentDetails;