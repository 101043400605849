import mmddyyyyTimeFormat from "../../utils/mmddyyyyTimeFormat";
import { useTranslation } from "react-i18next";

const OrderSummary = (props: any) => {
    const { t } = useTranslation()
    const { order_id, order_date } = props;

    return <>
        <div className="text-[16px] text-[#CACACA] font-[400] font-['Nunito'] mb-[13px] ml-[9px]">{t("OrderSummary")}</div>
        <div className="p-[16px] md:p-[20px] rounded-[12px] border-solid border-[#9B8BFF] border-[0.8px] flex flex-col gap-y-[20px] mb-[20px]">
            <div className="flex justify-between items-center flex-nowrap gap-x-[10px] text-[14px] text-[#919191] font-[400] font-['Nunito'] leading-3">
                <div>{t('OrderNumber')}</div>
                <div className='text-ar-cyan'>{order_id ? order_id : '-'}</div>
            </div>
            <div className="flex justify-between items-center flex-nowrap gap-x-[10px] text-[14px] text-[#919191] font-[400] font-['Nunito'] leading-3">
                <div>{t('TransactionDate')}</div>
                {order_date && <div className='text-[#FFFFFF]'>{order_date ? mmddyyyyTimeFormat(order_date) : '-'}</div>}
            </div>
        </div>
    </>
}

export default OrderSummary;