import { twMerge } from "tailwind-merge";
import Play from "../../assets/images/icons/Play";
import NoIMage from "../../assets/images/no-image.png";

export default function Player({
  className,
  artist = "Carlcox",
  song = "Song name",
}: {
  className?: string;
  artist?: string;
  song?: string;
}) {
  const classes = twMerge(
    "flex items-center gap-3 p-2 mx-auto overflow-hidden text-white rounded-full bg-ar-primary",
    className
  );

  return (
    <div className={classes}>
      <img
        src={NoIMage}
        alt=""
        className="w-[51px] h-[51px] rounded-full object-cover"
      />
      <div className="flex items-center justify-between flex-1">
        <div className="text-sm">
          <h4 className="font-bold">{song}</h4>
          <p className=" mr-3">{artist}</p>
        </div>
        <div className="mr-2 cursor-pointer">
          <Play />
        </div>
      </div>
    </div>
  );
}
