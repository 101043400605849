const langInfo: any = [
	{
		value: 'en',
		name: 'English',
	},
	{
		value: 'zh',
		name: '简体中文',
	},
	{
		value: 'ja',
		name: '日本語',
	},
	//add new object here if there is new language require
]

export default langInfo;