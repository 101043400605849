export default function Play() {
  return (
    <svg
      width={35}
      height={35}
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="17.5" cy="17.5" r="17.5" fill="#211F2E" />
      <path
        d="M15.2458 24.7384C14.975 24.9189 14.6997 24.928 14.4198 24.7655C14.1399 24.603 14 24.3592 14 24.0342V11.8467C14 11.5217 14.1399 11.278 14.4198 11.1155C14.6997 10.953 14.975 10.962 15.2458 11.1425L24.8333 17.2634C25.0861 17.4259 25.2125 17.6516 25.2125 17.9405C25.2125 18.2293 25.0861 18.455 24.8333 18.6175L15.2458 24.7384ZM15.625 22.5717L22.9104 17.9405L15.625 13.3092V22.5717Z"
        fill="white"
      />
    </svg>
  );
}
