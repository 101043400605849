import React, { useEffect } from "react";
import { useAppDispatch } from "../../hooks/useStore";
import { setCurrentLocation } from "../../redux/slices/locationSlice";
import { setCurrentLanguage } from "../../redux/slices/commonSlice";
import { get } from "../../utils/AxiosRequest";
import { countries } from "../../utils/apiRequestList/authServices";
import clm from "country-locale-map";
import langInfo from "../../utils/langInfo";
import i18n from "../../languages/i18n";

const LocationHandler = () => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const [response, dialCodeResponse] = await Promise.all([
                    get(`/country/info`),
                    countries(),
                ])
                let dialCode:any = []
                dialCodeResponse?.data?.data.forEach((item:any) => {
                    if (response.data.data.countryCode === item.code){
                        dialCode.push(item.dial_code)
                    }
                })
                dispatch(setCurrentLocation(
                    {
                        ...response.data,
                        dialCode: dialCode.toString()
                    },

                ));
                window.localStorage.setItem("currentLocation", response.data.data.countryCode);
                const lsLanguage:any = window.localStorage.getItem("currentLanguage");
                if (!lsLanguage && response?.data?.data?.countryCode) {
                    const clmValue = clm.getCountryByAlpha2(response.data.data.countryCode)
                    const mainLanguage: string = clmValue?.languages[0]?.toLowerCase() ?? ''
                    const supportedLang = langInfo.map((obj:{value:string}) => obj?.value)
                    const toLang: string = supportedLang.includes(mainLanguage) ? mainLanguage : 'en'
                    dispatch(setCurrentLanguage('en'))
                    i18n.changeLanguage('en');
                    // dispatch(setCurrentLanguage(toLang)) // todo uncomment this when we want to unlock localization
                    // i18n.changeLanguage(toLang); // todo uncomment this when we want to unlock localization
                }

            } catch (error) {
                console.error(error)
            }
        };

        fetchData();
    }, [dispatch]);

    return <></>
}

export default LocationHandler;