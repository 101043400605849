import React, { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useAppDispatch} from "../../../hooks/useStore";
import {setLoading} from "../../../redux/slices/commonSlice";
import {get} from "../../../utils/AxiosRequest";
import QRCodeImage from "../../QRCodeImage";
import {useTranslation} from "react-i18next";
import moment from "moment";

function TicketDetails() {
    const { t } = useTranslation();
    const { transactionId }: any = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch()
    const [admissionTicket, setAdmissionTicket] = useState<any>(null)
    const [processDate, setProcessDate] = useState<string>('');

    const fetchData = async () => {
        dispatch(setLoading(true))
        try {
            const { data } = await get(`/event/ticket/${transactionId}`);
            if (data?.success && data?.data) {
                const resData = data.data

                console.log(resData?.start_date + ' ' + resData?.start_time)
                const startDatetimeString = moment(resData?.event_start_date + ' ' + resData?.event_start_time, 'YYYY-MM-DD H:m')
                const endDatetimeString = moment(resData?.event_end_date + ' ' + resData?.event_end_time, 'YYYY-MM-DD H:m')
                if (resData?.event_start_date === resData?.event_end_date) {
                    setProcessDate(startDatetimeString.format('D MMMM, YYYY, LT') + ' - ' + endDatetimeString.format('LT'))
                } else {
                    setProcessDate(startDatetimeString.format('D MMMM, YYYY, LT') + '/nto/n' + endDatetimeString.format('D MMMM, YYYY, LT'))
                }

                setAdmissionTicket(resData)
            }
        } catch (error) {
            console.error('Error:', error);
            return navigate(`/404`)
        }
        dispatch(setLoading(false))
    }

    useEffect(() => {
        fetchData();
    }, []);

    return <div className='px-[20px] py-[24px] md:px-[38px] md:py-[31px]'>
        <div onClick={() => navigate('/social-profile/tickets')}
             className='inline-flex justify-start items-center font-bold top-0 cursor-pointer mb-[40px] ml-[0px] md:mt-0 md:ml-0'>
            <svg width="19" height="22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.875 4.75 7.125 9.5l4.75 4.75" stroke="#fff" strokeWidth="2"/>
            </svg>
            <div>{t('Back')}</div>
        </div>
        <div className='rounded-[12px] border-solid border-[#9B8BFF] border-[0.8px] p-4'>
            {
                admissionTicket &&
                <div>
                    <h1 className="w-100 text-[32px] text-center mb-8 text-white font-bold">MY Ticket</h1>
                    <h2 className="w-100 text-[26px] text-center mb-4 text-white">{admissionTicket?.event_title}</h2>
                    <p className="w-100 text-[16px] text-center mb-4 text-white">{admissionTicket?.venue_name}</p>
                    <div className='mb-4'>
                        {
                            processDate.split('/n').map(v =>
                                <p className="w-100 text-[16px] text-center text-white">{v}</p>
                            )
                        }
                    </div>

                    <p className="w-100 text-[16px] text-center mb-4 text-white">Order No. <span className='sm:hidden md:hidden'><br /></span> {admissionTicket?.order_id}</p>

                    <div className='flex justify-center'>
                        <QRCodeImage content={admissionTicket?.admission_token} className='w-[200px]' />
                    </div>
                </div>
            }
        </div>
    </div>
}

export default TicketDetails;