import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { setCountrySelection } from "../redux/slices/eventFilterSlice";
import { useAppDispatch } from "../hooks/useStore";


const LocationFilter = (props: any) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [items, setItems] = useState(
        // country.map(item => ({ ...item, isChecked: item.value === props.value ? true : false }))
        props?.countries
    );

    const [tempValue, setTempValue] = useState(props.value)
    const handleCheckboxChange = (value: any) => {
        let allFalse = true
        const updatedItems = items.map((item: any) => {
            const isChecked = item.value === value ? !item.isChecked : false;

            if (isChecked) allFalse = false

            return { ...item, isChecked }
        });

        setItems(updatedItems)
        setTempValue(allFalse ? '' : value)
    }

    useEffect(() => {
        const updatedItems = items.map((item: any) => ({ ...item, isChecked: item.value === props.countrySelection ? !item.isChecked : false }));
        setItems(updatedItems)
    }, [])

    const closeModal = () => {
        props.setShowModal(false)
        document.body.classList.remove('overflow-hidden');
    }

    return <div className="md:w-[375px] mb-10">
        <div className="pb-1 mb-3 border-b">{t('SelectLocation')}</div>
        <div className="h-full max-h-[300px] md:max-h-[500px] overflow-auto py-2">
            {items.map((item: any, index: number) => {
                return <div key={index} className="py-2">
                    <label className={"checkbox-label flex item-center justify-between filter-checkbox " + (item.isChecked ? 'active' : '')}>
                        {item.label}
                        <span className="relative">
                            <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx={12} cy={12} r={8} fill="#7E869E" fillOpacity="1" /><path d="m9.5 12 1.894 1.894a.15.15 0 0 0 .212 0L15.5 10" stroke="#222" strokeWidth="1.2" /></svg>

                            <input
                                type="checkbox"
                                checked={item.isChecked}
                                onChange={() => handleCheckboxChange(item.value)}
                                className="absolute opacity-0 checkbox"
                            />
                        </span>
                    </label>
                </div>
            })}
        </div>
        <div className="flex items-center justify-end mt-5">
            <div className="mr-3" onClick={() => {
                setItems(props?.countries.map((item: any) => ({ ...item, isChecked: false })))
                props.clearSelection()
                dispatch(setCountrySelection(''))
                closeModal()
            }}>
                <button className="h-12 px-8 py-2 text-sm font-bold text-white bg-transparent border rounded-full border-ar-primary">{t('Clear')}</button>
            </div>
            <div onClick={() => {
                props.onChange(tempValue)
                dispatch(setCountrySelection(tempValue))
                closeModal()
            }}>
                <button className="h-12 px-8 py-2 text-sm font-bold text-white rounded-full bg-ar-primary">{t('ApplyEventList')}</button>
            </div>
        </div>
    </div>
}

export default LocationFilter;