import FailedVerified from "./components/FailedVerified";
import SentVerification from "./components/SentVerification";
import Verified from "./components/Verified";
import LoadVerified from "./components/LoadVerified";
import { useEffect, useState } from "react";
import { emailVerified, getCustomerEmail, resendEmail } from "../../utils/apiRequestList/authServices";
import { useParams } from "react-router-dom";

const EmailVerificationPage = () => {
    const [verificationData, setVerificationData] = useState<any>(null);
    const [resendVerification, setResendVerification] = useState(false);
    const [loading, setLoading] = useState(false);
    const [customerEmail, setCustomerEmail] = useState<any>({});
    const params: any = useParams()


    const loadEmailVerifyApi = async () => {
        setLoading(true);

        try {
            const responseCustomerEmail = await getCustomerEmail(params.id);
            setCustomerEmail(responseCustomerEmail?.data?.data);


            if (responseCustomerEmail?.data?.data.is_email_verified) {
                setResendVerification(false);
                setVerificationData(responseCustomerEmail?.data?.data);
                setLoading(false);
            } else {

                const url = new URL(window.location.href);
                const token = url.searchParams.get('token');
                const response = await emailVerified(token, {});

                if (response?.data?.success) {
                    setLoading(false);
                    setVerificationData(response?.data?.data);
                }
            }
        } catch (error) {
            console.error('Error:', error);
            setLoading(false);
        }
    };

    useEffect(() => {
        loadEmailVerifyApi();
    }, []);

    const resendVerificationHandle = async (val: boolean, from: string) => {
        setLoading(true);
        if (from === 'failed') {
            setResendVerification(val);
        }
        try {
            setLoading(false);

            const response = await resendEmail({
                emailAddress: customerEmail?.email_address
            });

            if (response?.data?.data) {
                setVerificationData(null)
                setResendVerification(true)
            } else {
                setResendVerification(true)
            }

        } catch {
            setLoading(false);
        }
    }

    return <div className="flex items-center justify-center h-full">
        <div className="dark-shadow w-full lg:w-[708px] px-12 py-16 text-center h-full lg:h-auto">
            {!loading && !verificationData && resendVerification && <SentVerification customerEmail={customerEmail.email_address} resendVerificationHandle={resendVerificationHandle} />}
            {!loading && verificationData && !resendVerification && <Verified />}
            {!loading && !verificationData && !resendVerification && <FailedVerified resendVerificationHandle={resendVerificationHandle} />}
            {loading && <LoadVerified />}
        </div>
    </div>
}

export default EmailVerificationPage;