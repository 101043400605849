/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { homeData } from "../../utils/apiRequestList/homepageServices";
import BannerCarousel from "./components/BannerCarousel";
import FeaturedArtistCarousel from "./components/FeaturedArtistCarousel";
import NewEventsCarousel from "./components/NewEventsCarousel";
import UpcomingEventCarousel from "./components/UpcomingEventCarousel";

const HomePage = () => {
    const [infoCarouselData, setInfoCarouselData] = useState({ data: [], show: false, max: 0 });
    const [artistCarousel, setArtistCarousel] = useState({ data: [], show: false, max: 0 });
    const [upcomingEvent, setUpcomingEvent] = useState({ data: [], show: false, max: 0 });
    const [newEventsData, setNewEventsData] = useState({ data: [], show: false, max: 0 });
    const [isLoading, setIsLoading] = useState(false)

    const fetchData = async () => {
        try {
            const response = await homeData();
            if (response?.data?.data) {
                setInfoCarouselData({ ...infoCarouselData, data: response?.data?.data?.info_carousel, show: response?.data?.data?.info_carousel_show, max: response?.data?.data?.info_carousel_max })
                setArtistCarousel({ ...artistCarousel, data: response?.data?.data?.featured_artist, show: response?.data?.data?.featured_artist_show, max: response?.data?.data?.featured_artist_max })
                setUpcomingEvent({ ...upcomingEvent, data: response?.data?.data?.upcoming_event, show: response?.data?.data?.upcoming_event_show, max: response?.data?.data?.upcoming_event_max })
                setNewEventsData({ ...newEventsData, data: response?.data?.data?.new_event, show: response?.data?.data?.new_event_show, max: response?.data?.data?.new_event_max })
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        setIsLoading(true)
        fetchData()
    }, [])

    return <>
        <Helmet>
            <title>Aria | Get your Tickets for Live Events & Gigs</title>
            <meta property="og:title" content="Aria | Get your Tickets for Live Events & Gigs" />
            <meta property="og:description" content="Your music adventure begins at Aria! Explore and book tickets to the hottest performances, gigs, live music and more. " />
            <meta property="og:url" content="%PUBLIC_URL%" data-rh="true" />
            <meta property="og:image" content="https://cdnprod.witharia.co/Aria_Website/aria-og-image.png" data-rh="true" />
            <meta property="og:type" content="website" />
        </Helmet>
        {infoCarouselData?.show && infoCarouselData.data.length > 0 && <div className="md:max-h-[65vh] overflow-hidden">
            <BannerCarousel infoCarousel={infoCarouselData.data} />
        </div>}
        {newEventsData?.show && newEventsData.data.length > 0 && <NewEventsCarousel newEvents={newEventsData.data} /> }
        {artistCarousel?.show && artistCarousel.data.length > 0 && <FeaturedArtistCarousel featuredArtist={artistCarousel.data} />}
        {upcomingEvent?.show && upcomingEvent.data.length > 0 && <UpcomingEventCarousel data={upcomingEvent.data} show={upcomingEvent.show} max={upcomingEvent.max} />}
    </>
}

export default HomePage;


