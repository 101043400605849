import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// export type Categories = {
//     name: string,
//     value: null,
//     active: boolean,
// }

// export type Params = {
//     byCountry: string,
//     byDate: string,
//     byCategory: string,
// }

// export type Filter = {
//     name: string,
//     icon: any,
//     value: string,
// }

// export type Countries = {
//     value: string,
//     label: string,
//     isChecked: boolean
// }

// type EventFilterState = {
//     countries: Array<Countries>;
//     startDate: any;
//     endDate: any;
//     categories: Array<Categories>;
//     params: Params;
//     filter: Array<Filter>;
//     countrySelection: any;
// }

export interface EventFilterState {
    countries: any[];
    startDate: any;
    endDate: any;
    categories: any[];
    params: any;
    filter: any[];
    countrySelection: any;
}


const initialState: EventFilterState = {
    countries: [],
    startDate: null,
    endDate: null,
    categories: [
        {
            name: 'All Events',
            value: null,
            active: true,
        },
    ],
    params: {
        byCountry: '',
        byDate: '',
        byCategory: '',
    },
    filter: [
        {
            name: '',
            icon: null,
            value: '',
        },
    ],
    countrySelection: '',
};

const eventFilterSlice = createSlice({
    name: "eventFilter",
    initialState,
    reducers: {
        setCountries: (state, action: PayloadAction<any[]>) => {
            state.countries = action.payload;
        },
        setStartDate: (state, action: PayloadAction<any>) => {
            state.startDate = action.payload;
        },
        setEndDate: (state, action: PayloadAction<any>) => {
            state.endDate = action.payload;
        },
        setCategories: (state, action: PayloadAction<any[]>) => {
            state.categories = action.payload;
        },
        setParams: (state, action: PayloadAction<any>) => {
            state.params = action.payload;
        },
        setFilter: (state, action: PayloadAction<any[]>) => {
            state.filter = action.payload;
        },
        setCountrySelection: (state, action: PayloadAction<any>) => {
            state.countrySelection = action.payload;
        },
    }
})


export const {
    setCountries,
    setStartDate,
    setEndDate,
    setCategories,
    setParams,
    setFilter,
    setCountrySelection
} = eventFilterSlice.actions;
export default eventFilterSlice.reducer;