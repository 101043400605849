
import TagLabel from "../components/TagLabel";
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-anonymous-default-export
export default function (data: any, selectedQty: number) {
    const { t } = useTranslation();
    const salesStartDate = new Date(data?.sales_start_timestamp);
    const currentDate = new Date();

    let resultTag = null;
    if (data?.hasOwnProperty('ticket_viewability_type_id') && data?.ticket_viewability_type_id?._id === '64f7df09728437a86d7b75a8') {
        resultTag = <TagLabel label={t('Unlocked')} color='#FFF' bgColor='#D04EFF' />;
    }
    else if (data?.hasOwnProperty('available_amount') && data?.available_amount <= 0) {
        resultTag = <TagLabel label={t('SoldOut')} color='#211F2E' bgColor='#808080' />;
    }
    else if (data?.hasOwnProperty('available_amount') && selectedQty > data?.available_amount) {
        resultTag = <TagLabel label={t('OverOrder')} color='#211F2E' bgColor='#EE4B2B' />;
    }
    else if (currentDate < salesStartDate) {
        resultTag = <TagLabel label={t('ComingSoon')} color='#FFF' bgColor='#6D00FF' />;
    } else {
        resultTag = <TagLabel label={t('SellingFast')} color='#211F2E' bgColor='#28D7FE' />
    }
    // unlock tag
    // <TagLabel label={t('Unlocked')} color='#D04EFF' bgColor='#D04EFF' />
    return resultTag
}