import { get } from '../AxiosRequest'


const homeData = async () => {
    return await get(`/homepage-cms/home`);
}


export {
    homeData,
};