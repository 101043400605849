import FailedIcon from "../../../assets/images/icons/failed_icon.png";
import { useCountdown } from "../../../hooks/useCountDown";
import { useTranslation } from "react-i18next";

const ExpiredCheckout = (props: any) => {
    const { t } = useTranslation();
    const [days, hours, minutes, seconds] = useCountdown(props.expiredTimer);

    if (days + hours + minutes + seconds <= 0) {
        props.reselectTicket(false)
        return <></>
    } else {
        return <>
            <div className="w-full lg:w-[708px] px-12 py-16 text-center h-full lg:h-auto">
                <img className="w-[78px] h-[78px] m-auto" src={FailedIcon} alt="success icon" />
                <div className=" text-2xl font-semibold mt-8 md:px-20">{t('CartExpired')}</div>
                <button onClick={() => props.reselectTicket(false)} className=" mt-4 rounded-full px-5 py-3 bg-ar-primary text-sm font-normal">{t('ReselectTickets')}</button>
                <div className=" mt-4 text-base font-normal flex items-center justify-center">
                    <span className="ml-2 px-14 text-[#CACACA]">{t('YouHaveExceeded')}</span>
                </div>
            </div>
        </>
    }
}

export default ExpiredCheckout