/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { PropsWithChildren } from "react";
import "@splidejs/react-splide/css";
import { Splide, SplideTrack, SplideProps } from "@splidejs/react-splide";
import { clsx } from "clsx";
import ArrowLeft from "../screens/HomePage/components/ArrowRight";

interface Props extends PropsWithChildren, SplideProps {
    customClass?: string;
    /* Will default to light version */
    controlStyle?: "dark" | "light";
}

const CarouselWrapper = (props: Props) => {
    const classes = clsx({
        "custom-slider": true,
        [props.customClass ?? ""]: props.customClass,
        [`control--${props.controlStyle}`]: props.controlStyle
    });

    return <Splide
        className={classes}
        options={props.options}
        hasTrack={false}
    >
        <SplideTrack>
            {props?.children}
        </SplideTrack>

        {/* Arrows */}
        <div className="splide__arrows">
            <button className="splide__arrow splide__arrow--prev">
                <ArrowLeft />
            </button>
            <button className="splide__arrow splide__arrow--next">
                <ArrowLeft />
            </button>
        </div>
    </Splide>
}

export default CarouselWrapper;