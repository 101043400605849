export default function Clear({ className }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M10 11.1093L13.2348 14.3441C13.3806 14.4899 13.5638 14.5644 13.7844 14.5678C14.0051 14.5712 14.1916 14.4966 14.3441 14.3441C14.4966 14.1916 14.5729 14.0067 14.5729 13.7895C14.5729 13.5722 14.4966 13.3873 14.3441 13.2349L11.1093 10L14.3441 6.76515C14.4899 6.61941 14.5644 6.43622 14.5678 6.21557C14.5712 5.99494 14.4966 5.80838 14.3441 5.65588C14.1916 5.50337 14.0067 5.42712 13.7895 5.42712C13.5722 5.42712 13.3873 5.50337 13.2348 5.65588L10 8.89071L6.76515 5.65588C6.61941 5.51013 6.43622 5.43557 6.21557 5.4322C5.99494 5.42881 5.80837 5.50337 5.65588 5.65588C5.50337 5.80838 5.42712 5.99325 5.42712 6.21052C5.42712 6.42778 5.50337 6.61266 5.65588 6.76515L8.89071 10L5.65588 13.2349C5.51013 13.3806 5.43557 13.5638 5.4322 13.7844C5.42881 14.0051 5.50337 14.1916 5.65588 14.3441C5.80837 14.4966 5.99325 14.5729 6.21052 14.5729C6.42778 14.5729 6.61266 14.4966 6.76515 14.3441L10 11.1093ZM10.0018 20C8.61867 20 7.31863 19.7375 6.10165 19.2126C4.88464 18.6877 3.82603 17.9753 2.9258 17.0755C2.02555 16.1757 1.31285 15.1175 0.787713 13.9011C0.262571 12.6846 0 11.3849 0 10.0018C0 8.61867 0.262457 7.31863 0.787371 6.10165C1.31228 4.88464 2.02465 3.82603 2.92448 2.9258C3.82433 2.02555 4.88248 1.31286 6.09894 0.787714C7.31538 0.262572 8.61514 0 9.99824 0C11.3813 0 12.6814 0.262458 13.8984 0.787372C15.1154 1.31229 16.174 2.02465 17.0742 2.92448C17.9744 3.82433 18.6871 4.88248 19.2123 6.09894C19.7374 7.31538 20 8.61515 20 9.99824C20 11.3813 19.7375 12.6814 19.2126 13.8984C18.6877 15.1154 17.9753 16.174 17.0755 17.0742C16.1757 17.9744 15.1175 18.6871 13.9011 19.2123C12.6846 19.7374 11.3849 20 10.0018 20Z"
        fill="currentColor"
      />
    </svg>
  );
}
