import React, { useEffect, useState } from 'react';
import FormWrapper from "../../components/Form/FormWrapper";
import * as Yup from "yup";
import { FormikProps, FormikValues } from "formik";
import ErrorMessage from "../../components/Form/errorMessage";
import { setLoading } from "../../redux/slices/commonSlice";
import { loginSignUp, verifyOTP } from "../../utils/apiRequestList/authServices";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useStore";
import OtpInput from 'react-otp-input';
import RegisterForm from '../../components/Form/RegisterForm';
import { Disclosure, Transition } from '@headlessui/react'
import { setAccessToken, setProfileInfo } from "../../redux/slices/authSlice";

import CallCodeInput from "../../components/Form/CallCodeInput";
import { useTranslation } from "react-i18next";

const SignInForm = (props: any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const { dTwo, dOne } = Object.fromEntries(searchParams.entries());
    const navigate = useNavigate();
    const [emailLogin, setEmailLogin] = useState<boolean>(true);
    const [otpScreen, setOtpScreen] = useState<boolean>(false);
    const [submitedValues, setSubmitedValues] = useState<any>({ dialCode: '', phoneNumber: '', emailAddress: '' });
    const [otp, setOtp] = useState('');
    const [otpVerify, setOtpVerify] = useState<boolean>();
    const [createAccountScreen, setCreateAccountScreen] = useState<boolean>(false);
    const [registerToken, setRegisterToken] = useState('');
    const [second, setSecond] = useState<any>(null);
    const [emailData, setEmailData] = useState<any>({});
    const [attemptsExceeded, setAttemptsExceeded] = useState('');
    const [attemptsExceeded2, setAttemptsExceeded2] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        props?.hideCloseBtn(true);
        props?.hideModalPadding(false);
    }, []);

    useEffect(() => {
        if (second !== null) {
            const interval = setInterval(() => {
                if (second <= 0) {
                    clearInterval(interval);
                } else {
                    setSecond((prevSecond: any) => prevSecond - 1);
                }
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [second]);

    useEffect(() => {
        if (otpScreen) {
            setSecond(process.env.REACT_APP_OTP_EXPIRY_DURATION)
        }
    }, [otpScreen]);

    const initialValues = {
        emailAddress: '',
    }

    const schema = Yup.object().shape({
            emailAddress: Yup.string().email(t('InvalidEmailFormatTypos')).required(t('MandatoryField'))
        })

    const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => (
        <>
            <div>
                <div className=''>
                    <h2 className='text-[26px] mb-[20px]'>{t('LogInHeader')}</h2>
                </div>
                <div className="mb-[20px]">
                    <label htmlFor="emailAddress">{t('Email')}</label>
                    <div className='mt-[10px]'>
                        <div className=''>
                            <input
                                id="emailAddress"
                                name="emailAddress"
                                type="text"
                                className="input mb-[5px]"
                                placeholder={t('Email')}
                                value={values.emailAddress}
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {errors.emailAddress && touched.emailAddress ? (
                                <ErrorMessage text={errors.emailAddress}/>
                            ) : null}
                        </div>
                        <ErrorMessage text={errorMsg}/>
                    </div>
                    <p className="mt-3">
                        {t('AuthAcceptOur')}
                        <span className="text-ar-purple cursor-pointer"
                              onClick={() => {
                                  navigate('/term-and-condition');
                                  props?.closeModal();
                              }}>{t('AuthTNC')}</span>
                        {t('AuthAnd')}
                        <span className="text-ar-purple cursor-pointer"
                              onClick={() => {
                                  navigate('/privacy-policy');
                                  props?.closeModal();
                              }}>{t('AuthPolicy')}</span>.
                    </p>
                </div>
            </div>
        </>
    )
    const handleSubmit = async (values: any) => {
        setEmailData({
            emailAddress: values.emailAddress
        })
        try {
            dispatch(setLoading(true));
            const response = !emailLogin ? await loginSignUp({
                phoneNumber: values.phoneNumber,
                codeNumber: values.codeNumber,
                dTwo,
                dOne
            }) : await loginSignUp({
                emailAddress: values.emailAddress,
                dTwo,
                dOne
            })
            dispatch(setLoading(false));
            if (response?.data?.success) {
                setSubmitedValues({ ...submitedValues, dialCode: values.codeNumber, phoneNumber: values.phoneNumber, emailAddress: values.emailAddress })
                setOtpScreen(true)
            } else if (emailLogin) {
                setOtpScreen(true);
            }
        } catch (error) {
            dispatch(setLoading(false));
            console.error(error)
            // @ts-ignore
            setErrorMsg(error?.response?.data?.message)
        }
    }

    const verifyOTPAction = async () => {
        try {
            const response: any = await verifyOTP({
                otpCode: otp,
                emailAddress: emailData.emailAddress
            })
            if (response?.data?.success) {
                setOtpVerify(true);
                // token = response.data?.token?.token  (this token need to dispatch)
                // isRegistered = response.data?.token?.isRegistered
                const token = response?.data?.data?.registerToken ?? response?.data?.data?.accessToken
                setRegisterToken(response?.data?.data?.registerToken) // register token
                const isRegistered = response.data?.data?.isRegistered;
                if (isRegistered === true && token) {
                    // go to create account (new user)
                    setOtpScreen(false);
                    setCreateAccountScreen(true);
                } else {
                    // old user close modal & refresh current page
                    props?.closeModal();
                    dispatch(setAccessToken({ accessToken: token }));
                    dispatch(setProfileInfo(response.data.data));
                    window.location.reload();
                }
            }
        } catch (error) {
            setOtpVerify(false);
            // @ts-ignore
            setAttemptsExceeded2(error?.response?.data?.message)
            console.error(error)
        }
    };

    useEffect(() => {
        let debounceTimer: any;
        if (otp.length === 4) {
            debounceTimer = setTimeout(() => {
                verifyOTPAction();
            }, 1000);
        }
        return () => clearTimeout(debounceTimer);
    }, [otp]);

    const resendOtp = async () => {
        try {
            await loginSignUp(emailData)
            setSecond(process.env.REACT_APP_OTP_EXPIRY_DURATION)
        } catch (error) {
            console.error(error)
            // @ts-ignore
            setAttemptsExceeded(error?.response?.data?.message)
        }
    }

    const handleBack = () => {
        setOtpScreen(false)
        setOtp('')
        setOtpVerify(true)
        setAttemptsExceeded('')
        setAttemptsExceeded2('')
    }

    return (
        <>
            <div className=''>
                {!createAccountScreen ?
                    (!otpScreen ?
                        <div className='pt-[41px] md:pt-[15px]'>
                            <FormWrapper
                                initialValues={initialValues}
                                validationSchema={schema}
                                formInputs={formInputs}
                                onSubmit={handleSubmit}
                                toggleHoneyPot={true}
                                submitButton={
                                    <div className='flex justify-center mb-[20px]'>
                                        <button className="bg-ar-primary w-full py-[12px] rounded-[10px] text-white capitalize" type="submit">
                                            {t('LogInHeader')}
                                        </button>
                                    </div>
                                }
                            />
                            <p className='text-centercursor-pointer'>
                                {t('NewUserCreaAcc')}
                                <span className="text-ar-purple cursor-pointer" onClick={()=> setCreateAccountScreen(true)}> {t('HereAuthFlow')}</span>
                                .
                            </p>
                        </div>
                        :
                        <div className='pt-[41px] md:pt-[15px]'>
                            <div onClick={() => handleBack()} className='cursor-pointer mb-[25px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 22" fill="none">
                                    <path d="M1 11.1333L0.717159 11.4161L0.434316 11.1333L0.717159 10.8504L1 11.1333ZM26.3333 10.7333C26.5543 10.7333 26.7333 10.9124 26.7333 11.1333C26.7333 11.3542 26.5543 11.5333 26.3333 11.5333L26.3333 10.7333ZM10.8505 21.5494L0.717159 11.4161L1.28284 10.8504L11.4162 20.9838L10.8505 21.5494ZM0.717159 10.8504L10.8505 0.717096L11.4162 1.28278L1.28284 11.4161L0.717159 10.8504ZM1 10.7333L26.3333 10.7333L26.3333 11.5333L1 11.5333L1 10.7333Z" fill="#9B8BFF" />
                                </svg>
                            </div>
                            <h2 className='text-[26px] mb-[10px]'>{t('CodeRequested')}</h2>
                            <p className='mb-[20px]'>{!emailLogin ? t('fourDigitSent') : t('fourDigitSentEmail')} <br />
                                <span className="text-ar-cyan">
                                    {!emailLogin ?
                                        <>
                                            <span className='font-bold'>{submitedValues.dialCode} </span>
                                            <span className='font-bold'>{submitedValues.phoneNumber}</span>
                                        </>
                                        :
                                        <>
                                            <span className='font-bold'>{emailData.emailAddress}</span>
                                        </>
                                    }
                                </span>
                            </p>
                            <div className='mb-[20px]'>
                                <OtpInput
                                    inputType='tel'
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    placeholder="●●●●"
                                    // renderSeparator={<span>-</span>}
                                    renderInput={(props, index) => (<input {...props} autoFocus={index === 0} />)}
                                    containerStyle={{ display: 'flex', justifyContent: 'space-between', padding: "20px 44px", border: '1px solid white' }}
                                    inputStyle={{ backgroundColor: 'transparent', fontSize: '26px' }}

                                />
                                {otpVerify === false &&
                                    <div className='mt-[5px]'>
                                        <ErrorMessage text={t('InvalidCodeTryAgain')} />
                                    </div>
                                }
                                {attemptsExceeded2 && attemptsExceeded2 !== 'Invalid data' &&
                                    <div className='mt-[5px]'>
                                        <ErrorMessage text={attemptsExceeded2} />
                                    </div>
                                }
                            </div>
                            <div className='mb-[20px] flex items-center'>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M7.7999 21.6998C7.36657 21.6998 7.00824 21.5581 6.7249 21.2748C6.44157 20.9915 6.2999 20.6331 6.2999 20.1998V14.9998H6.9999V18.6498H16.9999V5.3498H6.9999V8.9998H6.2999V3.7998C6.2999 3.36647 6.44157 3.00814 6.7249 2.7248C7.00824 2.44147 7.36657 2.2998 7.7999 2.2998H16.1999C16.6332 2.2998 16.9916 2.44147 17.2749 2.7248C17.5582 3.00814 17.6999 3.36647 17.6999 3.7998V20.1998C17.6999 20.6331 17.5582 20.9915 17.2749 21.2748C16.9916 21.5581 16.6332 21.6998 16.1999 21.6998H7.7999ZM6.9999 19.3498V20.1998C6.9999 20.3998 7.08324 20.5831 7.2499 20.7498C7.41657 20.9165 7.5999 20.9998 7.7999 20.9998H16.1999C16.3999 20.9998 16.5832 20.9165 16.7499 20.7498C16.9166 20.5831 16.9999 20.3998 16.9999 20.1998V19.3498H6.9999ZM9.9999 15.0998L9.4999 14.5998L11.7499 12.3498H2.6499V11.6498H11.7499L9.4999 9.3998L9.9999 8.8998L13.0999 11.9998L9.9999 15.0998ZM6.9999 4.6498H16.9999V3.7998C16.9999 3.5998 16.9166 3.41647 16.7499 3.2498C16.5832 3.08314 16.3999 2.9998 16.1999 2.9998H7.7999C7.5999 2.9998 7.41657 3.08314 7.2499 3.2498C7.08324 3.41647 6.9999 3.5998 6.9999 3.7998V4.6498Z" fill="white" />
                                    </svg>
                                </div>
                                <div>
                                    <p className={`${second ? 'opacity-20 cursor-not-allowed' : 'opacity-100 cursor-pointer'}`} onClick={() => {
                                        if (second <= 0) {
                                            resendOtp()
                                        }
                                    }}>{t('RequestNewCode')}</p>
                                    {second ?
                                        <p className='text-ar-cyan'>{t('AvailableIn')}<span>{second}</span> {t('AuthSeconds')}</p>
                                        :
                                        <></>
                                    }
                                    {attemptsExceeded &&
                                        <p className='text-[#FF6A6A]'>{attemptsExceeded}</p>
                                    }
                                </div>
                            </div>
                            <div>
                                <Disclosure>
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full text-left mb-[21px]">
                                                <span className="text-ar-purple">{t('DintReceiveCode')}</span>
                                                <span className={`${open ? 'rotate-180 transform' : ''} self-center max-w-[26px]`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M18 15L12 9L6 15" stroke="#9B8BFF" strokeWidth="2" />
                                                    </svg>
                                                </span>
                                            </Disclosure.Button>
                                            <Transition
                                                enter="transition duration-100 ease-out"
                                                enterFrom="transform scale-95 opacity-0"
                                                enterTo="transform scale-100 opacity-100"
                                                leave="transition duration-75 ease-out"
                                                leaveFrom="transform scale-100 opacity-100"
                                                leaveTo="transform scale-95 opacity-0"
                                            >
                                                <div className="grid grid-cols-1 divide-y divide-[#919191] border-solid border border-[#919191]">
                                                    <Disclosure.Panel className="pl-[15px] pr-[65px] py-[26px]">
                                                        {t('CheckTypoEmail')}
                                                    </Disclosure.Panel>
                                                    <Disclosure.Panel className="pl-[15px] pr-[65px] py-[26px]">
                                                        {t('CheckyourSpam')}
                                                    </Disclosure.Panel>
                                                    <Disclosure.Panel className="pl-[15px] pr-[65px] py-[26px]">
                                                        {t('NotInSpam')}
                                                    </Disclosure.Panel>
                                                </div>
                                            </Transition>
                                        </>
                                    )}
                                </Disclosure>
                            </div>
                        </div>
                    )
                    :
                    <div>
                        <RegisterForm
                            closeModal={props?.closeModal} registerToken={registerToken}
                            hideCloseBtn={props?.hideCloseBtn}
                            hideModalPadding={props?.hideModalPadding}
                        />
                    </div>
                }
            </div>
        </>
    )
}

export default SignInForm;