import React, { useState, useEffect } from "react";
import emailIcon from "../../../assets/images/icons/sent-email.png";
import { useTranslation } from "react-i18next";
import { resendEmail } from "../../../utils/apiRequestList/authServices";
import DangerouslySetInnerHTML from "../../../components/DangerouslySetInnerHTML";

const SentVerification = (props: any) => {
    const [sentEmail, setSentEmail] = useState(false);
    const [seconds, setSeconds]: any = useState(null);
    const { t } = useTranslation();
    const resendEmailAction = async () => {
        setSentEmail(true)
        setSeconds(process.env.REACT_APP_OTP_EXPIRY_DURATION);
        try {
            await resendEmail({
                emailAddress: props?.customerEmail
            });
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (seconds !== null) {
            const interval = setInterval(() => {
                if (seconds <= 0) {
                    clearInterval(interval);
                    setSentEmail(false)
                    props.resendVerificationHandle(true, 'resend')
                } else {
                    setSeconds((prevSeconds: any) => prevSeconds - 1);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [seconds]);

    return <div>
        <div className="text-center">
            <img className="w-[78px] h-[78px] m-auto" src={emailIcon} alt="success icon" />
            <div className=" text-2xl font-semibold mt-7">{t("CheckYoutEmail")}</div>
            <div className=" mt-4 text-base font-normal mb-5">{t("AnEmailWithVerificationSent")} <br />
                <span className='text-[#28d7fe]'>{props?.customerEmail}</span>
            </div>
            {!sentEmail ? <button onClick={() => resendEmailAction()} className="text-sm bg-ar-primary w-full max-w-[380px] py-[12px] rounded-[46px] text-white font-semibold">
                    {t("ResendLink")}
                </button> :
                <div className="text-ar-primary text-sm font-semibold">{t("ResendLInkIn")} {seconds}s</div>}
            <div className='text-[10px] text-center mt-4 max-w-[340px] mx-auto'>
                <span>{t('CheckSpamFolderEmail')}</span>
                <a href="mailto:admin@witharia.co" className='text-ar-primary'>admin@witharia.co</a>
                <span>{t('ForHelp')}</span>
            </div>
        </div>
    </div>
}

export default SentVerification;
