import EmailVerificationPage from "../screens/EmailVerificationPage";

const publicRoutesNoNav = [
  {
    name: "Home page",
    path: "/email-update-verification/:id",
    element: <EmailVerificationPage />,
    errorElement: <EmailVerificationPage />,
  }
];

export default publicRoutesNoNav;
