export default function ClosePurple() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
        >
            <circle cx="15" cy="15" r="13.5" fill="#6650F2" />
            <path d="M10.5 19.5L19.5 10.5" stroke="white" />
            <path d="M19.5 19.5L10.5 10.5" stroke="white" />
        </svg>
    );
}
