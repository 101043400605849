import React from "react";

const PillButton = (props: any) => {

    const style={
        display: 'flex',
        padding: '13px 19px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
        borderRadius: '46px',
        boxShadow: '0px 5px 5px 0px rgba(0, 0, 0, 0.10), 0px 1px 1px 0px rgba(0, 0, 0, 0.05)',
        marginRight: '11px',
    }

    return(
        <>
            <button className={`${props?.className} pagination-pill-btn whitespace-nowrap`} style={style} onClick={props.onClick}>
                {props.item.name}
            </button>
        </>
    )
}

export default PillButton;