import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CurrentLocation } from "../types/location"

type LocationState = {
    currentLocation: CurrentLocation
};

const initialState: LocationState = {
    currentLocation: {
        as: "",
        city: "",
        country: "",
        countryCode: "",
        isp: "",
        lat: 0,
        lon: 0,
        org: "",
        query: "",
        region: "",
        regionName: "",
        status: "",
        timezone: "",
        zip: "",
        dialCode: "",
    }
};

const locationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        setCurrentLocation: (state, { payload }: PayloadAction<CurrentLocation>) => {
            state.currentLocation = payload
        }
    },
});

export const { setCurrentLocation } = locationSlice.actions;
export const getCurrentLocation = (state: any) => state.location.currentLocation;
export default locationSlice.reducer;