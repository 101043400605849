import LoadingIcon from "../../../assets/images/icons/loading-icon.png";
import { useTranslation } from "react-i18next";
import DangerouslySetInnerHTML from "../../../components/DangerouslySetInnerHTML";

const LoadVerified = () => {
    const { t } = useTranslation();
    return <div>
        <img className="w-[78px] h-[78px] m-auto" src={LoadingIcon} alt="success icon" />
        <div className=" text-2xl font-semibold mt-7">{t("Loading")}</div>
        <DangerouslySetInnerHTML
            classNames={'mt-4 text-base font-normal editor-formatting'}
            htmlContent={t('PleaseWait')}
        />
    </div>
}

export default LoadVerified;
