import { useCountdown } from "../../hooks/useCountDown";
import { useTranslation } from "react-i18next";

const CountdownTimer = ({ targetDate, expiredStatus }: { targetDate: Date, expiredStatus:any }) => {
  const { t } = useTranslation();
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    expiredStatus(true)
    return <div>{t("Expired")}</div>;
  } else {
    return (
      <div className="bg-[#28D7FE] text-xs px-[14px] py-[6px] text-ar-black rounded-full">
        {t("TicketHeldFor")}{" "}
        <b>
          {minutes.toString().padStart(2, "0")}:
          {seconds.toString().padStart(2, "0")}
        </b>
      </div>
    );
  }
};

export default CountdownTimer;
