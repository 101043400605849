import React from "react";

const CapsuleButton = (props: any) => {
    return <button
        className={"capsule rounded-full flex items-center "
        + (props.shadow ? props.shadow + ' ' : 'shadow-[0_5px_5px_0px_#0000001A] ')
        + (props.paddingy ? props.paddingy + ' ' : 'py-3 ')
        + (props.paddingx ? props.paddingx + ' ' : 'px-5 ')
        + (props.active ? props.activeBg + ' active ' : props.bgColor ? props.bgColor + ' ' : " bg-ar-purple ")
        + (props.border ? props.border + ' ' : " border border-ar-purple ")
        + (props.bgColor ? props.bgColor + ' ' : " bg-ar-purple ")}
    >
        {props.icon ? <div className="mr-2">
            {props.icon}
        </div> : ''}
        <div className={(props.active ? "text-ar-purple" : "text-white")}>{props.name}</div>
    </button>
}

export default CapsuleButton;