import React from "react";
import { useNavigate } from 'react-router-dom';

const CardRounded = (props: any) => {
    const { category, imgUrl, name, date, border, country_code, location } = props.item;
    const navigate = useNavigate();

    const selectedCard = (url: string) => {
        navigate(url)
    }

    return <div className="px-0 pb-[30px] cursor-pointer w-auto max-w-[330px]" onClick={() => { selectedCard(props.url) }}>
        <div className="relative w-[330px] h-[185px]">
            {/* {country && <div className="absolute p-1 text-xs font-normal bg-white rounded-full top-3 right-2">{country}</div>} */}
            <img className={`object-cover w-full h-full overflow-hidden rounded-none ${border}`} src={imgUrl} alt='' />
            {category && <div className="absolute px-2 py-1 text-xs font-normal text-white rounded-full bottom-4 right-2 bg-ar-black">{category}</div>}
        </div>
        <div className="p-2">
            <div className="text-base font-bold truncate">
                {name}
            </div>
            <div className="">
                {location && <div className="text-base font-normal">{`${country_code}, ${location}`}</div>}
                {date && <div className="text-base font-normal ">{date}</div>}
            </div>
        </div>
    </div>
}

export default CardRounded;