import { Outlet } from "react-router-dom";

const NoNavigationLayout = () => {
    return <>
        <div className=" bg-ar-black h-[100vh] overflow-y-auto w-full text-white">
            <Outlet />
        </div>
    </>
}

export default NoNavigationLayout;