import { useTranslation } from "react-i18next";
import i18n from "../../languages/i18n";
import formatDateRange from "../../utils/formatDateRange";

const EventDetails = (props: any) => {
    const { t } = useTranslation()
    const { event } = props

    console.log('check event', event)

    const handleAddToCalendar: any = () => {
        const languageParams = i18n.language ? `?lang=${i18n.language}` : '';
        const addCalendarURL: string = `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1/event/add-to-calendar/${event?.venue?._id}${languageParams}`
        return addCalendarURL;
    }

    // const handleAddToCalendar = async () => {
    //     try {
    //         const response = await get(`/event/add-to-calendar/${receipt_id}`);

    //         // Create a Blob from the response data
    //         const blob = new Blob([response.data], { type: 'text/calendar' });

    //         // Create a download link and trigger a click event to download the .ics file
    //         const link = document.createElement('a');
    //         link.href = window.URL.createObjectURL(blob);
    //         link.download = 'calendar-event.ics';
    //         link.click();
    //     }
    //     catch (error) {
    //         console.error('Error adding event to calendar:', error);
    //     }
    // }

    return <>
        <div className="text-[16px] text-[#CACACA] font-[400] font-['Nunito'] mb-[13px] ml-[9px]">
            {t('EventDetails')}
        </div>
        <div className="p-[16px] md:p-[20px] pb-[26px] rounded-[12px] border-solid border-[#9B8BFF] border-[0.8px] flex flex-col gap-y-[20px] mb-[20px]">
            <div className="text-[14px] text-[#FFFFFF] font-[400] font-['Nunito']">
                {event?.name &&
                    <div>{event?.name}</div>
                }
                {/* <div className="text-[#919191]">Greatest EDM music festival to ever happen in Singapore.</div> */}
            </div>
            <div className="text-[14px] text-[#FFFFFF] font-[400] font-['Nunito']">
                {
                    event?.venue?.name && <div>
                        {event?.venue?.name}
                    </div>
                }
                <div>
                    {event?.venue?.address &&
                        <span>{event?.venue?.address}, </span>
                    }
                    {event?.venue?.city &&
                        <span>{event?.venue?.city}</span>
                    }
                    {event?.venue?.province &&
                        <span>, {event?.venue?.province}</span>
                    }
                    {event?.venue?.state &&
                        <span>, {event?.venue?.state}</span>
                    }
                    {event?.venue?.country &&
                        <span>, {event?.venue?.country}</span>
                    }
                    {event?.venue?.postalCode &&
                        <span> {event?.venue?.postalCode}</span>
                    }
                </div>
            </div>
            <div className="text-[14px] text-[#FFFFFF] font-[400] font-['Nunito']">
                {event?.no_of_days &&
                    <div className="text-[#919191]">{t('HappeningOver', { x: event?.no_of_days })}</div>
                }
                {event?.start && event?.end && <div>
                    {formatDateRange(new Date(event?.start), new Date(event?.end))}
                </div>}
                <a className="text-[#9B8BFF]" href={handleAddToCalendar()}>+ {t('AddToCalendar')}</a>
            </div>
            <div>
                <div className="text-[#919191]">{t('GettingThere')}</div>
                <p className=' text-sm font-normal text-white'>{t('WalkingBurnsCalories')}</p>
            </div>
        </div>
    </>
}

export default EventDetails;