import React from "react";
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from "react-redux";
import {
  RouterProvider, createBrowserRouter
} from "react-router-dom";
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from "../src/redux/store";
import Routes from "./Routes";
import ExceptionHandler from "./components/Handler/ExceptionHandler";
import LocationHandler from "./components/Handler/LocationHandler";

const persistor = persistStore(store);
const router = createBrowserRouter(Routes);

function App() {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
          <LocationHandler />
          <ExceptionHandler />
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
