import { useTranslation } from "react-i18next";
import { Tooltip } from "primereact/tooltip";

const BookingItems = ({ data, purpleLine = true }: any) => {
    const informationIcon = <svg width="21" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#a)"><circle cx="10.5" cy="10.5" r="6" stroke="#28D7FE" /><path d="M10.784 11.368a.31.31 0 0 1-.24-.096.434.434 0 0 1-.08-.272c0-.165.019-.32.056-.464.043-.15.11-.299.2-.448.09-.15.21-.307.36-.472.112-.128.2-.24.264-.336a1.038 1.038 0 0 0 .192-.568.502.502 0 0 0-.192-.416c-.128-.101-.31-.152-.544-.152-.213 0-.408.03-.584.088-.17.053-.344.13-.52.232a.576.576 0 0 1-.32.096.367.367 0 0 1-.224-.104.403.403 0 0 1-.112-.216.426.426 0 0 1 .04-.264.632.632 0 0 1 .232-.248c.219-.144.47-.253.752-.328.283-.08.55-.12.8-.12.336 0 .63.056.88.168.256.112.453.267.592.464.144.197.216.43.216.696 0 .165-.024.323-.072.472-.048.144-.125.29-.232.44a3.424 3.424 0 0 1-.44.472c-.165.15-.299.285-.4.408a1.61 1.61 0 0 0-.216.352 1.545 1.545 0 0 0-.088.36.252.252 0 0 1-.096.184.323.323 0 0 1-.224.072Zm-.016 1.672a.595.595 0 0 1-.44-.168.6.6 0 0 1-.16-.432c0-.17.053-.31.16-.416a.595.595 0 0 1 .44-.168.563.563 0 0 1 .592.584.6.6 0 0 1-.16.432c-.101.112-.245.168-.432.168Z" fill="#28D7FE" /></g><defs><filter id="a" x="0" y="4" width="21" height="21" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /><feOffset dy="4" /><feGaussianBlur stdDeviation="2" /><feComposite in2="hardAlpha" operator="out" /><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" /><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_8257_50610" /><feBlend in="SourceGraphic" in2="effect1_dropShadow_8257_50610" result="shape" /></filter></defs></svg>
    const { t } = useTranslation()
    const order_summary = data?.order_summary ? data?.order_summary : data

    return order_summary && <>
        <div className='text-sm md:text-base'>
            <ul className={`border-b-[0.5px] border-b-[${purpleLine ? '#9B8BFF' : '#919191'}]`}>
                {order_summary?.ticketList?.map((item: any) => {
                    return <li key={item.id} className={`border-b border-b-[${purpleLine ? '#9B8BFF' : '#919191'}] py-[15px] first:pt-0 last:border-0`}>
                        <div className='flex justify-between mb-2 text-[14px]'>
                            <div className='font-normal'>{item?.ticket_amount} x {item?.name} ({item?.single_ticket_price_display})
                                {/*{t('PerPax')})*/}
                            </div>
                            <div>{item?.ticket_total_price_display}</div>
                        </div>
                        {item?.total_booking_fee_price > 0 && <div className='flex justify-between mb-2 text-[14px]'>
                            <div className='flex items-center font-normal'>{t('BookingFees')}
                                <Tooltip target=".custom-target-icon" />
                                <i className='custom-target-icon relative top-[2px]'
                                    data-pr-tooltip="The booking fee is based on each ticket's pre-discount price."
                                    data-pr-position="right"
                                    data-pr-at="right+5 top"
                                    data-pr-my="left center-2" >{informationIcon}
                                </i>
                            </div>
                            <div>{item?.total_booking_fee_price_display}</div>
                        </div>}
                        {item?.total_discount_price > 0 && <div className='flex justify-between mb-2 text-ar-cyan text-[14px]'>
                            <div className='inline-flex font-bold '>
                                <div className='max-w-[8em] text-ellipsis overflow-hidden whitespace-nowrap text-[14px]'>
                                    {order_summary?.promoSubTotal?.promoName ? order_summary?.promoSubTotal?.promoName : order_summary.promoCode}
                                </div>
                                &nbsp;{t('Discount')}
                            </div>
                            <div>
                                -{item?.total_discount_price_display}
                            </div>
                        </div>}
                        <div className='flex justify-between mb-2 last:mb-0 text-[14px]'>
                            <div className="font-bold">{t('Subtotal')}</div><div>{item?.single_subtotal_display}</div>
                        </div>
                    </li>
                })}
                {
                    order_summary?.addonList?.map((item: any) => {
                        return <li key={item.id} className={`border-b border-b-[${purpleLine ? '#9B8BFF' : '#919191'}] py-[15px] first:pt-0 last:border-0`}>
                            <div className='flex justify-between mb-2 text-[14px]'>
                                <div>
                                    <span className='text-ar-cyan font-normal'>{t('AddOn')}: </span>
                                    {item?.addOns_amount} x {item?.name} ({item?.single_addon_price_display})
                                </div>
                                <div>{item?.addOns_total_price_display} </div>
                            </div>
                            {item?.total_booking_fee_price > 0 && <div className='flex justify-between mb-2 text-[14px]'>
                                <div className='flex items-center'>{t('BookingFees')}
                                    <Tooltip target=".custom-target-icon" />
                                    <i className='custom-target-icon relative top-[2px]'
                                        data-pr-tooltip="The booking fee is based on each ticket's pre-discount price."
                                        data-pr-position="right"
                                        data-pr-at="right+5 top"
                                        data-pr-my="left center-2" >{informationIcon}
                                    </i>
                                </div>
                                <div>{item?.total_booking_fee_price_display}</div>
                            </div>}
                            <div className='flex justify-between mb-2 last:mb-0 text-[14px]'>
                                <div className="font-bold">{t('Subtotal')}</div><div>{item?.single_subtotal_display}</div>
                            </div>
                        </li>
                    })
                }
            </ul>
            <ul className={`${purpleLine ? '' : 'border-b-[0.5px] border-[#919191]'}] py-[15px]`}>
                <li className='flex justify-between mb-2 text-[14px] font-bold'>
                    <div>{t('Total')}</div><div>{order_summary?.totalBeforeTax?.displayAmount}</div>
                </li>
                <li className='flex justify-between text-[14px]'>
                    <div>{t('ApplicableTaxes')} ({order_summary?.taxPercentage}%)</div>
                    <div>{order_summary?.totalTax?.displayAmount}</div>
                </li>
            </ul>
            <div className={`flex justify-between text-white font-bold py-[15px] ${purpleLine ? ' bg-ar-dark-purple px-[9px] py-[20px]' : ''}`}>
                <div>{t('PaymentTotal')}</div><div>{order_summary?.grandTotal?.displayAmount}</div>
            </div>
        </div >
    </>
}

export default BookingItems;