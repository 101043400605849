import React from "react";

const PageContainer = (props: any) => {
  return <div className={`${props.hideBanner ? props.hideBannerCustomTop + " md:px-3 px- xl:px-0 " : " px-3 xl:px-0 "} md:mb-[-96px]`}>
    <div className={`w-full ${props?.maxWidth ? props?.maxWidth : "xl:w-[1080px]"} bg-ar-black md:bg-opacity-90 md:backdrop-blur-[3.5px] m-auto relative shadow-[0_9px_10px_3px_rgba(0,0,0,0.5)] `
      + (props.mobileTransparent ? 'bg-ar-black bg-opacity-90 backdrop-blur-[3.5px] ' : 'bg-ar-black md:bg-opacity-90 md:backdrop-blur-[3.5px] ')
      + (props.hideBanner ? 'top-0 md:top-[-166px] lg:top-[-166px] rounded-0 md:rounded-2xl ' : (props.noTop ? 'top-[0] md:top-[-166px] rounded-2xl' : (props.customTop ? `${props.customTop} md:top-[-166px] rounded-2xl` : 'top-[-200px] md:top-[-166px] rounded-2xl')))}>
      {props?.children}
    </div>
  </div>
}

export default PageContainer;