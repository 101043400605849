import { useAppSelector } from "./useStore";

const useAuth = () => {
    const { accessToken, user: userInfo } = useAppSelector((state) => state.userAuth);

    return{
        accessToken,
        userInfo
    }
}
export  default useAuth;