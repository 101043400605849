import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import mmddyyyyTimeFormat from '../../../../utils/mmddyyyyTimeFormat';
import { useTranslation } from 'react-i18next';


const DesktopView = (props: any) => {
    const { t } = useTranslation();

    return <div>

        <DataTable value={props?.data} showGridlines tableStyle={{ minWidth: '50rem' }} emptyMessage={t('NoResultsFound')}
        >
            <Column header={t('OrderNo')} body={(res: any) => {
                return <div>
                    {res?.order_id}
                </div>
            }}></Column>
            <Column header={t('OrderDate')} body={(res: any) => {
                return res?.order_date && <div>
                    {mmddyyyyTimeFormat(res?.order_date)}
                </div>
            }}></Column>
            <Column header={t('PurchaseInformation')} body={(res: any) => {
                return <div>
                    <div>{res?.event_name}</div>
                    <div>{res?.event_venue?.name}</div>
                    {/* <div>
                        {res?.event_venue?.address &&
                            <span>{res?.event_venue?.address}, </span>
                        }
                        {res?.event_venue?.city &&
                            <span>{res?.event_venue?.city}</span>
                        }
                        {res?.event_venue?.province &&
                            <span>, {res?.event_venue?.province}</span>
                        }
                        {res?.event_venue?.state &&
                            <span>, {res?.event_venue?.state}</span>
                        }
                        {res?.event_venue?.country &&
                            <span>, {res?.event_venue?.country}</span>
                        }
                        {res?.event_venue?.postalCode &&
                            <span> {res?.event_venue?.postalCode}</span>
                        }</div> */}
                </div>
            }}>
            </Column>
            <Column body={(res: any) => {
                return <div className='flex justify-center items-center flex-nowrap gap-x-[10px]'>
                    <Button
                        label={t('ViewReceipt')}
                        className="bg-[#6650F2] disabled:bg-[#4B4B4B] rounded-[20px] px-[12px] py-[5px] text-[14px] text-[#FFFFFF] disabled:text-[#919191] font-normal font-['Nunito']"
                        onClick={() => props.navigateMyOrder(res?._id)}
                    />
                    <Button
                        label={t('ViewTicket')}
                        className="bg-[#6650F2] disabled:bg-[#4B4B4B] rounded-[20px] px-[12px] py-[5px] text-[14px] text-[#FFFFFF] disabled:text-[#919191] font-[400] font-['Nunito']"
                        // onClick={() => props.navigateMyTicketDetails(res?._id)}
                        disabled
                    />
                </div>
            }}></Column>
        </DataTable>
    </div>
}

export default DesktopView;