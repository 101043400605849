import { get } from "../AxiosRequest";

const eventDetailInformation = async (slug: string, queryParams?: string) => {
  return await get(`event/details/information/${slug}?${queryParams}`);
};

const eventDetailLineUp = async (slug: string, queryParams?: string) => {
  return await get(`event/details/line-up/${slug}?${queryParams}`);
};

const getEventListing = async ({ byCountry, byDate, byCategory, page }: any) => {
  return await get(`event/public/listing?byCountry=${byCountry}&byDate=${byDate}&byCategory=${byCategory}&pageSize=6&page=${page}`);
};

const getEventCategory = async () => {
  return await get(`event/public/all-categories`);
};

const eventDetailGallery = async (slug: string, queryParams?: string) => {
  return await get(`event/details/gallery/${slug}?${queryParams}`);
}

const getEventCountryDropdown = async () => {
  return await get(`event/public/country-dropdown`);
};

export { eventDetailInformation, eventDetailLineUp, getEventListing, getEventCategory, eventDetailGallery, getEventCountryDropdown };
