export default function Back() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27"
            height="22"
            viewBox="0 0 27 22"
            fill="none"
        >
            <path
                d="M1.00025 11.1342L0.717403 11.4171L0.43456 11.1342L0.717403 10.8514L1.00025 11.1342ZM26.3336 10.7343C26.5545 10.7343 26.7336 10.9133 26.7336 11.1343C26.7336 11.3552 26.5545 11.5342 26.3336 11.5342L26.3336 10.7343ZM10.8507 21.5504L0.717403 11.4171L1.28309 10.8514L11.4164 20.9847L10.8507 21.5504ZM0.717403 10.8514L10.8507 0.718073L11.4164 1.28376L1.28309 11.4171L0.717403 10.8514ZM1.00025 10.7342L26.3336 10.7343L26.3336 11.5342L1.00025 11.5342L1.00025 10.7342Z"
                fill="#9B8BFF"
            />
        </svg>
    );
}
