import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type TicketCartItem = {
    id: string,
    ticket_obj: any,
    count: number
}

export type AddonsCartItem = {
    id: string,
    addons_obj: any,
    count: number
}

type TicketCheckout = {
    total: number,
    currency: string,
    fullName: string,
    email: string,
    callCode: string,
    contactNo: string,
    summary: any,
    event: any,
    transaction_id: string,
    session_expired: string,
    order_no_with_prefix?: string
    order_id_with_prefix?: string
}

type TicketState = {
    itemCart: {
        event_id: string,
        event_slug: string,
        tickets: Array<TicketCartItem>,
        addons: Array<AddonsCartItem>,
        promotionObj?: any,
    },
    ticketCheckout: TicketCheckout | null,
    receipt_id: string | null,
};

const initialState: TicketState = {
    itemCart: {
        event_id: '',
        event_slug: '',
        tickets: [],
        addons: [],
    },
    ticketCheckout: null,
    receipt_id: null,
};

const ticketSlice = createSlice({
    name: "ticket",
    initialState,
    reducers: {
        setItemCart: (state, { payload }: PayloadAction<{
            event_id: string,
            event_slug: string,
            ticket?: TicketCartItem,
            addon?: AddonsCartItem
        }>) => {
            if (state.itemCart.event_id !== payload.event_id) {
                state.itemCart = {
                    event_id: payload.event_id,
                    event_slug: payload.event_slug,
                    tickets: payload.ticket ? [payload.ticket] : [],
                    addons: payload.addon ? [payload.addon] : [],
                    promotionObj: state.itemCart.promotionObj ?? null
                }
                state.ticketCheckout = null
            } else {
                let isExistTicket = false

                if (payload.ticket) {
                    state.itemCart.tickets = state.itemCart.tickets.map((item) => {
                        let temp = item
                        if (payload?.ticket?.id === item.id) {
                            isExistTicket = true
                            temp = payload.ticket as TicketCartItem
                        }
                        return temp
                    })

                    if (!isExistTicket) {
                        state.itemCart.tickets.push(payload.ticket)
                    }
                } else if (payload.addon) {
                    state.itemCart.addons = state.itemCart.addons.map((item) => {
                        let temp = item
                        if (payload?.addon?.id === item.id) {
                            isExistTicket = true
                            temp = payload.addon as AddonsCartItem
                        }
                        return temp
                    })

                    if (!isExistTicket) {
                        state.itemCart.addons.push(payload.addon)
                    }
                }
            }
        },
        removeTicketOrAddonFromCart: (state, { payload }: PayloadAction<string>) => {
            let selectedIndex: number | null = null
            state.itemCart.tickets.forEach((ticket: TicketCartItem, index: number) => {
                if (ticket.id === payload) {
                    selectedIndex = index
                }
            })

            if (typeof selectedIndex === 'number') {
                state.itemCart.tickets = state.itemCart.tickets.filter((ticket, index) => (index !== selectedIndex))
                selectedIndex = null
            }

            state.itemCart.addons.forEach((addon: AddonsCartItem, index: number) => {
                if (addon.id === payload) {
                    selectedIndex = index
                }
            })

            if (typeof selectedIndex === 'number') {
                state.itemCart.addons = state.itemCart.addons.filter((addon, index) => (index !== selectedIndex))
                selectedIndex = null
            }
        },
        setPromotionObj: (state, { payload }: PayloadAction<any>) => {
            state.itemCart.promotionObj = payload
        },
        calculateTicketCheckout: (state, { payload }: PayloadAction<TicketCheckout>) => {
            state.ticketCheckout = payload
        },
        resetItemSlice: (state) => {
            state.itemCart = {
                event_id: '',
                event_slug: '',
                tickets: [],
                addons: [],
            }
            state.ticketCheckout = null
        },
        resetTicketCheckout: (state) => {
            state.ticketCheckout = null
        },
        setReceiptID: (state, { payload }: PayloadAction<string>) => {
            state.receipt_id = payload
        },
        resetReceiptID: (state) => {
            state.receipt_id = null
        },
    },
});

export const {
    setItemCart,
    removeTicketOrAddonFromCart,
    setPromotionObj,
    calculateTicketCheckout,
    resetItemSlice,
    resetTicketCheckout,
    setReceiptID,
    resetReceiptID,
} = ticketSlice.actions;
export const getItemCart = (state: any) => state.itemCart;
export default ticketSlice.reducer;