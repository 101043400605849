import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import NoIMage from "../../assets/images/no-image.png";
import BreadCrumb from "../../components/Breadcrumb";
import Calendar from "../../components/Calendar";
import CapsuleButton from "../../components/CapsuleButton";
import CardRounded from "../../components/CardRounded";
import LocationFilter from "../../components/LocationFilter";
import Modal from "../../components/Modal";
import PageContainer from "../../components/PageContainer";
import { getEventCategory, getEventCountryDropdown, getEventListing } from "../../utils/apiRequestList/eventServices";
import formatDateStartEnd from "../../utils/formatDateStartEnd";
import {
    setCountries,
    setStartDate,
    setEndDate,
    setCategories,
    setParams,
    setFilter,
    setCountrySelection
} from "../../redux/slices/eventFilterSlice";
import { useAppDispatch } from "../../hooks/useStore";
import { useSelector } from "react-redux";

const EventListing = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const iconLocation = <svg
        className="colored-svg"
        width="11"
        height="13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.886 2.371A5.132 5.132 0 0 0 1.628 9.63l3.007 3.006a.88.88 0 0 0 1.244 0l3.006-3.006a5.131 5.131 0 0 0 0-7.257h.001Zm-5.32 5.32A2.39 2.39 0 1 1 6.947 4.31a2.39 2.39 0 0 1-3.38 3.38Z"
            fill="#000"
        />
    </svg>
    const { countries,
        startDate,
        endDate,
        categories,
        params,
        filter,
        countrySelection } = useSelector((state: any) => state.eventFilter)

    const [showModal, setShowModal] = useState(false);
    const [modalBodyContent, setModalBodyContent] = useState("");
    const [activeButtonCal, setActiveButtonCal] = useState<number | null>(null);
    const [calendarSelectedDate, setCalendarSelectedDate] = useState(null);
    const [firstLoad, setFirstLoad] = useState(true)
    const [isLoading, setIsLoading] = useState(true);
    const [tempStartDate, setTempStartDate] = useState<any>(null);
    const [events, setEvents] = useState<any>([]);
    
    const categoriesInit = [
        {
            name: t("AllEvent"),
            value: '',
            active: params?.byCategory === '',
        },
    ];

    const [infiniteLoader, setInfiniteLoader] = useState({
        page: 1,
        hasMore: false,
    });

    const createOption1 = (countryLabel: string) => ({
        name: countryLabel,
        icon: iconLocation,
        value: "location",
    });

    const createOption2 = (countryLabel: string) => ({
        name: countryLabel,
        icon: null,
        value: "date",
    });

    const options = [
        createOption1(countries?.find((c: any) => c.value === params.byCountry)?.label || t("All")),
        createOption2(params?.byDate ? filter[1].name : t("Date")),
    ];

    const normalizedTempStartDate = tempStartDate ? new Date(tempStartDate) : startDate;

    useEffect(() => {
        const cleanOptions = options.map(option => ({
            name: option.name,
            icon: option.value === 'location' ? iconLocation : null,
            value: option.value,
        }));

        dispatch(setCountries(countries))
        dispatch(setFilter(cleanOptions))
        dispatch(setCategories(categories))
        dispatch(setParams(params))
        dispatch(setCountrySelection(countrySelection))
        dispatch(setEndDate(endDate))
        dispatch(setStartDate(normalizedTempStartDate))
    }, [dispatch])

    const fetchData = async () => {
        if (infiniteLoader.page === 1) { setIsLoading(true); }
        try {
            const response: any = await getEventListing({
                ...params,
                ...infiniteLoader
            });
            if (response?.data?.data) {
                const mapped = response?.data?.data?.docs?.map(
                    (event: any) => ({
                        name: event.title,
                        category: event.event_category_id.name,
                        imgUrl:
                            event.cover_image_desktop || NoIMage,
                        date: formatDateStartEnd(event.start_date, null),
                        location: event?.event_venue_id?.name,
                        country_id: event?.event_venue_id?.country_id,
                        country_code: event?.event_venue_id?.country_id?.code,
                        is_private: event.is_private,
                        slug: event?.slug,
                        border: event.cover_image_desktop ? '' : 'border border-ar-purple'
                    })
                );
                const combined = events.concat(mapped);
                setEvents(combined);
                setInfiniteLoader({
                    page: response?.data?.data?.nextPage,
                    hasMore: response?.data?.data?.hasNextPage,
                });
                setIsLoading(false);
            }
        } catch (error) {
            setEvents([]);
            setIsLoading(false);
            console.error(error);
        }
    };


    const fetchCategory = async () => {
        try {
            const response: any = await getEventCategory();
            if (categories.length > 1) {
                dispatch(setCategories([
                    ...categories?.map((c: any) => {
                        return {
                            name: c?.name,
                            value: c?._id ? c?._id : c.value,
                            active: c?.value === params?.byCategory ? true : false
                        }
                    }),
                ]))
            } else {
                if (response?.data?.data) {
                    dispatch(setCategories([
                        ...categoriesInit,
                        ...response?.data?.data.map((c: any) => ({
                            name: c?.name,
                            value: c?._id,
                        })),
                    ]));
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (firstLoad) {
            const firstFetchData = async () => {
                const eventList: any = await getEventListing({
                    byCountry: params.byCountry ? params.byCountry : "",
                    byDate: params.byDate ? params.byDate : "",
                    byCategory: params.byCategory ? params.byCategory : "",
                    page: 1,
                    hasMore: false,
                });

                const eventCountryDropdown: any = await getEventCountryDropdown()

                const mapped = eventList?.data?.data?.docs?.map(
                    (event: any) => ({
                        name: event.title,
                        category: event.event_category_id.name,
                        imgUrl: event.cover_image_desktop || NoIMage,
                        date: formatDateStartEnd(event.start_date, null),
                        location: event?.event_venue_id?.name,
                        country_code: event?.event_venue_id?.country_id?.code,
                        is_private: event.is_private,
                        slug: event?.slug,
                        border: event.cover_image_desktop ? '' : 'border border-ar-purple'
                    })
                );
                setEvents(mapped);

                //get existing country from events
                dispatch(setCountries(eventCountryDropdown?.data?.data?.map((country: any) => ({
                    value: country?._id,
                    label: country?.name,
                    isChecked: false
                }))));

                setInfiniteLoader({
                    page: eventList?.data?.data?.nextPage ?? 1,
                    hasMore: eventList?.data?.data?.hasNextPage,
                });
                setIsLoading(false);
                setFirstLoad(false)
            }
            firstFetchData()
        } else {
            fetchData();
        }
    }, [params]);


    useEffect(() => {
        document.body.classList.remove('overflow-hidden');
        fetchCategory();
    }, []);


    const resetList = () => {
        setEvents([]);
        setInfiniteLoader({ page: 1, hasMore: false })
    }

    const dateFilter = [
        {
            name: t("Today"),
            value: "today",
        },
        {
            name: t("Tomorrow"),
            value: "tomorrow",
        },
        {
            name: t("ThisWeekend"),
            value: "weekend",
        },
    ];

    const selectFilter = (value: any, index: number) => {
        if (value === "date") {
            setShowModal(true);
            setModalBodyContent(value);
        } else if (value === "location") {
            setShowModal(true);
            setModalBodyContent(value);
        }
    };

    const setCalendarData = (item: any, index: number) => {
        setActiveButtonCal(index);
        setCalendarSelectedDate(item.value);
    };

    const clearText = () => {
        const updatedFilter = [...filter];
        const updatedDateFilter = { ...updatedFilter[1], name: t("Date") };
        updatedFilter[1] = updatedDateFilter;
        dispatch(setFilter(updatedFilter));
        setTempStartDate(null);
        dispatch(setStartDate(null));
        dispatch(setEndDate(null));
        setCalendarSelectedDate(null);
    };

    const setRangeDate = () => {
        const updatedFilter = [...filter];
        let startDateSelect = startDate
            ? startDate.toDateString()
            : new Date().toDateString();
        let endDateSelect = endDate ? " - " + endDate.toDateString() : "";

        let dateFilterName = ''
        
        if(' - ' + startDateSelect === endDateSelect){
            dateFilterName = startDateSelect
        } else {
            dateFilterName = startDateSelect + endDateSelect
        }

        const updatedDateFilter = { ...updatedFilter[1], name: dateFilterName };
        updatedFilter[1] = updatedDateFilter;

        dispatch(setFilter(updatedFilter));
        const byDate = endDate
            ? `${new Date(startDate).toISOString()}~${new Date(endDate).toISOString()}`
            : new Date(startDate).toISOString();
        dispatch(setParams({ ...params, byDate }));
        resetList()
        // setStartDate(tempStartDate)
        setShowModal(false);
        setActiveButtonCal(null);
        document.body.classList.remove("overflow-hidden");
    };

    const handleClickCancel = () => {
        document.body.classList.remove("overflow-hidden");
        setShowModal(false);
    };

    const modalCalendar = () => {
        return (
            <div>
                <div className="m-auto bg-ar-black  px-5">
                    <div className="flex items-center pb-3 mt-16 overflow-hidden overflow-x-auto mb-7">
                        {dateFilter.map((item: any, index: any) => {
                            return (
                                <div
                                    key={item.name + index}
                                    className="w-100 whitespace-nowrap mr-[10px]"
                                    onClick={() => setCalendarData(item, index)}
                                >
                                    <CapsuleButton
                                        name={item.name}
                                        active={
                                            activeButtonCal === index
                                                ? true
                                                : false
                                        }
                                        activeBg={"bg-ar-primary"}
                                        bgColor={"bg-transparent"}
                                    />
                                </div>
                            );
                        })}
                    </div>
                    <Calendar
                        setCalendarSelectedDate={setCalendarSelectedDate}
                        calendarSelectedDate={calendarSelectedDate}
                        startDate={startDate}
                        endDate={endDate}
                        // setStartDate={setStartDate}
                        // setEndDate={setEndDate}
                        tempStartDate={tempStartDate}
                        setTempStartDate={setTempStartDate}
                    />
                    <div className="flex justify-end item-center">
                        <div
                            onClick={() => handleClickCancel()}
                            className="mr-7"
                        >
                            <CapsuleButton
                                name={t("Cancel")}
                                shadow={"shadow-none"}
                                paddingx={"px-7"}
                            />
                        </div>
                        <div onClick={() => setRangeDate()}>
                            <CapsuleButton
                                name={t("ApplyEventList")}
                                shadow={"shadow-none"}
                                paddingx={"px-7"}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const modalFilter = () => {
        return (
            <div className="mt-16 px-5">
                <LocationFilter
                    setShowModal={setShowModal}
                    countries={countries}
                    countrySelection={countrySelection}
                    value={params.byCountry || ""}
                    onChange={(newValue: string) => {
                        const filterCheck = filter && filter.map((item: any) => ({
                            ...item,
                            name:
                                item.value === "location"
                                    ? countries.find(
                                        (item: any) => item.value === newValue
                                    )?.label || t('All')
                                    : item.name,
                        }))
                        dispatch(setFilter(
                            filterCheck
                        ))
                        dispatch(setParams({ ...params, byCountry: newValue }));
                        resetList()
                    }}
                    clearSelection={() => {
                        dispatch(setFilter(
                            filter && filter.map((item: any) => ({
                                ...item,
                                name:
                                    item.value === "location"
                                        ? t('All')
                                        : item.name,
                            }))
                        ));
                        dispatch(setParams({ ...params, byCountry: "" }));
                        resetList()
                    }}
                />
            </div>
        );
    };

    return (
        <>
            <Helmet>
                <title>Popular Events | Find Exciting Live Experiences</title>
                <meta property="og:title" content="Popular Events | Find Exciting Live Experiences" />
                <meta property="og:description" content="Find detailed information and secure your tickets for upcoming live concerts, festivals, and performances." />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className="bg-[url('../assets/images/hero-banner.png')] bg-no-repeat bg-cover bg-center w-full md:h-[478px] relative">
                {/* <div className="w-full px-5 xl:w-[1015px] pt-2 lg:pt-3 left-0 right-0 m-auto absolute top-0">
                    <BreadCrumb />
                </div> */}
            </div>
            <PageContainer hideBanner={true} maxWidth="event:w-[1066px]">
                <div className="pt-[6px] px-[18px] pb-[20px] lg:pt-[30px] lg:pb-[28px] lg:px-[30px] relative">
                    <div className="flex py-4 md:hidden">
                        <BreadCrumb />
                    </div>
                    <h2 className="w-100 text-[26px] mb-4 text-white">
                        {t("EventsMainCategory")}
                    </h2>
                    <ul className="flex items-center justify-start pb-3 overflow-hidden overflow-x-auto">
                        {filter && filter.map((item: any, index: number) => {
                            return (
                                <li
                                    key={index}
                                    className={
                                        "mr-[16px] w-100 whitespace-nowrap text-sm font-normal flex item-center "
                                    }
                                >
                                    <div
                                        onClick={() =>
                                            selectFilter(item.value, index)
                                        }
                                    >
                                        <CapsuleButton
                                            key={item.name + index}
                                            name={item.name}
                                            icon={item.icon}
                                            bgColor={"bg-transparent"}
                                        />
                                    </div>
                                    {(tempStartDate || startDate) &&
                                        index === 1 && (
                                            <button onClick={() => {
                                                clearText()
                                                resetList()
                                                dispatch(setParams({ ...params, byDate: '' }))
                                            }}>
                                                <svg
                                                    width="30"
                                                    height="30"
                                                    fill="#6650F2"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g stroke="white">
                                                        <circle
                                                            cx="15"
                                                            cy="15"
                                                            r="11.25"
                                                        />
                                                        <path d="m11.25 18.75 7.5-7.5M18.75 18.75l-7.5-7.5" />
                                                    </g>
                                                </svg>
                                            </button>
                                        )}
                                </li>
                            );
                        })}

                        {categories.map((category: any, index: number) => {
                            return (
                                <li
                                    key={index}
                                    className={
                                        "mr-[16px] w-100 whitespace-nowrap text-sm font-normal flex item-center "
                                    }
                                >
                                    <div
                                        onClick={() => {
                                            dispatch(setParams({
                                                ...params,
                                                byCategory: category.value,
                                            }));
                                            dispatch(setCategories(
                                                categories.map(
                                                    (c: any, i: number) => ({
                                                        ...c,
                                                        active: i === index,
                                                    })
                                                )
                                            ));
                                            resetList()
                                        }}
                                    >
                                        <CapsuleButton
                                            key={category.name + index}
                                            name={category.name}
                                            icon={category.icon}
                                            active={category.active}
                                            activeBg={"bg-white"}
                                            bgColor={"bg-transparent"}
                                        />
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
                <div className="pb-0 px-[22px]">
                    <InfiniteScroll
                        pageStart={infiniteLoader.page}
                        loadMore={fetchData}
                        threshold={200}
                        hasMore={infiniteLoader.hasMore}
                        loader={<h4 className="text-center">Loading...</h4>}
                    >
                        {!isLoading && !events.length ? (
                            <div key="no-result" className="text-center">
                                {t('NoResultAdjustFilter')}
                            </div>
                        ) : (
                            <div key="listing" className="flex flex-wrap text-white item-center justify-center event:justify-start event:columns-3 gap-4">
                                {events.map((item: any, index: number) => {
                                    return (
                                        <CardRounded
                                            key={index}
                                            item={item}
                                            url={item.is_private ? `/events/private/${item.slug}` : `/events/${item.slug}`}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </InfiniteScroll>
                </div>
            </PageContainer>
            {showModal && (
                <Modal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    showCloseBtn={true}
                    footer={false}
                    header={true}
                >
                    {modalBodyContent === "date" && modalCalendar()}
                    {modalBodyContent === "location" && modalFilter()}
                </Modal>
            )}
        </>
    );
};

export default EventListing;