import React from "react";
import MainLayout from "../components/Layout/MainLayout";
import NoNavigationLayout from "../components/Layout/NoNavigationLayout";
import CheckoutResult from "../screens/CheckoutResult";
import HomePage from "../screens/HomePage";
import NotFound from "../screens/NotFound";
import Profile from "../screens/Profile";
import PrivateRouteMiddleware from "./PrivateRouteMiddleware";
import publicRoutes from "./publicRoutes";
import publicRoutesNoNav from "./publicRoutesNoNav";
import SocialProfileLayout from "../components/Layout/SocialProfileLayout";
import SProfile from "../screens/SocialProfile/SProfile";
import ProfileSettings from "../screens/SocialProfile/ProfileSettings";
import SocialTicket from "../screens/SocialProfile/MyTicket";
import SocialTicketDetails from "../screens/SocialProfile/MyTicket/TicketDetails";
import SocialReceipt from "../screens/SocialProfile/MyTicket/Receipt";

const Routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      ...publicRoutes,
      {
        path: "*",
        element: <NotFound />,
      },
      {
        element: <PrivateRouteMiddleware />,
        children: [
          {
            path: "/social-profile",
            element: <SocialProfileLayout />,
            children: [
              {
                index: true,
                element: <SProfile />,
              },
              {
                path: "/social-profile/tickets",
                element: <SocialTicket />,
              },
              {
                path: "/social-profile/tickets/details/:transactionId",
                element: <SocialTicketDetails />,
              },
              {
                path: "/social-profile/tickets/receipt",
                element: <SocialReceipt />,
              },
              {
                path: "/social-profile/settings",
                element: <ProfileSettings />,
              },
            ],
          },
          {
            name: "Profile",
            path: "/profile",
            element: <Profile />,
            // loader: async function ({ params }: any) {
            //   const response = await getProfile();
            //   console.log('loader getProfile')
            //   return response?.data?.data
            // },
          },
        ],
        errorElement: <HomePage />,
      },
      {
        element: <PrivateRouteMiddleware />,
        children: [
          {
            name: "CheckoutResult",
            path: "/events/:slug/:tId/checkout-result", // if pathname changes, need to take a look at MainLayout.js
            element: <CheckoutResult />
          }
        ],
        errorElement: <HomePage />,
      },
    ],
  },
  {
    path: '/',
    element: <NoNavigationLayout />,
    children: [
      ...publicRoutesNoNav,
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  }
];

export default Routes;
