import React from "react";
import CarouselWrapper from "../../../components/CarouselWrapper";
import CapsuleButton from "../../../components/CapsuleButton";
import { useNavigate } from 'react-router-dom';
import { SplideSlide } from "@splidejs/react-splide";

const BannerCarousel = (props: any) => {
    const navigate = useNavigate();
    const sortedData = props?.infoCarousel.sort((a: any, b: any) => a.order - b.order);
    const carouselOption = {
        type: 'loop',
        perPage: 1,
        arrows: false,
        interval: 5000,
        autoplay: true,
        pauseOnHover: true,
        // autoHeight: true,

        height: 'auto',
        // autoHeight: true,
    }

    const reDirect = (url: string) => {
        const isInternal = url[0] === '/'
        if (isInternal) {
            navigate(url)
        } else {
            const processedUrl = url.includes('http') ? url : "https://" + url
            window.open(processedUrl, '_blank');
        }
    }

    const CaraouselWrapper = ({ item }: any) => {
        return <div className="absolute h-full w-full md:max-h-[65vh]"><div className="absolute block md:flex w-full items-end justify-between bottom-0 md:px-[128px] pb-20 md:pb-[80px] z-10">
            <div className={"max-w-[318px] m-auto md:m-0 md:max-w-[357px] w-full text-center md:text-left " + (item?.text_color === '#000000' ? "text-white" : "text-white")}>
                <div className="text-lg font-semibold mb-[10px]">{item?.type_text}</div>
                {/* currently use temp image */}
                {item?.title_image ?
                    <div className="flex items-center justify-center md:justify-start mb-[10px]">
                        <img src={`${item?.title_image}`} className="w-auto h-[86px] my-2" alt="" />
                    </div>
                    :
                    <div className="text-4xl font-semibold leading-9 md:font-normal mb-[10px]">{item?.title_text}</div>
                }
                <div className="text-lg font-semibold leading-7">{item?.subtitle_text}</div>
            </div>
            <div className="font-bold text=[#6650F2] mt-6 md:mt-0 flex justify-center md:justify-normal">
                <div onClick={() => reDirect(item?.url)} >
                    <CapsuleButton name={item?.cta_text} />
                </div>
            </div>
        </div>
            <div className="absolute bottom-0 w-full h-[300px] z-0" style={{ background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 36.46%, rgba(0, 0, 0, 0.75) 100%)" }}></div></div>
    }

    return sortedData.length ? (<CarouselWrapper options={carouselOption} customClass={'main-banner md:max-h-[65vh]'}>
        {sortedData.map((item: any) => {
            return <SplideSlide key={item?._id}>
                {item?.cta_text !== '' ?
                    <>
                        <div className={"flex md:hidden w-full bg-no-repeat bg-cover bg-center relative z-[1]"}>
                            <img
                                className=' w-full relative top-0 left-0 z-[-1]'
                                src={item?.cover_image_portrait}
                                alt={`cover P ${item?._id}`}
                                style={{ maxWidth: "100%" }}
                            />
                            <CaraouselWrapper item={item} />
                        </div>
                        <div className={"hidden md:flex w-full bg-no-repeat bg-cover bg-center relative z-[1]"}>
                            <img
                                className=' w-full relative top-0 left-0 z-[-1]'
                                src={item?.cover_image_landscape}
                                alt={`cover L ${item?._id}`}
                                style={{ maxWidth: "100%" }}
                            />
                            <CaraouselWrapper item={item} />
                        </div>
                    </>
                    :
                    <>
                        <div className={"flex md:hidden cursor-pointer w-full bg-no-repeat bg-cover bg-center bg-image md:bg-d-image relative z-[1]"} onClick={() => reDirect(item?.url)}>
                            <img
                                className='w-full  relative top-0 left-0 z-[-1]'
                                src={item?.cover_image_portrait}
                                alt={`cover P ${item?._id}`}
                                style={{ maxWidth: "100%" }}
                            />
                        </div>
                        <div className={"hidden md:flex cursor-pointer w-full bg-no-repeat bg-cover bg-center bg-image md:bg-d-image relative z-[1]"} onClick={() => reDirect(item?.url)}>
                            <img
                                className='w-full  relative top-0 left-0 z-[-1]'
                                src={item?.cover_image_landscape}
                                alt={`cover L ${item?._id}`}
                                style={{ maxWidth: "100%" }}
                            />
                        </div>
                    </>
                }
            </SplideSlide>
        })
        }
    </CarouselWrapper>) : null
}

export default BannerCarousel;