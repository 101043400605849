import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import langInfo from "../../utils/langInfo";

const supportedLang = langInfo.map((obj:{value:string}) => obj?.value)

type CommonState = {
    loading: boolean,
    loggedIn: boolean,
    from: string,
    country: Array<any> | null,
    locales: any,
    currentLanguage: string,
};

const country:any = localStorage.getItem("country") || "";
const locales:any = localStorage.getItem("locales") || "";
const currentLanguage:any = localStorage.getItem("currentLanguage") || "";

const initialState: CommonState = {
    loading: false,
    loggedIn: false,
    country: country ?? null,
    locales: locales ?? null,
    currentLanguage: currentLanguage ?? supportedLang.includes(window.navigator.language.split('-')[0]) ?? 'en',
    from: "",
};

const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        setLoading: (state, { payload }: PayloadAction<boolean>) => {
            state.loading = payload
        },
        setLoggedIn: (state, { payload }: PayloadAction<boolean>) => {
            state.loggedIn = payload;
        },
        setFrom: (state, { payload }: PayloadAction<string>) => {
            state.from = payload;
        },
        setCountry: (state:any, { payload: country }: PayloadAction<{country: any}>) => {
            state.country = country
            localStorage.setItem("country", JSON.stringify(country));
        },
        setLocales: (state, { payload }: PayloadAction<any>) => {
            state.locales = payload
            localStorage.setItem("locales", JSON.stringify(payload));
        },
        setCurrentLanguage: (state, { payload }: PayloadAction<string>) => {
            state.currentLanguage = payload
        }
    },
});

export const { setLoading, setLoggedIn, setCountry, setLocales, setFrom, setCurrentLanguage} = commonSlice.actions;
export default commonSlice.reducer;
