import { Menu, RadioGroup, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import AriaLogo from "../assets/images/aria-header-logo.svg";
import ProfileIcon from "../assets/images/profile-icon.svg";
import useAuth from "../hooks/useAuth";
import { useAppDispatch, useAppSelector } from "../hooks/useStore";
import i18n from "../languages/i18n";
import { setLoggedIn, setCurrentLanguage } from "../redux/slices/commonSlice";
import EmailAuth from "../screens/Auth";
import SignInForm from "../screens/Auth/SignInForm";
import { logoutServices } from "../utils/apiRequestList/authServices";
import langInfo from "../utils/langInfo";
import Modal from "./Modal";
import { persistStore } from "redux-persist";
import { store } from "../redux/store";

const CheckIcon = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 24 24" fill="none">
      <circle cx="12" cy="12" r="8" fill="#9B8BFF" />
      <path d="M9.5 12L11.3939 13.8939C11.4525 13.9525 11.5475 13.9525 11.6061 13.8939L15.5 10" stroke="white" strokeWidth="1.2" />
    </svg>
  );
};

const persistor = persistStore(store);

const HeaderNavigation = () => {
  const { accessToken } = useAuth()
  const dispatch = useAppDispatch();
  const { loggedIn, locales } = useAppSelector((state) => state.common);
  const { user }: any = useAppSelector((state) => state.userAuth);
  const { t } = useTranslation();
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [menuOverlay, setMenuOverlay] = useState<boolean>(false);
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const [homeNav, setHomeNav] = useState<boolean>(false);
  const [navHeight, setNavHeight] = useState<number>(0);
  const location = useLocation();
  const navRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [showCloseBtn, setShowCloseBtn] = useState(true);
  const [noModalPadding, setNoModalPadding] = useState(false);
  const [filteredLangInfo, setFilteredLangInfo] = useState<any>([]); //const [filteredLangInfo, setFilteredLangInfo] = useState<Record<string, { value: string; displayLang: string }>>({});
  const navigate = useNavigate()

  const handleSelectChange = (event: any) => {
    dispatch(setCurrentLanguage(event.value));
    window.localStorage.setItem("currentLanguage", event.value);
    i18n.changeLanguage(event.value);
    setMenuOverlay(false);
    window.location.reload();
  };

  const languagesHandler = () => {
    const newFilteredLangInfo = langInfo.filter((info: any) => locales.includes(info.value));
    setFilteredLangInfo(newFilteredLangInfo);
  }

  const profile = [
    { name: t("MyProfile"), navigate: "/profile" },
    { name: t("MyTickets"), navigate: "/my-tickets" },
    { name: t("SignOut") },
  ];

  useEffect(() => {
    if (location.pathname === "/") {
      setHomeNav(true);
    } else {
      setHomeNav(false);
    }
  }, [location]);

  useEffect(() => {
    if (locales) {
      languagesHandler();
    }
  }, [locales]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (navRef.current != null) {
      const height: any = navRef.current?.offsetHeight;
      setNavHeight(height);
    }
  }, [navRef.current]);

  const menuClickHandler = () => {
    if (menuOverlay) {
      setMenuOverlay(false);
      if (windowWidth < 768) document.body.classList.remove("overflow-hidden");
    } else {
      setMenuOverlay(true);
      if (windowWidth < 768) document.body.classList.add("overflow-hidden");
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const menuButtonHandle = async (tab: any) => {
    if (tab.name === t("SignOut")) {
      logoutServices({});
      await persistor.purge(); // clearing redux state to initial state
      // dispatch(logout());
      window.location.reload();
    }
    else if (
      [
        t("MyProfile"),
        t("MyTickets"),
      ].includes(tab.name)
    ) {
      navigate(tab.navigate)
    }
  }

  const profileMenuItems = profile.map((tab: any) => (
    <Menu.Item key={tab.name}>
      {({ active }) => (
        <button
          onClick={() => menuButtonHandle(tab)}
          className={`group flex w-full items-center px-2 py-2 text-sm text-center font-bold text-white hover:bg-[#C7BFFA] hover:text-ar-black`}
          style={{ width: "247px", height: "66px" }}
        >
          <span className="w-full">{tab.name}</span>
        </button>
      )}
    </Menu.Item>
  ));

  const navMenu: any = {
    position: "fixed",
    display: menuOverlay ? "flex" : "none",
    backgroundColor: "#211F2E",
    height: "100%",
    width: "100%",
    left: "0",
    top: "0",
    padding: "76px 33px 76px 33px",
    paddingBottom: '0px',
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: "1",
  };

  const closeModal = () => {
    dispatch(setLoggedIn(false));
    setTimeout(() => {
      document.body.classList.remove('overflow-hidden');
    }, 333)
  };

  return (
    <>
      <div
        style={{ height: navHeight }}
        className={`${!homeNav && isSticky ? "relative" : "absolute"}`}
      ></div>
      <div
        ref={navRef}
        className={`main-nav bg-ar-black shadow-md py-[10px] md:py-[26px] 
                    ${isSticky ? "nav-active fixed top-0 left-0 w-full transition-all duration-300" : ""}
                    ${!homeNav && !isSticky ? "relative" : ""}
                   `}
      >
        {/*{!isSticky && homeNav && (*/}
        {/*  <span*/}
        {/*    style={{*/}
        {/*      position: "absolute",*/}
        {/*      left: "0",*/}
        {/*      top: "0",*/}
        {/*      width: "100%",*/}
        {/*      height: "100%",*/}
        {/*      zIndex: -1,*/}
        {/*    }}*/}
        {/*    className="semi-transparent"*/}
        {/*  ></span>*/}
        {/*)}*/}
        <div className="main-nav-inner">
          <div>
            <NavLink to={"/"}>
              <img src={AriaLogo} alt="ARIA" />
            </NavLink>
          </div>
          <div
            style={windowWidth < 768 ? navMenu : {}}
            className="hidden md:flex md:items-center"
          >
            <div>
              <div
                onClick={menuClickHandler}
                className="absolute cursor-pointer close-btn md:hidden"
              ></div>
              <ul
                className={`${homeNav && !isSticky ? "md:text-white " : ""} text-white`}
              >
                <li className="block page-list md:mx-5 md:inline-block w-100 md:w-auto border-b-ar-purple pb-[5px]">
                  <NavLink onClick={menuClickHandler} to={"/events"}>
                    {t("EventsMainCategory")}
                  </NavLink>
                </li>
                <li className="block page-list md:mx-5 md:inline-block w-100 md:w-auto border-b-ar-purple pb-[5px]">
                  <NavLink onClick={menuClickHandler} to={"/artistes"}>
                    {t("ArtistesMainCategory")}
                  </NavLink>
                </li>
                {/* <li className="block page-list md:mx-5 md:inline-block w-100 md:w-auto border-b-ar-purple pb-[5px]">
                  <NavLink onClick={menuClickHandler} to={"/contact"}>
                    {t("ContactMainCategory")}
                  </NavLink>
                </li> */}
              </ul>
            </div>
            {/*<div className="block md:hidden m-language-wrapper">*/}
            {/*  <div className="w-full py-7">*/}
            {/*    <div className="w-full max-w-md mx-auto">*/}
            {/*      <RadioGroup*/}
            {/*        value={currentLanguage}*/}
            {/*        onChange={handleSelectChange}*/}
            {/*      >*/}
            {/*        <div className="max-h-[300px] min-h-[159px] overflow-auto ">*/}
            {/*          <div className="flex flex-col items-center">*/}
            {/*            {filteredLangInfo.map((language: any) => {*/}
            {/*              if (language.value === 'en') return (*/}
            {/*                  <RadioGroup.Option*/}
            {/*                      key={language.name}*/}
            {/*                      value={language}*/}
            {/*                      className={({ active, checked }) =>*/}
            {/*                          `${active ? "" : ""}*/}
            {/*                ${currentLanguage ===*/}
            {/*                          language.value*/}
            {/*                              ? "bg-[#ffffff] text-ar-black"*/}
            {/*                              : "text-ar-purple"*/}
            {/*                          } mb-2 relative flex px-2 py-4 w-full`*/}
            {/*                      }*/}
            {/*                      style={{ height: "66px" }}*/}
            {/*                  >*/}
            {/*                    {() => (*/}
            {/*                        <>*/}
            {/*                          <div className="flex items-center justify-between w-full">*/}
            {/*                            <div className="flex items-center">*/}
            {/*                              <div className="text-sm absolute left-[50%] translate-x-[-50%]">*/}
            {/*                                <RadioGroup.Label as="span">*/}
            {/*                                  {language.name}*/}
            {/*                                </RadioGroup.Label>*/}
            {/*                              </div>*/}
            {/*                            </div>*/}
            {/*                            {currentLanguage === language.value && (*/}
            {/*                                <div className="text-white shrink-0">*/}
            {/*                                  <CheckIcon className="w-6 h-6" />*/}
            {/*                                </div>*/}
            {/*                            )}*/}
            {/*                          </div>*/}
            {/*                        </>*/}
            {/*                    )}*/}
            {/*                  </RadioGroup.Option>*/}
            {/*              )*/}
            {/*            })}*/}
            {/*          </div>*/}
            {/*        </div>*/}
            {/*      </RadioGroup>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
          <div className="flex items-center">
            {/*<div className="hidden md:block">*/}
            {/*  <Menu as="div" className="relative inline-block text-left">*/}
            {/*    <Menu.Button*/}
            {/*      className={`${homeNav && !isSticky && "text-white"*/}
            {/*        } items-center inline-flex w-full justify-center rounded-md pr-[16px] font-medium text-white`}*/}
            {/*    >*/}
            {/*      <span className="uppercase">{currentLanguage}</span>*/}
            {/*      <span className="ml-[2px]">*/}
            {/*        <svg*/}
            {/*          xmlns="http://www.w3.org/2000/svg"*/}
            {/*          width="19"*/}
            {/*          height="19"*/}
            {/*          viewBox="0 0 24 24"*/}
            {/*          fill="none"*/}
            {/*        >*/}
            {/*          <path*/}
            {/*            d="M18 9L12 15L6 9"*/}
            {/*            stroke="white"*/}
            {/*            strokeWidth="1.5"*/}
            {/*          />*/}
            {/*        </svg>*/}
            {/*      </span>*/}
            {/*    </Menu.Button>*/}
            {/*    <Transition*/}
            {/*      as={Fragment}*/}
            {/*      enter="transition ease-out duration-100"*/}
            {/*      enterFrom="transform opacity-0 scale-95"*/}
            {/*      enterTo="transform opacity-100 scale-100"*/}
            {/*      leave="transition ease-in duration-75"*/}
            {/*      leaveFrom="transform opacity-100 scale-100"*/}
            {/*      leaveTo="transform opacity-0 scale-95"*/}
            {/*    >*/}
            {/*      <Menu.Items className="absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg bg-ar-black ring-1 ring-black ring-opacity-5 focus:outline-none">*/}
            {/*        <div*/}
            {/*          className="w-auto px-[10px] py-[13px]"*/}
            {/*          style={{ width: "267px" }}*/}
            {/*        >*/}
            {/*          <RadioGroup*/}
            {/*            value={currentLanguage}*/}
            {/*            onChange={handleSelectChange}*/}
            {/*          >*/}
            {/*            <div className="grid grid-cols-1">*/}
            {/*              {filteredLangInfo.map((language: any) => {*/}
            {/*                if (language.value === 'en') {*/}
            {/*                  return (*/}
            {/*                        <RadioGroup.Option*/}
            {/*                            key={language.name}*/}
            {/*                            value={language}*/}
            {/*                            className={({ active, checked }) =>*/}
            {/*                                `${active ? "" : ""}*/}
            {/*                  ${currentLanguage ===*/}
            {/*                                language.value*/}
            {/*                                    ? "bg-white text-ar-black"*/}
            {/*                                    : "bg-ar-black text-ar-purple hover:text-white hover:bg-[#332B64]"*/}
            {/*                                } cursor-pointer relative flex px-2 py-6 h-[66px] mb-[10px] last:mb-0`*/}
            {/*                            }*/}
            {/*                        >*/}
            {/*                          {() => (*/}
            {/*                              <>*/}
            {/*                                <div className="flex items-center justify-between w-full">*/}
            {/*                                  <div className="flex items-center">*/}
            {/*                                    <div className="text-sm">*/}
            {/*                                      <RadioGroup.Label*/}
            {/*                                          className="absolute text-sm transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2"*/}
            {/*                                          as="span"*/}
            {/*                                      >*/}
            {/*                                        {language.name}*/}
            {/*                                      </RadioGroup.Label>*/}
            {/*                                    </div>*/}
            {/*                                  </div>*/}
            {/*                                  {currentLanguage === language.value && (*/}
            {/*                                      <div className="text-white shrink-0">*/}
            {/*                                        <CheckIcon className="w-6 h-6" />*/}
            {/*                                      </div>*/}
            {/*                                  )}*/}
            {/*                                </div>*/}
            {/*                              </>*/}
            {/*                          )}*/}
            {/*                        </RadioGroup.Option>*/}
            {/*                    )*/}
            {/*                }*/}
            {/*              })}*/}
            {/*            </div>*/}
            {/*          </RadioGroup>*/}
            {/*        </div>*/}
            {/*      </Menu.Items>*/}
            {/*    </Transition>*/}
            {/*  </Menu>*/}
            {/*</div>*/}
            <div className="mr-5 md:mr-0">
              {accessToken ?
                <Menu as="div" className="relative flex cursor-pointer" onClick={()=> navigate('/social-profile')}>
                  <div className="itext-white hover:bg-opacity-30">
                    <img src={ProfileIcon} alt="" />
                  </div>
                </Menu>
                :
                <button className='border border-[#9B8BFF] rounded-[46px] py-[4px] px-[12px]' onClick={() => {
                  dispatch(setLoggedIn(true))
                }}>{t('LogInHeader')}</button>
              }
            </div>
            <div
              onClick={menuClickHandler}
              className="flex cursor-pointer burger-menu md:hidden"
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
      </div>
      <EmailAuth isOpen={isOpen} setIsOpen={setIsOpen} />


      {loggedIn &&
        <Modal
          backdrop={true}
          width={'md:w-[480px]'}
          showModal={loggedIn}
          setShowModal={(bool: boolean) => {
            dispatch(setLoggedIn(bool))
          }}
          footer={false}
          header={true}
          closeBtnPosition={'right-[30px] top-[59px] md:right-[34px] md:top-[31px]'}
          showCloseBtn={showCloseBtn}
          noModalPadding={noModalPadding}
          borderRadius={'md:rounded-[15px]'}
        >
          <div className="">
            <SignInForm
              closeModal={closeModal}
              hideCloseBtn={(v: any) => setShowCloseBtn(v)}
              hideModalPadding={(v: any) => setNoModalPadding(v)}
            />
          </div>
        </Modal>
      }

    </>
  );
};

export default HeaderNavigation;
