import React, {useEffect, useState} from 'react';
import BackArrow from '../../../assets/images/icons/back-arrow.png';
import Location from "../../../assets/images/icons/location-profile.png";
import moment from "moment";
import {useNavigate} from "react-router-dom";

const AllEventsAttendances = (props:any) => {
    const navigate = useNavigate();
    const [data, setData] = useState<any>()
    const [month, setMonth] = useState<any>()

    useEffect(() => {
        if (props?.upcomingAttendances) {
            setData(props?.upcomingAttendances);
            setMonth(props?.month)
        }
    }, [props?.upcomingAttendances]);

    const formattedMonth = (date:any, shortForm: boolean) => {
        if (shortForm) {
            return moment(date).format("MMM");
        } else {
            return moment(date).format("MMMM");
        }
    }
    const formattedDay = (date:any) => {
        return moment(date).format("D")
    }
    const formattedYear = (date:any) => {
        return moment(date).format("YYYY")
    }

    return (
        <>
            <div className='flex items-center cursor-pointer' onClick={() => props?.back(false)}>
                <span className='w-[15px] mr-[12px]'><img className='w-full' src={BackArrow} alt=""/></span>
                <span>Upcoming Attendances</span>
            </div>
            <div className="mb-[15px] mt-[30px] flex flex-col w-full md:max-w-[392px]">

                {
                    data &&
                    <>
                        {
                            month.map((item:any, index:number) => {
                                return <div className='mb-[21px]' key={index}>
                                    <div className='flex items-center mb-[8px]'>
                                        <span className='capitalize mr-[16px]'>{formattedMonth(data.eventByDate[item][0].start_date, false)}</span>
                                        <span className='text-[20px] opacity-[0.5]'>{formattedYear(item.split(',')[1])}</span>
                                    </div>
                                {
                                    data.eventByDate[item].map((data:any, index:number) => {
                                        return (
                                            <div className='flex cursor-pointer' key={index} onClick={() => navigate(`/events/${data.slug}`)}>
                                                <div className='min-w-[320px] mr-[10px] '>
                                                    <div className='flex mb-[15px] rounded-[10px] overflow-hidden'>
                                                        <div className='p-[15px] bg-ar-purple min-w-[64px]'>
                                                            <p className='text-center font-bold text-[26px] leading-[26px] mb-[5px]'>{formattedDay(data?.start_date)}</p>
                                                            <p className='text-center leading-[14px] uppercase'>{formattedMonth(data?.start_date, true)}</p>
                                                        </div>
                                                        <div
                                                            className='py-[15px] px-[23px] pr-[8px] bg-[#332B64] overflow-hidden leading-[26px] w-full flex flex-col justify-center'>
                                                            <p className='font-bold text-[14px] leading-[26px] truncate mb-[5px]'>{data?.title}</p>
                                                            <p className='text-[14px] text-[#7E7E7E] flex items-center leading-[14px]'>
                                                                <span className='min-w-[10px] max-w-[10px]'><img
                                                                    className='mt-[-2px] w-full' src={Location}/></span>
                                                                <p className='truncate pl-[7px]'>{data?.venue_name.name}</p>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                </div>
                            })
                        }
                    </>
                }
            </div>
        </>
    )
}

export default AllEventsAttendances;