/* eslint-disable jsx-a11y/alt-text */
import React, {useEffect, useState} from "react"
import QRCode from 'qrcode';

const QRCodeComponent = (props: {content: string, className?: string, style?:any}) => {
    const [qrImageSrc, setQrImageSrc] = useState('');

    useEffect(() => {
        QRCode.toDataURL(props.content, function (err:any, url:any) {
            if (err) throw err
            setQrImageSrc(url)
        })
    }, [props])

    return (
        <div>
            <img src={qrImageSrc} className={props?.className} style={props?.style} />
        </div>
    )
}

export default QRCodeComponent;