import { get } from "../AxiosRequest";

const artistListing = async (queryParams?: string) => {
  return await get(`artist/directory?${queryParams}`);
};

const artistBySlug = async (slug: string) => {
  return await get(`artist/detail/${slug}`);
};

export { artistListing, artistBySlug };
