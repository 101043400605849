import { get, post, put, remove } from '../AxiosRequest'

const registrationTest = async (params: any) => {
    return await post('/user/test', { params });
}
const login = async (params: any) => {
    return await post('/user/login', { params });
}

const countries = async () => {
    return await get(`/country/get-country`);
}

const loginSignUp = async (params: any) => {
    return await post(`/customer/login-sign-up`, params);
}

const verifyOTP = async (params: any) => {
    return await post(`/customer/verify-otp`, params);
}

const createAccount = async (params: any) => {
    return await post('/customer/register-info', params);
}

const emailVerified = async (token: any, params: any) => {
    return await post(`customer/verify-email/${token}`, params)
}

const resendEmail = async (obj:any) => {
    return await post('customer/resend-verify-email', obj)
}

const getCustomerEmail = async (id:any) => {
    return await get(`customer/email/${id}`)
}

const logoutServices = async (params: any) => {
    return await post('customer/logout', params);
}

const getCustomerAttendancesEvent = async () => {
    return await get('customer/upcoming-attendance')
}

export {
    registrationTest,
    login,
    countries,
    loginSignUp,
    verifyOTP,
    createAccount,
    emailVerified,
    resendEmail,
    getCustomerEmail,
    logoutServices,
    getCustomerAttendancesEvent
};