import {getI18n} from "react-i18next";
import i18n from "../languages/i18n";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (startDate: any, endDate: any) {
    const { t } = getI18n();

    const startDay = startDate.getDate().toString().padStart(2, '0');
    const startMonth = startDate.toLocaleString('en-GB', { month: 'long' });
    const endDay = endDate.getDate().toString().padStart(2, '0');
    const endMonth = endDate.toLocaleString('en-GB', { month: 'long' });
    const year = endDate.getFullYear();
    const dayUnit = i18n.language.includes("zh") ? '日' : i18n.language.includes("ja") ? '日' : ''
    const yearUnit = i18n.language.includes("zh") ? '年' : i18n.language.includes("ja") ? '年' : ''

    if (i18n.language.includes("zh") || i18n.language.includes("ja")) {
        return `${year}${yearUnit} ${t(startMonth)} ${startDay}${dayUnit} - ${year}${yearUnit} ${t(endMonth)} ${endDay}${dayUnit} `;
    } else if (i18n.language.includes("en")) {
        return `${startDay}${dayUnit} ${t(startMonth)} - ${endDay}${dayUnit} ${t(endMonth)} ${year}`;
    }

};