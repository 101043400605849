import {getI18n} from "react-i18next";
import i18n from "../languages/i18n";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (startDate: any, endDate: any) {
    const salesStartFormatted = startDate;
    const salesEndFormatted = endDate;
    const salesStartDate = new Date(salesStartFormatted);

    const currentDate = new Date();

    const { t } = getI18n();

    function formatDateString(dateString:string) {
        const date = new Date(dateString);
        const options:any = { day: '2-digit', month: 'short', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        const [month, day, year] = formattedDate.split(' ');
        const dayUnit = i18n.language.includes("zh") ? '日' : i18n.language.includes("ja") ? '日' : ''
        const yearUnit = i18n.language.includes("zh") ? '年' : i18n.language.includes("ja") ? '年' : ''

        if (i18n.language.includes("zh") || i18n.language.includes("ja")) {
            return `${year}${yearUnit} ${t(month)}  ${day.replace(',', '')}${dayUnit}`;
        } else if (i18n.language.includes("en")) {
            return `${day.replace(',', '')}${dayUnit} ${t(month)} ${year}`;
        }

      }

    let resultDate = '';
    if(salesEndFormatted) {
        if (currentDate < salesStartDate) {
            resultDate = `${t('SalesStartOn')} ${formatDateString(salesStartFormatted)}`;
        } else {
            resultDate = `${t('SalesEndOn')} ${formatDateString(salesEndFormatted)}`;
        }
    } else {
        resultDate = `${formatDateString(salesStartFormatted)}`;
    }
    return resultDate;
}