import React from "react";
import ReactDOM from "react-dom/client";

//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";

import "../src/styles/index.scss";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { init as axiosInit } from "./utils/AxiosRequest";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

axiosInit();
root.render(<App />);

reportWebVitals();
