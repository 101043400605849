import { Skeleton } from 'primereact/skeleton';
import React, { useEffect, useState, useRef } from 'react';
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import ShareBtn from '../../assets/images/icons/share.png';
import NoImage from '../../assets/images/no-image.png';
import PromoCode from '../../components/Form/PromoCode';
import Modal from '../../components/Modal';
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { AddonsCartItem, TicketCartItem, calculateTicketCheckout, removeTicketOrAddonFromCart, setItemCart, setPromotionObj } from "../../redux/slices/ticketSlice";
import { resendEmail } from '../../utils/apiRequestList/authServices';
import { getEventTicketList, prepareCheckout, ticketSummary } from '../../utils/apiRequestList/ticketServices';
import formatDateRange from '../../utils/formatDateRange';
import SentVerification from '../EmailVerificationPage/components/SentVerification';
import Accordion from "./Accordion";
import ResendVerificationEmail from './components/ResendVerificationEmail';
import { setLoggedIn } from "../../redux/slices/commonSlice";
import { Tooltip } from 'primereact/tooltip';
import BookingItems from '../../components/Receipt/BookingItems';

const EventPurchaseTicket = () => {
    const informationIcon = <svg width="21" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#a)"><circle cx="10.5" cy="10.5" r="6" stroke="#28D7FE" /><path d="M10.784 11.368a.31.31 0 0 1-.24-.096.434.434 0 0 1-.08-.272c0-.165.019-.32.056-.464.043-.15.11-.299.2-.448.09-.15.21-.307.36-.472.112-.128.2-.24.264-.336a1.038 1.038 0 0 0 .192-.568.502.502 0 0 0-.192-.416c-.128-.101-.31-.152-.544-.152-.213 0-.408.03-.584.088-.17.053-.344.13-.52.232a.576.576 0 0 1-.32.096.367.367 0 0 1-.224-.104.403.403 0 0 1-.112-.216.426.426 0 0 1 .04-.264.632.632 0 0 1 .232-.248c.219-.144.47-.253.752-.328.283-.08.55-.12.8-.12.336 0 .63.056.88.168.256.112.453.267.592.464.144.197.216.43.216.696 0 .165-.024.323-.072.472-.048.144-.125.29-.232.44a3.424 3.424 0 0 1-.44.472c-.165.15-.299.285-.4.408a1.61 1.61 0 0 0-.216.352 1.545 1.545 0 0 0-.088.36.252.252 0 0 1-.096.184.323.323 0 0 1-.224.072Zm-.016 1.672a.595.595 0 0 1-.44-.168.6.6 0 0 1-.16-.432c0-.17.053-.31.16-.416a.595.595 0 0 1 .44-.168.563.563 0 0 1 .592.584.6.6 0 0 1-.16.432c-.101.112-.245.168-.432.168Z" fill="#28D7FE" /></g><defs><filter id="a" x="0" y="4" width="21" height="21" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /><feOffset dy="4" /><feGaussianBlur stdDeviation="2" /><feComposite in2="hardAlpha" operator="out" /><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" /><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_8257_50610" /><feBlend in="SourceGraphic" in2="effect1_dropShadow_8257_50610" result="shape" /></filter></defs></svg>
    const { slug }: any = useParams();
    const leftPanelRef = useRef(null);
    const [searchParams] = useSearchParams();
    const { dTwo, dOne } = Object.fromEntries(searchParams.entries());
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { itemCart }: any = useAppSelector((state) => state.ticket);
    const { user, accessToken }: any = useAppSelector((state) => state.userAuth);
    const [toggleOrderSummary, setToggleOrderSummary] = useState(false);
    const [accordionOpen, setAccordionOpen] = useState<any>({});
    const [data, setData] = useState<any>(null);
    const [itemsQty, setItemsQty] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [ticketSummaryData, setTicketSummaryData] = useState<any>(null);
    const [currentPromoCode, setCurrentPromoCode] = useState(itemCart?.promotionObj?.promoCode ?? '');
    const [promoCodeUpdate, setPromoCodeUpdate] = useState(0)
    const [showModal, setShowModal] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [summaryLoader, setSummaryLoader] = useState(false);
    const [showAddons, setShowAddons] = useState<boolean>(false);


    const fetchData = async () => {
        try {
            let response;
            if (itemCart.promotionObj) {
                const promoCode = itemCart.event_slug === slug ? currentPromoCode : '';
                response = await getEventTicketList(slug, promoCode, !data, dTwo, dOne);
            } else {
                response = await getEventTicketList(slug, currentPromoCode, !data, dTwo, dOne);
            }

            setData(response?.data?.data);

            if (itemCart.event_slug !== slug) {
                setCurrentPromoCode('');
            }

            // Remove invalid items in itemCart based on latest response
            // handle sold out case
            if (itemCart.event_slug === slug) {
                const remainingTixByIds: any = {};
                response?.data?.data?.ticketGroups?.forEach((group: any) => {
                    remainingTixByIds[group?.tickets[0]?._id] = group?.tickets[0]?.available_amount
                })
                const addonIds = response?.data?.data?.addons?.map((addon: any) => addon?._id)
                itemCart.tickets.forEach((item: TicketCartItem) => {
                    // undefined = invalid ticket ;; value 0 = out of stock
                    if (!remainingTixByIds[item.id]) {
                        dispatch(removeTicketOrAddonFromCart(item.id))
                    }
                })
                itemCart.addons.forEach((item: AddonsCartItem) => {
                    if (!addonIds.includes(item.id)) {
                        dispatch(removeTicketOrAddonFromCart(item.id))
                    }
                })
            }

            if (response?.data?.data?.promotion) {
                setCurrentPromoCode(response?.data?.data?.promotion?.promoCode)
                dispatch(setPromotionObj(response?.data?.data?.promotion))
            } else {
                dispatch(setPromotionObj(null))
            }

            fetchSummaryData(response?.data?.data?.event?.id);

        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        let isOpenAcc: any = {}
        itemCart.tickets.forEach((item: TicketCartItem) => {
            isOpenAcc[item.id] = true
        })

        itemCart.addons.forEach((item: AddonsCartItem) => {
            isOpenAcc[item.id] = true
        })
        setAccordionOpen(isOpenAcc)

        if (dTwo && dOne && !accessToken) {
            dispatch(setLoggedIn(true));
        }

        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }, [])

    useEffect(() => {
        fetchData()
    }, [promoCodeUpdate]);


    const fetchSummaryData = async (currentEventId: string) => {
        if (currentEventId === itemCart.event_id) {
            const response = await ticketSummary(slug, itemCart)
            setTicketSummaryData(response?.data?.data)
        }

        setSummaryLoader(false)
    }
    useEffect(() => {
        setSummaryLoader(true)
        let temp: any = {
            tickets: {},
            addons: {}
        }
        itemCart.tickets.forEach((item: TicketCartItem) => {
            temp.tickets[item.id] = item.count
        })

        itemCart.addons.forEach((item: AddonsCartItem) => {
            temp.addons[item.id] = item.count
        })

        setItemsQty(temp)

        let debounceTimer: any;
        debounceTimer = setTimeout(() => {
            fetchSummaryData(data?.event?.id)
        }, 1000);
        return () => clearTimeout(debounceTimer);

    }, [itemCart]);

    const orderSummaryHandler = () => {
        if (toggleOrderSummary) {
            setToggleOrderSummary(false)
        } else {
            setToggleOrderSummary(true)
        }
    }

    useEffect(() => {
        if (toggleOrderSummary) document.body.classList.add('overflow-hidden');
        return () => {
            document.body.classList.remove('overflow-hidden');
        }
    }, [toggleOrderSummary]);

    const toggleAccordion = (itemId: string) => {
        setAccordionOpen((prevState: any) => {
            const temp = { ...prevState }
            temp[itemId] = !prevState[itemId]
            return temp
        });
    };

    const onBeforeCheckout = async () => {
        setIsLoading(true)
        if (ticketSummaryData?.addonList?.length > 0 || ticketSummaryData?.ticketList?.length > 0) {
            const prepCheckout: any = await prepareCheckout(data.event?.slug, itemCart).catch((error) => {
                setIsLoading(false)

                // handled cart ticket out stock
                // , cart ticket stock not enough
                // and Promo code finished
                if (error?.response?.status === 404) {
                    fetchData()
                }

                const response = error?.response?.data
                if (response?.isEVerify === false) {
                    setShowModal(true)
                }
            })
            if (!prepCheckout) return

            const fullName = `${prepCheckout?.data?.data?.first_name} ${prepCheckout?.data?.data?.last_name}`
            const email = prepCheckout?.data?.data?.email_address
            const callCode = prepCheckout?.data?.data?.calling_code
            const contactNo = prepCheckout?.data?.data?.contact_no

            dispatch(calculateTicketCheckout({
                currency: data?.event?.currency_id?.currency_code,
                total: parseFloat(ticketSummaryData?.grandTotal?.amount),
                fullName,
                email,
                callCode,
                contactNo,
                summary: ticketSummaryData,
                event: data?.event,
                transaction_id: prepCheckout?.data?.data?.transaction_id,
                session_expired: prepCheckout?.data?.data?.session_expired,
                order_id_with_prefix: prepCheckout?.data?.data?.order_id_with_prefix,
            }))
            navigate('/checkout')
        }
        setIsLoading(false)
    }


    const [scrollY, setScrollY] = useState(0);
    const scrollThreshold = 77; //this is navigation height
    const [float, setFloat] = useState<boolean>(false)

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // Check if the scroll position is beyond the threshold
        if (scrollY > scrollThreshold) {
            setFloat(true)
        } else {
            setFloat(false)
        }
    }, [scrollY, scrollThreshold])

    const [leftPanelHeight, setLeftPanelHeight] = useState<any>(0)
    useEffect(() => {
        if (leftPanelRef.current) {
            //@ts-ignore
            const height = leftPanelRef.current.offsetHeight;
            setLeftPanelHeight(height);
        }
    }, [summaryLoader]);

    const [isShorter, setIsShorter] = useState(false);
    const minHeight = leftPanelHeight  //682
    const orderSummaryHeight = window.innerHeight - 118;



    useEffect(() => {
        function checkScreenHeight() {
            const screenHeight = window.innerHeight - 100;
            if (screenHeight < minHeight) {
                setIsShorter(true);
            } else {
                setIsShorter(false);
            }
        }
        checkScreenHeight();

        window.addEventListener('resize', checkScreenHeight);
        return () => {
            window.removeEventListener('resize', checkScreenHeight);
        };
    }, [minHeight]);

    const SummaryView = () => {
        return <>
            <div className='text-white mb-[15px] pb-2 font-bold border-b-[0.5px] border-[#919191] text-base pt-[48px] md:pt-[15px]'>{t('OrderSummary')}</div>
            {!summaryLoader ? <BookingItems data={ticketSummaryData} purpleLine={false} />
                :
                <div className='text-sm md:text-base'>
                    <ul className='border-b-[0.5px] border-[#919191] pb-[18px] mb-[18px]'>
                        <li className='flex justify-between mb-2'>
                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                        </li>
                        <li className='flex justify-between mb-2'>
                            <Skeleton width="12rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                        </li>
                    </ul>
                    <ul className='border-b-[0.5px] border-[#919191] pb-[18px] mb-[18px]'>
                        <li className='flex justify-between mb-2'>
                            <Skeleton width="6rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                        </li>
                        <li className='flex justify-between mb-2'>
                            <Skeleton width="10rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                        </li>
                        <li className='flex justify-between mb-2'>
                            <Skeleton width="12rem" className="mb-2"></Skeleton>
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                        </li>
                    </ul>
                    <div className='flex justify-between text-white font-bold'>
                        <Skeleton width="6rem" className="mb-2"></Skeleton>
                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                    </div>
                </div>
            }
        </>
    }

    const [accordionShow, setAccordionShow] = useState<boolean>(false)
    const BookingFeeInfo = () => {
        const moreDescription = (fee_amount_display: string, fee_percentage: number) => {
            let text = '';
            if (fee_amount_display) {
                text = t('IncurAmount', { x: fee_amount_display })
            } else {
                text = t('IncurPercentage', { x: fee_percentage })
            }
            return text;
        }

        const handleAccordion = () => {
            setAccordionShow(!accordionShow)
        }

        return <> {ticketSummaryData?.bookingFeeInfo?.length > 0 && <div className="mt-[25px] md:mt-0 mb-[9px]">
            <div onClick={() => handleAccordion()} className='flex items-center justify-between border-b border-b-[#919191] pb-1 mb-2'>
                <div className="font-['Nunito'] text-sm font-normal"> {t('BookingFeesDiffer')}</div >
                <div className={`${accordionShow ? 'rotate-0' : 'rotate-180'} transition-all`}><svg width="14" height="9" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 8 7 2 1 8" stroke="#CCC3FF" strokeWidth="2" /></svg></div>
            </div>
            {accordionShow && <ul>
                {
                    ticketSummaryData?.bookingFeeInfo?.map((item: any, index: number) => {
                        return <li key={index} className="flex items-start font-['Nunito'] text-[11px] font-normal text-[#CACACA]">
                            <span>-</span>
                            {!isNaN(item.min_range) && item.min_range === 0 && item.max_range && index === 0
                                ? `${t('TicketPriceBetween', { y: item.max_range_display })} ${moreDescription(item.fee_amount_display, item.fee_percentage)}`
                                :
                                !isNaN(item.min_range) && item.max_range
                                    ? `${t('TicketPriceBetween2', { x: item.min_range_display, y: item.max_range_display })} ${moreDescription(item.fee_amount_display, item.fee_percentage)}`
                                    : `${t('TicketPriceAbove', { x: item.min_range_display })} ${moreDescription(item.fee_amount_display, item.fee_percentage)}`
                            }
                        </li>
                    })
                }
            </ul>}

        </div>}</>
    }

    const onApplyPromoCode = () => {
        setPromoCodeUpdate(prev => (prev + 1))
    }

    const onRemovePromoCode = () => {
        setCurrentPromoCode('')
        setPromoCodeUpdate(prev => (prev + 1))
    }

    const onItemAddQty = (item: any) => {
        if ('tickets' in item) {
            const existTicket = itemCart.tickets.find(((ticket: TicketCartItem) => {
                return ticket.id === item.tickets[0].id
            }))

            if (existTicket) {
                const count = existTicket.count + 1 > item?.tickets[0]?.max_tickets_per_order ?
                    existTicket.count : existTicket.count + 1

                dispatch(setItemCart({
                    event_id: data?.event?._id ?? '',
                    event_slug: data?.event?.slug ?? '',
                    ticket: {
                        id: item?.tickets[0]?.id,
                        ticket_obj: item?.tickets[0],
                        count
                    },
                }))
            } else {
                if (item?.tickets[0]?.min_tickets_per_order && item?.tickets[0]?.min_tickets_per_order > 1) {
                    toast.info(`Minimum order amount: ${item?.tickets[0]?.min_tickets_per_order}`, {
                        position: toast.POSITION.TOP_CENTER,
                        autoClose: 1500,
                        style: {
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '4px 20px'
                        }
                    });
                }

                dispatch(setItemCart({
                    event_id: data?.event?._id ?? '',
                    event_slug: data?.event?.slug ?? '',
                    ticket: {
                        id: item?.tickets[0]?.id,
                        ticket_obj: item?.tickets[0],
                        count: item?.tickets[0]?.min_tickets_per_order ?? 1
                    },
                }))
            }
        } else {
            const existAddon = itemCart.addons.find(((addon: AddonsCartItem) => {
                return addon.id === item?.id
            }))

            if (existAddon) {
                const count = existAddon.count + 1 > item?.max_addons_per_order ?
                    existAddon.count : existAddon.count + 1

                dispatch(setItemCart({
                    event_id: data?.event?._id ?? '',
                    event_slug: data?.event?.slug ?? '',
                    addon: {
                        id: item?.id,
                        addons_obj: item,
                        count
                    },
                }))
            } else {
                dispatch(setItemCart({
                    event_id: data?.event?._id ?? '',
                    event_slug: data?.event?.slug ?? '',
                    addon: {
                        id: item?.id,
                        addons_obj: item,
                        count: item?.min_addons_per_order ?? 1
                    },
                }))
            }
        }
    }

    const onItemMinusQty = (item: any) => {
        if ('tickets' in item) {
            const existTicket = itemCart.tickets.find(((ticket: TicketCartItem) => {
                return ticket.id === item.tickets[0].id
            }))

            if (existTicket) {
                const count = existTicket.count - 1 < item?.tickets[0]?.min_tickets_per_order ?
                    0 : existTicket.count - 1

                if (!count) {
                    dispatch(removeTicketOrAddonFromCart(item?.tickets[0]?.id))
                } else {
                    dispatch(setItemCart({
                        event_id: data?.event?._id ?? '',
                        event_slug: data?.event?.slug ?? '',
                        ticket: {
                            id: item?.tickets[0]?.id,
                            ticket_obj: item?.tickets[0],
                            count
                        },
                    }))
                }
            }
        } else {
            const existAddon = itemCart.addons.find(((addon: AddonsCartItem) => {
                return addon.id === item?.id
            }))

            if (existAddon) {
                const count = existAddon.count - 1 < item?.min_addons_per_order ?
                    0 : existAddon.count - 1

                if (!count) {
                    dispatch(removeTicketOrAddonFromCart(item?.id))
                } else {
                    dispatch(setItemCart({
                        event_id: data?.event?._id ?? '',
                        event_slug: data?.event?.slug ?? '',
                        addon: {
                            id: item?.id,
                            addons_obj: item,
                            count
                        },
                    }))
                }
            }
        }
    }

    const resendVerificationHandle = async () => {
        setIsLoading(true);
        const response = await resendEmail({
            emailAddress: user?.email_address
        });
        if (response?.data?.success) {
            setEmailSent(true)
        }
        setIsLoading(false);
    }

    return (
        <>
            <Helmet>
                <title>{`${data?.event?.title} | Buy Ticket | Aria`}</title>
                <meta property="og:title" content={`${data?.event?.title} | Buy Ticket | Aria`} />
                <meta property="og:description" content="Don't miss out on the excitement – book your tickets today and guarantee your spot at the must-attend experiences!" />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className='relative'>
                <div className="container mx-auto xl:max-w-[1080px] max-w-[calc(100%-20px)] py-3 md:pt-0 pb-[60px] md:py-[70px]">
                    <div className='flex flex-col md:flex-row justify-between gap-4 w-full'>
                        <div className='md:relative left-0 top-0 flex flex-col w-full md:max-w-[372px] md:w-[372px]'>
                            <div
                                ref={leftPanelRef}
                                className={`yolo w-full md:overflow-auto md:w-[255px] lg:w-[354px] xl:w-[382px] sticky transition-all ease-in-out top-0 left-0 md:left-[auto] ${float ? 'md:top-[100px]' : ''} ${(isShorter && float) ? `overflow-scroll h-[${orderSummaryHeight}px] ` : ''}`}
                                style={{
                                    height: `${(isShorter && float) ? orderSummaryHeight : ''}px`,
                                    transitionDuration: '0.5s',
                                }}
                            >
                                <div className='md:rounded-[15px] overflow-hidden md:mb-[19px]'>
                                    <img className={`w-full md:rounded-[15px] h-auto hidden md:block ${data?.event?.cover_image_mobile ? '' : 'border border-ar-purple'}`} src={data?.event?.cover_image_desktop || NoImage} alt="" />

                                    <img className={`w-full md:rounded-[15px] h-auto block md:hidden  ${data?.event?.cover_image_mobile ? '' : 'border border-ar-purple'}`} src={data?.event?.cover_image_mobile || NoImage} alt="" />

                                </div>
                                {(ticketSummaryData?.addonList?.length > 0 || ticketSummaryData?.ticketList?.length > 0) ? <>
                                    <div className='order-summary hidden md:block rounded-[15px] bg-ar-dark-purple py-[0px] px-[18px]'>
                                        <SummaryView />
                                    </div>
                                    <div className=' mt-[19px] order-summary hidden md:block rounded-[15px] bg-ar-dark-purple py-[15px] px-[18px]'>
                                        <BookingFeeInfo />
                                    </div>
                                </>
                                    :
                                    summaryLoader && <div>{t('LoadingOrderSum')}</div>}
                            </div>
                        </div>
                        {
                            data &&
                            <div className='flex flex-col rounded-[15px] bg-ar-black w-full md:max-w-[650px] md:w-[650px] py-[14px] px-[13px] md:py-[40px] md:px-[40px] md:border-ar-purple md:border-[0.5px] z-10'>
                                <div className='md:mb-[16px] mb-7 flex justify-between'>
                                    <div className='text-[14px] md:text-[16px]'>
                                        <h3 className='text-white text-[16px] md:text-[20px] font-bold'>{data?.event?.title}</h3>
                                        <div>{(data?.event?.start_date && data?.event?.end_date) ? formatDateRange(new Date(data?.event?.start_date), new Date(data?.event?.end_date)) : ''}</div>
                                        <div className='md:max-w-[336px]'>{data?.event?.event_venue_id?.name}</div>
                                    </div>
                                    <div className='md:hidden min-w-[36px]'>
                                        <img className='w-full' src={ShareBtn} alt="" />
                                    </div>
                                </div>

                                <div>
                                    <PromoCode
                                        value={currentPromoCode}
                                        setValue={setCurrentPromoCode}
                                        onApply={onApplyPromoCode}
                                        onRemove={onRemovePromoCode}
                                        errMessage={data?.promotionErr}
                                        isValid={(data?.promotion && !data?.promotionErr)}
                                        isResponseExists={(data?.promotion || data?.promotionErr)}
                                    />
                                </div>

                                {
                                    (data?.addons?.length > 0 || data?.ticketGroups?.length > 0) ? <>
                                        <div className='p-4 border-1 text-[16px] md:text-[20px] underline text-center font-bold border-ar-purple border-b-[0.5px]'>
                                            {showAddons ? 'Add-on(s) Selection' : 'Ticket(s) Selection' }
                                        </div>

                                        <div>
                                            {
                                                !!data?.ticketGroups?.length && !showAddons &&
                                                data?.ticketGroups?.map((item: any, index: number) => {
                                                    return <Accordion
                                                        currentValue={itemsQty?.tickets[item?.tickets[0]?.id]}
                                                        isOpen={accordionOpen[item?.tickets[0]?.id]}
                                                        bgColor='ar-dark-purple'
                                                        toggleAccordion={() => toggleAccordion(item?.tickets[0]?.id)}
                                                        key={item.id}
                                                        data={item}
                                                        promotionObj={data?.promotion}
                                                        onPlus={() => { onItemAddQty(item) }}
                                                        onMinus={() => { onItemMinusQty(item) }}
                                                        disabledMinus={(!itemsQty?.tickets[item?.tickets[0]?.id] || (!itemsQty?.tickets[item?.tickets[0]?.id] && itemsQty?.tickets[item?.tickets[0]?.id] < item?.tickets[0]?.min_tickets_per_order))}
                                                        disabledPlus={(item?.tickets[0]?.available_amount <= 0 || (itemsQty?.tickets[item?.tickets[0]?.id] && (itemsQty?.tickets[item?.tickets[0]?.id] >= item?.tickets[0]?.available_amount || itemsQty?.tickets[item?.tickets[0]?.id] >= item?.tickets[0]?.max_tickets_per_order)))}
                                                    />
                                                })
                                            }

                                            {
                                                !!data?.addons?.length && showAddons &&
                                                data?.addons?.map((item: any, index: number) => {
                                                    return <Accordion
                                                        currentValue={itemsQty?.addons[item?.id]}
                                                        isOpen={accordionOpen[item?.id]}
                                                        toggleAccordion={() => toggleAccordion(item?.id)}
                                                        addOns
                                                        key={item.id}
                                                        data={item} bgColor='ar-dark-purple'
                                                        onPlus={() => { onItemAddQty(item) }}
                                                        onMinus={() => { onItemMinusQty(item) }}
                                                        disabledMinus={(!itemsQty?.addons[item?.id] || (!itemsQty?.addons[item?.id] && itemsQty?.addons[item?.id] < item?.min_addons_per_order))}
                                                        disabledPlus={(itemsQty?.addons[item?.id] && itemsQty?.addons[item?.id] >= item?.max_addons_per_order)}
                                                    />
                                                })
                                            }

                                            {/*<div className='text-[#919191] mt-1 pb-[10px] text-[12px] md:text-[14px]'>*{t('PricesDoNotIncludeGST')}</div>*/}
                                        </div>
                                        <div className={`w-full flex ${showAddons && data?.addons?.length ? 'justify-between' : 'justify-end'}`}>
                                            {
                                                showAddons && !!data?.addons?.length &&
                                                <button onClick={() => {setShowAddons(false)}} className='hidden justify-center bg-ar-primary text-white text-center py-[13px] px-[19px] w-[215px] rounded-[10px] mt-[25px] md:block'>Back</button>
                                            }
                                            {
                                                !showAddons && !!data?.addons?.length &&
                                                <button onClick={() => {setShowAddons(true)}} className='hidden justify-center bg-ar-primary text-white text-center py-[13px] px-[19px] w-[215px] rounded-[10px] mt-[25px] md:block'>Next</button>
                                            }
                                            {
                                                (showAddons || !data?.addons?.length) &&
                                                <button onClick={onBeforeCheckout} disabled={(isLoading || (itemCart.tickets.length <= 0 && itemCart.addons.length <= 0))} className={`hidden justify-center bg-ar-primary text-white text-center py-[13px] px-[19px] w-[215px] rounded-[10px] mt-[25px] md:block ${(itemCart.tickets.length <= 0 && itemCart.addons.length <= 0) ? 'grayscale cursor-not-allowed' : ''}`}>{t(isLoading ? 'Loading' : 'Checkout')}</button>
                                            }
                                        </div>
                                    </> : <div className=' mt-5'>Currently, there is no ticket price list data.</div>}
                            </div>
                        }

                        <div style={{ zIndex: 29 }}>
                            <div className='md:hidden fixed bg-ar-black left-0 bottom-0 w-full z-50 px-[10px] pt-[8px] pb-[15px] px-' style={{ filter: 'drop-shadow(0px -10px 16px rgba(0, 0, 0, 0.15))' }}>
                                <div onClick={orderSummaryHandler} className='flex justify-between items-center mb-[12px]  px-[12px]'>
                                    <div className='flex items-center justify-start'>
                                        <span className=' text-ar-purple mr-1'>{toggleOrderSummary ? 'Hide summary' : t("View summary")}</span>
                                        <svg className={`${toggleOrderSummary ? 'rotate-0' : 'rotate-180'}`} width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m6 9 6 6 6-6" stroke="#9B8BFF" strokeWidth="2" /></svg>
                                    </div>
                                    <div className='font-bold text-[14px]'>{ticketSummaryData?.totalPrice?.totalDisplay}</div>
                                </div>
                                {
                                    !showAddons && !!data?.addons?.length &&
                                    <button onClick={() => {setShowAddons(true)}} className='w-full self-end bg-ar-primary text-white text-center py-[13px] px-[19px] rounded-[10px]'>Next</button>
                                }
                                {
                                    (showAddons || !data?.addons?.length) &&
                                    <button onClick={onBeforeCheckout} disabled={(isLoading || (itemCart.tickets.length <= 0 && itemCart.addons.length <= 0))} className={`w-full self-end bg-ar-primary text-white text-center py-[13px] px-[19px] rounded-[10px] ${(itemCart.tickets.length <= 0 && itemCart.addons.length <= 0) ? 'grayscale cursor-not-allowed' : ''}`}>{t(isLoading ? 'Loading' : 'Checkout')}</button>
                                }
                            </div>
                            <div className={`order-summary ${toggleOrderSummary ? 'h-full bottom-0' : 'h-0 bottom-[-120px]'} ease-in-out duration-300 fixed  md:hidden overflow-auto left-0  z-[40] bg-ar-dark-purple w-full py-[34px] pb-[140px] px-[16px]`}>
                                <div className='text-sm md:text-base'>
                                    <SummaryView />
                                    <BookingFeeInfo />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                showCloseBtn={false}
                backdropClose={true}
                backdrop={true}
                footer={false}
                header={false}>
                {!emailSent && <ResendVerificationEmail resendVerificationHandle={resendVerificationHandle} userEmail={user?.email_address} />}
                {emailSent && <div className="w-full lg:w-[708px] px-12 py-16 text-center h-full lg:h-auto">
                    <SentVerification resendVerificationHandle={resendVerificationHandle} customerEmail={user?.email_address} />
                </div>
                }
            </Modal >
        </>
    )
}

export default EventPurchaseTicket;