import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

type AuthState = {
    user: any | null,
    accessToken: string | null;
    privateEvent: any | null;
    csrf?: string | null;
};

const initialState: AuthState = {
    user: null,
    accessToken: null,
    privateEvent: null,
    csrf: null,
}

const authSlice = createSlice({
    name: 'userAuth',
    initialState,
    reducers: {
        setAccessToken: (state, {payload: {accessToken}}: PayloadAction<{ accessToken: string }>)=>{
            state.accessToken = accessToken;
            if (accessToken) axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
        },
        setCSRF: (state, {payload: {csrf}}: PayloadAction<{ csrf: string | null }>)=>{
            state.csrf = csrf;
            if (csrf) axios.defaults.headers.common['x-csrf-token'] = csrf;
        },
        logout: (state) => {
            state.accessToken = null;
            state.user = null;
        },
        setProfileInfo: (state, { payload: user }: PayloadAction<{ user: any }>) => {
            state.user = user;
        },
        setUpdateEmail: (state, { payload: email_address} : PayloadAction<string>) => {
            if (typeof state.user === 'object') {
                state.user.email_address = email_address
            }
        },
        setPrivateEvent: (state, {payload}) => {
            state.privateEvent = state.privateEvent ? [...state.privateEvent, ...payload] : payload;
        }
    }
});

export const { setAccessToken, setCSRF, logout, setProfileInfo, setUpdateEmail, setPrivateEvent} = authSlice.actions;

export default authSlice.reducer;