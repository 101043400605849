import { get, post, put, remove } from '../AxiosRequest'

const getProfile = async ()=> {
    return await get('/customer/Profile')
}

const setProfile = async (params:any) => {
    return post('/customer/set-profile', params)
}

const updateMobile = async (params:any) => {
    return post('/customer/profile/change-mobile', params)
}

const changeEmail = async (params:any) => {
    return post('customer/profile/change-email', params)
}

const verifyNumberOtp = async (params:any) => {
    return post('customer/profile/verify-change-mobile-otp', params)
}

const subscriptionUpdate = async (params:any) => {
    return post('customer/subscription', params)
}

const uploadProfileImage = async (profileImage:File) => {
    const formData = new FormData();
    formData.append('profileImage', profileImage)

    return await post(`customer/upload-profile-image`, formData, {
        'Content-Type': 'multipart/form-data'
    });

    // return post('customer/upload-profile-image', params)
}


export {
    getProfile,
    setProfile,
    updateMobile,
    changeEmail,
    verifyNumberOtp,
    subscriptionUpdate,
    uploadProfileImage
}