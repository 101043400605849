import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode } from "react";
import ClosePurple from "../../assets/images/icons/ClosePurple";

interface Props {
    isOpen: boolean;
    closeModal: () => void;
    title?: string | ReactNode;
    content?: string;
    children: ReactNode;
}
export default function Modal({ isOpen, closeModal, title, children }: Props) {
    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/80 backdrop-blur-sm" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md px-[34px] py-[30px] overflow-hidden text-left align-middle transition-all transform bg-[#211F2EED] text-white rounded-[15px] shadow-[0px_9px_10px_3px_rgba(0,0,0,0.50)]">
                                    <Dialog.Title
                                        as="h3"
                                        className="flex items-center justify-between text-2xl font-medium leading-6"
                                    >
                                        {title}
                                        <div
                                            className="cursor-pointer"
                                            onClick={closeModal}
                                        >
                                            <ClosePurple />
                                        </div>
                                    </Dialog.Title>
                                    <div className="mt-2">{children}</div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
}
