import React, { useState, useEffect } from "react";
import emailIcon from "../../../assets/images/icons/sent-email.png";
import { useTranslation } from "react-i18next";
import { resendEmail } from "../../../utils/apiRequestList/authServices";
import DangerouslySetInnerHTML from "../../../components/DangerouslySetInnerHTML";
import VerifyEmailBanner from "../../../assets/images/verify-email-banner.png";
import Modal from "../../../components/Modal";
import {toast} from "react-toastify";
import ExceptionToast from "../../../components/Handler/ExceptionToast";
import {setLoggedIn} from "../../../redux/slices/commonSlice";
import {useAppDispatch} from "../../../hooks/useStore";

const SentVerification = (props: any) => {
    const [seconds, setSeconds]: any = useState(null);
    const [customerCareModal, setCustomerCareModal] = useState(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const resendEmailAction = async () => {
        try {
            const respond = await resendEmail({
                emailAddress: props?.customerEmail
            });
            toast(<ExceptionToast message={respond?.data?.message}/>)
        } catch (error) {
            console.log(error)
        }
    }

    return <>
        <div>
            <div>
                <img className='w-full' src={VerifyEmailBanner} alt=""/>
            </div>
            <div className='pt-[23px] pb-[95px] px-[34px]'>
                <div className='mb-[12px]'>
                    <h3 className='text-[20px] font-semibold mb-[12px] pt-[20px]'>{t('VerifyActiveYourAccount')}</h3>
                </div>
                <p className='mb-[22px]'>
                    <span>{t('VerificationEmailSent')}</span><span className='text-[#4285F4]'>{props?.customerEmail}</span>
                    <span>{t('ActivatePurchaseTickets')}</span>
                </p>
                <button onClick={()=> {
                    resendEmailAction()
                }}
                        className="bg-ar-primary w-full py-[12px] rounded-[10px] text-white">
                    {t('ResendEmail')}
                </button>
                <p className='text-[10px] mt-[18px]'>
                    <span>{t('CheckSpamFolderEmail')}</span><a href="mailto:admin@witharia.co" className="text-ar-primary cursor-pointer underline">admin@witharia.co</a><span>{t('ForHelp')}</span>
                </p>
            </div>
        </div>
    </>
}

export default SentVerification;
