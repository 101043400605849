import React from "react";
import {useTranslation} from "react-i18next";

const PromoCode = ({onApply, onRemove, isValid, value, setValue, errMessage, isResponseExists} : {
    onApply: () => void,
    onRemove: () => void,
    isValid: boolean,
    isResponseExists: boolean,
    value: string,
    setValue: (value: string) => void,
    errMessage: string | undefined | null} ,

) => {
    const validIcon = <svg width="24" height="25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m10.58 16.754 6.724-6.723-1.054-1.054-5.67 5.67-2.85-2.85-1.053 1.053 3.904 3.904ZM12.003 22a9.255 9.255 0 0 1-3.705-.748 9.598 9.598 0 0 1-3.018-2.03 9.591 9.591 0 0 1-2.03-3.016 9.245 9.245 0 0 1-.749-3.704c0-1.314.25-2.55.748-3.705a9.597 9.597 0 0 1 2.03-3.018 9.592 9.592 0 0 1 3.016-2.03A9.245 9.245 0 0 1 11.998 3c1.314 0 2.55.25 3.705.748a9.597 9.597 0 0 1 3.018 2.03 9.592 9.592 0 0 1 2.03 3.016 9.245 9.245 0 0 1 .749 3.704c0 1.314-.25 2.55-.748 3.705a9.598 9.598 0 0 1-2.03 3.018 9.592 9.592 0 0 1-3.016 2.03 9.245 9.245 0 0 1-3.704.749ZM12 20.5c2.233 0 4.125-.775 5.675-2.325C19.225 16.625 20 14.733 20 12.5c0-2.233-.775-4.125-2.325-5.675C16.125 5.275 14.233 4.5 12 4.5c-2.233 0-4.125.775-5.675 2.325C4.775 8.375 4 10.267 4 12.5c0 2.233.775 4.125 2.325 5.675C7.875 19.725 9.767 20.5 12 20.5Z" fill="#28D7FE" /></svg>
    const { t } = useTranslation();

    const handleApplyPromoCode = () => {
        onApply()
    }

    const removeCode = () => {
        onRemove()
    }

    return <>
        <div className="border-b-[0.5px] border-ar-purple pb-4">
            <label className=" text-base font-normal mb-1">{t('PromoCode')}</label>
            <div className="w-full flex-nowrap flex items-center">
                <div className={`w-full rounded-md flex items-center ${!isResponseExists && !isValid
                    ? 'border border-solid border-gray-300'
                    : isValid
                        ? 'border border-solid border-ar-cyan'
                        : errMessage
                            ? 'border border-solid border-ar-error'
                            : null}`}>
                    {isValid
                        ? <div className="w-full p-2 font-normal bg-transparent flex items-center justify-start">
                            <span className=" mr-1">{value} </span>{ !errMessage && validIcon}</div>
                        : <input className="w-full p-2 text-base font-normal bg-transparent rounded-md" disabled={!!errMessage} value={value} placeholder={t('EnterPromoCode')} onChange={(e) => setValue(e.target.value)} />

                    }
                    {isResponseExists && <button onClick={() => removeCode()} className="pr-5 text-ar-purple text-sm font-bold whitespace-nowrap">{t('Remove')}</button>}
                </div>

                {!isResponseExists && <button className=" bg-ar-primary rounded-md w-[35%] text-sm font-semibold tracking-wide py-3 ml-1 px-6" onClick={() => handleApplyPromoCode()}>{t('Apply')}</button>}
            </div>
            <div className=" mt-1">
                {isValid && <div className=" text-sm font-normal text-ar-cyan">{t('PromoCodeAdded')}</div> }
                {errMessage && <div className=" text-sm text-ar-error font-normal">{errMessage}</div>}
            </div>
        </div>
    </>
}

export default PromoCode;
