import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageContainer from '../../../components/PageContainer';
import { useAppDispatch } from '../../../hooks/useStore';
import { setReceiptID } from '../../../redux/slices/ticketSlice';
import { get } from '../../../utils/AxiosRequest';
import { useTranslation } from "react-i18next";
import DesktopView from './components/desktopView';
import MobileView from './components/mobileView';

const Index = () => {
    const { t } = useTranslation()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [records, setRecords] = useState(10);
    const [purchases, setPurchases] = useState<any>({});

    const fetchData = async () => {
        try {
            const { data } = await get(`/event/list-purchase?page=${page}&records=${records}`);
            if (data?.success && data?.data) {
                setPurchases(data.data)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, [
        page,
        records,
    ]);

    const [currentPage, setCurrentPage] = useState(1);
    const [startIndex, setStartIndex] = useState(0);

    useEffect(() => {
        setStartIndex(records * (purchases?.page - 1))
        setCurrentPage(purchases?.page)
    }, [purchases?.page, purchases?.totalDocs, records]);

    const nextPage = () => {
        if (!purchases.hasNextPage) return
        setPage(current => (current + 1))
    }
    const prevPage = () => {
        if (!purchases.hasPrevPage) return
        setPage(current => (current - 1))
    }

    const onPageChange = (event: PaginatorPageChangeEvent) => {
        if (records !== event.rows) {
            setRecords(event.rows)
            return;
        }

        if (currentPage < Math.ceil(event.pageCount / event.first)) {
            prevPage()
        }
        else {
            if (currentPage === event.pageCount) {
                prevPage()
            } else {
                nextPage()
            }
        }
    };

    const navigateMyOrder = (id: any) => {
        dispatch(setReceiptID(id))
        // navigate(`/receipt`)
        navigate(`/social-profile/tickets/receipt`)
    }

    const navigateMyTicketDetails = (id: any) => {
        navigate(`/social-profile/tickets/details/${id}`)
    }
    return (
        <>
            <div>
                {purchases?.docs && <div className="px-[20px] py-[24px] md:px-[38px] md:py-[35px] aria-table">
                    <h2 className=' text-[26px] font-semibold'>{t('MyTickets')}</h2>
                    <div className='hidden lg:block'>
                        <DesktopView data={purchases?.docs} navigateMyOrder={navigateMyOrder} navigateMyTicketDetails={navigateMyTicketDetails} />
                    </div>
                    <div className='lg:hidden block'>
                        <MobileView data={purchases?.docs} navigateMyOrder={navigateMyOrder} navigateMyTicketDetails={navigateMyTicketDetails} />
                    </div>

                    {purchases?.docs.length > 0 && <div className='w-full flex justify-center items-center pt-1'>
                        <Paginator
                            style={{ background: 'none' }}
                            first={startIndex}
                            rows={records}
                            totalRecords={purchases?.totalDocs}
                            rowsPerPageOptions={[10, 20, 30]}
                            onPageChange={onPageChange}
                            template={{ layout: 'PrevPageLink CurrentPageReport NextPageLink RowsPerPageDropdown' }}
                            currentPageReportTemplate="{currentPage} of {totalPages}"
                        />
                    </div>}
                </div>
                }
            </div>
        </>
    )
}

export default Index
