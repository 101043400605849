import FormWrapper from "../../components/Form/FormWrapper";
import { FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import ErrorMessage from "../../components/Form/errorMessage";
import CoinIcon from "../../assets/images/icons/Coin";
import CountdownTimer from "./CountdownTimer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DangerouslySetInnerHTML from "../../components/DangerouslySetInnerHTML";
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { StripeElements, Stripe } from "@stripe/stripe-js";
import { deferPi } from "../../utils/apiRequestList/paymentService";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/useStore";
import formatDateRange from "../../utils/formatDateRange";
import NoImage from '../../assets/images/no-image.png'
import { getProfile, subscriptionUpdate } from "../../utils/apiRequestList/profileService";
import Switch from "react-switch";
import BookingItems from "../../components/Receipt/BookingItems";
import { policyCustomContent } from "../../utils/apiRequestList/ticketServices";

const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export default function CheckoutForm(props: any) {
    const { ticketCheckout }: any = useAppSelector((state) => state.ticket);
    const stripe: Stripe | null = useStripe();
    const elements: StripeElements | null = useElements();

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false)
    const [showPolicy, setShowPolicy] = useState(false)
    const [cardError, setCardError] = useState<any>(null)
    const [customerSubscription, setCustomerSubscription] = useState(
        { ariaOrganisation: true }
    );
    const [policyContent, setPolicyContent] = useState<any>(null)

    const schema = Yup.object({
        fullName: Yup.string()
            .min(2, "Too Short!")
            .max(50, "Too Long!")
            .required(t('MandatoryField')),
        email: Yup.string()
            .typeError("type error1")
            .matches(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/, "Invalid email")
            .required(t('MandatoryField')),
        countryCode: Yup.string(),
        phoneNumber: Yup.string()
            .matches(phoneRegExp, "Phone number is not valid")
            .required(t('MandatoryField')),
        cardNumber: Yup.string(),
        icLastFour: Yup.string().when('collectIc', {
            is: true,
            then: (schema) => schema.matches(/^[a-zA-Z0-9]+$/, "Must be alphanumeric").max(4, "Too Long!").required(t('MandatoryField')),
        })
    });


    const formInputs = ({
        errors,
        touched,
        values,
        handleBlur,
        handleChange,
        getFieldProps,
        setFieldValue,
    }: FormikProps<FormikValues>) => (
        <>
            <p className="mb-[10px]">{t("ContactDetails")}</p>
            <div className="mb-[10px]">
                <input
                    type="text"
                    {...getFieldProps("values.fullName")}
                    className="input text-ar-black bg-[#CACACA] !text-[16px]"
                    disabled={true}
                    placeholder={t("FullName")}
                    value={values.fullName}
                />
                {errors.fullName && touched.fullName ? (
                    <ErrorMessage text={errors.fullName} />
                ) : null}
            </div>
            <div className="mb-[10px]">
                <input
                    type="email"
                    {...getFieldProps("values.email")}
                    className="input text-ar-black bg-[#CACACA] !text-[16px]"
                    disabled={true}
                    placeholder={t("EmailAddress")}
                    value={values.email}
                />
                {errors.email && touched.email ? (
                    <ErrorMessage text={errors.email} />
                ) : null}
            </div>

            {ticketCheckout?.event?.collect_ic &&
                <div className="mb-8 mb-[10px]">
                    <label>The last 4 characters of your passport number</label>
                    <div className='text-[12px] leading-[14px] mb-[5px]'>Kindly provide accurate details. Passport verification is mandatory upon entry for identity confirmation. </div>
                    <input
                        id="icLastFour"
                        name="icLastFour"
                        maxLength={4}
                        type="text"
                        className={`input text-ar-black !text-[16px] ${(errors.icLastFour && touched.icLastFour) && "p-invalid"}`}
                        placeholder='Last 4 characters'
                        value={values.icLastFour}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.icLastFour && touched.icLastFour ? (
                        <ErrorMessage text={errors.icLastFour} />
                    ) : null}
                </div>
            }

            <p>{t("PaymentMethod")}</p>
            <p className="text-[#919191] text-[12px]">{t("TransactionsEncryted")}</p>
            <div className="px-[20px] py-[24px] mt-1 border border-ar-purple rounded-[6px]">
                <PaymentElement />
            </div>

            <div className="flex items-center justify-between h-12 mt-4 border-b border-ar-purple">
                <div>{t("Seats")}</div>
                <div className="font-bold">NA</div>
            </div>

            <div className="flex items-center justify-between h-12 border-b border-ar-purple">
                <div>{t("Delivery")}</div>
                <div className="font-bold">{t("E-ticketDelivery")}</div>
            </div>
            <div className="pt-[12px]">
                <BookingItems data={props?.ticketCheckout?.summary} />
            </div>

            <div className="mt-[25px]">
                <div className="flex justify-between items-center">
                    <div className='text-sm'>{t('EmailUpdatesFromAriaOrganisers')}</div>
                    <div>
                        <Switch
                            onChange={(bool: boolean) => {
                                setCustomerSubscription((prevState: any) => ({
                                    ...prevState,
                                    ariaOrganisation: bool,
                                }))
                            }}
                            checked={customerSubscription.ariaOrganisation}
                            className="react-switch"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            height={22.4}
                            width={38.4}
                            onColor='#6366f1'
                            offColor='#d1d5db'
                            handleDiameter={15}
                        />
                    </div>
                </div>
            </div>

            <p className="mt-3 text-xs">
                {t('AuthAcceptOur')}
                <span className="text-ar-purple cursor-pointer"
                    onClick={() => navigate('/term-and-condition')}>{t('AuthTNC')}</span>
                {t('AuthAnd')}
                <span className="text-ar-purple cursor-pointer"
                    onClick={() => navigate('/privacy-policy')}>{t('AuthPolicy')}</span>
            </p>

            {/*<div className="flex items-baseline gap-2 mt-4">*/}
            {/*    <input*/}
            {/*        type="checkbox"*/}
            {/*        name="consent"*/}
            {/*        id="consent"*/}
            {/*        className="translate-y-1"*/}
            {/*    />*/}
            {/*    <p className="text-xs">{t("YesIWouldLikeToReceive")}</p>*/}
            {/*</div>*/}
        </>
    );

    const targetDate = new Date(ticketCheckout.session_expired);

    const processPayment = async (values: any) => {
        if (!stripe) {
            // console.log('missing Stripe')
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        if (!elements) {
            // console.log('missing elements')
            return;
        }

        // Trigger form validation and wallet collection
        const submitRes = await elements?.submit();
        if (submitRes?.error) {
            // console.log(submitRes?.error)
            return;
        }

        // Create the PaymentMethod using the details collected by the Payment Element
        //@ts-ignore
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            elements,
            params: {
                billing_details: {
                    "email": ticketCheckout?.email,
                    "name": ticketCheckout?.fullName,
                    "phone": ticketCheckout?.callCode + ticketCheckout?.contactNo
                },
            }
        });

        // if (error) console.log(error)

        // console.log('method id', paymentMethod)

        const deferPiPayload: any = {
            payment_method_id: paymentMethod?.id,
            amount: ticketCheckout.total,
            currency: ticketCheckout.currency,
            transaction_id: ticketCheckout.transaction_id,
        }

        if (ticketCheckout?.event?.collect_ic) {
            deferPiPayload.ic_last_four = values.icLastFour
        }

        const response: any = await deferPi(deferPiPayload)

        const pm = response?.data?.data?.payment_method;
        const payment_method = pm ? pm?.type ? String(pm?.type === 'card' ? pm?.card?.brand : pm?.type).toUpperCase() : null : null;

        // if (response?.success === false) {
        //     setLoading(false)
        //     props.failedStatus(true)
        //     setCardError('something went wrong')
        // }
        // console.log(response?.data)

        const confirmPay = await stripe.confirmPayment({
            clientSecret: response?.data?.data?.client_secret,
            confirmParams: {
                return_url: process.env.REACT_APP_DOMAIN + encodeURI(`/events/${ticketCheckout?.event?.slug}/${ticketCheckout.transaction_id}/checkout-result?event=${props?.ticketCheckout?.event?.title}&pm=${payment_method}`)
            },
            // redirect: 'if_required'
        });

        // console.log('check response', response, confirmPay)

        if (confirmPay && confirmPay?.error) {
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer.
            // setLoading(false);
            props.failedStatus(true)
            let errorMessage: any = confirmPay?.error?.code
            setCardError(t(errorMessage))
        } else {
            setCardError(null)
            if (props?.setSubmitting) {
                props?.setSubmitting(false)
            }
            // navigate("/purchased-ticket")
        }
    }

    const getUserSubData = async () => {
        try {
            const response = await getProfile();
            setCustomerSubscription({
                ariaOrganisation: response.data?.data?.email_updates_aria_organisation
            })
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const getPolicyContent = async () => {
        const response = await policyCustomContent(props?.ticketCheckout?.summary?.eSlug);
        setPolicyContent(response?.data?.data)
    }

    useEffect(() => {
        getUserSubData();
        getPolicyContent();
    }, []);

    const handleSubscriptions = async (values: any) => {
        if (props?.setSubmitting) {
            props?.setSubmitting(true)
        }

        try {
            await subscriptionUpdate({
                emailSubscriptionAriaOrganisation: customerSubscription.ariaOrganisation
            })
            await processPayment(values)
        }
        catch (e) {
            // console.log(e)
        }
    }

    const handleSubmit = async (values: any) => {
        setLoading(true)
        await handleSubscriptions(values)
        // await processPayment()
        setLoading(false)
    }



    return (
        <div className="pb-[50px] lg:pb-[74px]">
            <div className="max-w-[600px] lg:max-w-[972px] !h-auto mx-auto pt-6">
                <div className="md:hidden block px-5 mb-5 lg:px-0">
                    <div className="flex items-center justify-between">
                        <h3 className="text-white text-[26px] font-semibold">
                            {t("Checkout")}
                        </h3>
                        <div>
                            <CountdownTimer targetDate={targetDate} expiredStatus={props.expiredStatus} />
                        </div>
                    </div>
                    <h4 className="text=[16px] font-normal text-[#CACACA]">{t('ClosingThisPage')}</h4>
                </div>

                <div className="gap-8 lg:flex">
                    <div className="basis-[450px] grow-0 shrink-0 px-5 lg:px-0">
                        <div className="md:block hidden mb-[20px]">
                            <h3 className="text-white text-[26px] font-semibold">
                                {t("Checkout")}
                            </h3>
                            <h4 className="text=[16px] font-normal text-[#CACACA]">{t('ClosingThisPage')}</h4>
                        </div>
                        <div className="bg-ar-dark-purple px-5 py-3 lg:px-[20px] lg:py-[20px] rounded-[6px] lg:rounded-[15px] mb-8 shadow-[0px_5px_5px_0px_rgba(0,0,0,0.10),0px_1px_1px_0px_rgba(0,0,0,0.05)]">
                            <h4 className="mb-5 font-semibold text-[20px]">
                                {props?.ticketCheckout?.event?.title}
                            </h4>
                            <div className="mb-4">
                                <img className={`hidden object-cover w-full h-full md:block ${props?.ticketCheckout?.event?.cover_image_desktop ? '' : 'border border-ar-purple'}`} src={props?.ticketCheckout?.event?.cover_image_desktop || NoImage} alt='' />
                                <img className={`block object-cover w-full h-full md:hidden ${props?.ticketCheckout?.event?.cover_image_desktop ? '' : 'border border-ar-purple'}`} src={props?.ticketCheckout?.event?.cover_image_mobile || NoImage} alt='' />
                            </div>
                            <div className="border-b-[0.5px] border-[#919191] pb-4 mb-4">
                                <div className="flex items-baseline mb-1">
                                    <div className="w-[10px]">
                                        <svg className="w-[10px]" width="10" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.583 6.75a.538.538 0 0 1-.396-.16.538.538 0 0 1-.16-.396c0-.157.054-.289.16-.395a.538.538 0 0 1 .396-.16c.158 0 .29.053.396.16.107.106.16.238.16.395 0 .158-.053.29-.16.396a.538.538 0 0 1-.396.16Zm2.459 0a.538.538 0 0 1-.396-.16.538.538 0 0 1-.16-.396c0-.157.053-.289.16-.395a.538.538 0 0 1 .396-.16c.157 0 .289.053.396.16.106.106.16.238.16.395 0 .158-.054.29-.16.396a.538.538 0 0 1-.396.16Zm2.36 0a.538.538 0 0 1-.395-.16.538.538 0 0 1-.16-.396c0-.157.053-.289.16-.395a.538.538 0 0 1 .396-.16c.157 0 .29.053.396.16.106.106.16.238.16.395 0 .158-.054.29-.16.396a.538.538 0 0 1-.396.16ZM.834 11.111a.8.8 0 0 1-.583-.25.8.8 0 0 1-.25-.583V1.667a.8.8 0 0 1 .25-.584.8.8 0 0 1 .583-.25h.903V0h.903v.833H7.36V0h.903v.833h.903a.8.8 0 0 1 .583.25.8.8 0 0 1 .25.584v8.61a.8.8 0 0 1-.25.584.8.8 0 0 1-.583.25H.833Zm0-.833h8.334V4.306H.833v5.972Zm0-6.806h8.334V1.667H.833v1.805Z" fill="white" /></svg>
                                    </div>
                                    <div className="ml-2">
                                        {(props?.ticketCheckout?.event?.start_date && props?.ticketCheckout?.event?.end_date) ? formatDateRange(new Date(props?.ticketCheckout?.event?.start_date), new Date(props?.ticketCheckout?.event?.end_date)) : ''}
                                    </div>
                                </div>
                                <div className="flex items-center md:block">
                                    <div className="flex items-baseline">
                                        <div className="w-[10px]">
                                            <svg width="10" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)"><path d="M8.536 1.5c-1.953-2-5.119-2-7.071 0-1.953 2-1.953 5.242 0 7.242l2.929 3a.843.843 0 0 0 1.212 0l2.93-3c1.953-2 1.953-5.242 0-7.242ZM3.353 6.809a2.427 2.427 0 0 1 0-3.375 2.29 2.29 0 0 1 3.294 0c.91.932.91 2.443 0 3.375a2.29 2.29 0 0 1-3.295 0Z" fill="#fff" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h10v12H0z" /></clipPath></defs></svg>
                                        </div>

                                        <div className="ml-2 text-white">
                                            <span>{props?.ticketCheckout?.event?.event_venue_id?.name}</span> <br /> {props?.ticketCheckout?.event?.event_venue_id?.address} {props?.ticketCheckout?.event?.event_venue_id?.city} {props?.ticketCheckout?.event?.event_venue_id?.postalCode}</div>
                                    </div>
                                </div>
                            </div>
                            <ul className='text-white font-bold'>
                                {props?.ticketCheckout?.summary?.ticketList && props?.ticketCheckout?.summary?.ticketList?.map((item: any, index: number) => {
                                    return <li key={index} className='last:mb-0 mb-4'>
                                        <div>{item?.ticket_amount} x {item?.name}</div>
                                        <DangerouslySetInnerHTML
                                            classNames={'break-words editor-formatting'}
                                            htmlContent={item?.description}
                                        />
                                    </li>
                                })}
                                {props?.ticketCheckout?.summary?.addonList && props?.ticketCheckout?.summary?.addonList.map((item: any, index: number) => {
                                    return <li key={index} className='last:mb-0 mb-4'>
                                        <div> <span className='text-ar-cyan text-[16px] font-normal'>{t('AddOn')} </span> {item?.addOns_amount} x {item?.name}</div>
                                        <DangerouslySetInnerHTML
                                            classNames={'break-words editor-formatting'}
                                            htmlContent={item?.description}
                                        />
                                    </li>
                                })}
                            </ul>
                        </div>

                        {(policyContent?.purchase_policy || policyContent?.event_policy) && <div className="bg-ar-dark-purple px-5 py-3 lg:px-[36px] lg:py-[31px] rounded-[6px] lg:rounded-[15px] mb-8 text-[14px]">
                            <div className={showPolicy ? "" : "h-[70px] lg:h-auto overflow-hidden lg:overflow-auto"}>
                                {policyContent?.purchase_policy && <>
                                    <h4 className="mb-1 font-bold">
                                        {t("PurchasePolicy")}
                                    </h4>
                                    <DangerouslySetInnerHTML
                                        classNames={'break-words editor-formatting overflow-auto max-h-[300px] mb-8'}
                                        htmlContent={policyContent?.purchase_policy}
                                    />
                                    {/* <ul className="pl-4 mb-8 list-disc">
                                    <li>{t("TicketPolicyPoint1")}</li>
                                    <li>{t("TicketPolicyPoint2")}</li>
                                    <li>{t("TicketPolicyPoint3")}</li>
                                    <li>{t("TicketPolicyPoint4")}</li>
                                    <li>{t("TicketPolicyPoint5")}</li>
                                </ul> */}
                                </>}
                                {policyContent?.event_policy && <>
                                    <h4 className="mb-1 font-bold">{t("EventPolicy")}</h4>
                                    <DangerouslySetInnerHTML
                                        classNames={'break-words editor-formatting overflow-auto max-h-[300px]'}
                                        htmlContent={policyContent?.event_policy}
                                    />
                                    {/* <ul className="pl-4 list-disc">
                                    <li>{t("EventPolicyPoint1")}</li>
                                    <li>{t("EventPolicyPoint2")}</li>
                                    <li>{t("EventPolicyPoint3")}</li>
                                    <li>{t("EventPolicyPoint4")}</li>
                                    <li>{t("EventPolicyPoint5")}</li>
                                    <li>{t("EventPolicyPoint6")}</li>
                                </ul> */}
                                </>}
                            </div>
                            <div className="lg:hidden pb-2 pt-4 text-center text-ar-purple font-bold cursor-pointer" onClick={() => setShowPolicy(prev => !prev)}>{showPolicy ? 'show less' : 'show more'}</div>
                        </div>}
                    </div>
                    <div>
                        <div className="md:flex hidden justify-end mb-[18px]">
                            <CountdownTimer targetDate={targetDate} expiredStatus={props.expiredStatus} />
                        </div>
                        <div className="lg:border border-ar-purple lg:px-[36px] lg:py-[31px] lg:rounded-[15px] flex-1 px-5">
                            <FormWrapper
                                initialValues={{
                                    fullName: ticketCheckout.fullName,
                                    email: ticketCheckout.email,
                                    cardNumber: "",
                                    phoneNumber: ticketCheckout.contactNo,
                                    callCode: ticketCheckout.callCode,
                                    promoCode: "",
                                    icLastFour: "",
                                    collectIc: ticketCheckout?.event?.collect_ic,
                                }}
                                validationSchema={schema}
                                formInputs={formInputs}
                                onSubmit={handleSubmit}
                                toggleHoneyPot={true}
                                submitButton={
                                    <>
                                        <button
                                            type="submit"
                                            className="flex gap-[6px] items-center justify-center w-full mt-9 bg-ar-primary h-[60px] rounded-[10px] text-white font-bold"
                                            // onClick={handleSubmit}
                                            disabled={!stripe || loading}
                                            style={{
                                                filter: (!stripe || loading) ? 'grayscale(1)' : ''
                                            }}
                                        >
                                            <CoinIcon />
                                            {loading ? 'Loading...' : t("PayNow")}
                                        </button>
                                        {cardError && <div className=" text-ar-error text-sm mt-3">{cardError}</div>}
                                    </>
                                }
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
