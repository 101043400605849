import { get } from "../AxiosRequest";

const tncData = async () => {
  return await get(`/tnc`);
};

const privacyPolicyData = async () => {
  return await get(`/privacy-policy`)
}


export { tncData, privacyPolicyData };
