import React from 'react';
import { Disclosure, Transition } from "@headlessui/react";
import QuantityController from "../../components/QuantityController";
import { useTranslation } from 'react-i18next';
import formatDateStartEnd from '../../utils/formatDateStartEnd';
import ticketLabelTag from '../../utils/ticketLabelTag';
import DangerouslySetInnerHTML from '../../components/DangerouslySetInnerHTML';


interface AccordionProps {
    currentValue?: number | undefined | null,
    data: any;
    promotionObj?: any;
    bgColor?: string;
    addOns?: any;
    isOpen?: boolean; // New prop to indicate if the accordion is open
    toggleAccordion?: () => void; // Function to toggle the accordion
    onPlus?: () => void;
    onMinus?: () => void;
    disabledMinus: boolean;
    disabledPlus: boolean;
}

const Accordion = (props: AccordionProps) => {
    const { t } = useTranslation();
    const currentDate = new Date();
    return (
        <div >
            <Disclosure defaultOpen={props?.isOpen}>
                {({ open }) => (
                    <div className={`${open ? 'bg-ar-dark-purple' : ''} border-b-[0.5px] border-ar-purple pt-[16px] ${open ? 'pb-[16px]' : 'pb-[16px]'}`} style={{ backgroundColor: `${props?.bgColor}` }}>
                        <Disclosure.Button className="flex w-full justify-between  text-left" onClick={props.toggleAccordion}>
                            <div className={`px-[7.5px] text-sm md:text-base`}>
                                {!props?.addOns ?
                                    <div>
                                        <div>{props?.data?.tickets[0]?.name}</div>
                                        {
                                            typeof props?.data?.tickets[0]?.final_price === 'number' ?
                                                (
                                                    <div>
                                                        <span className='text-[#919191] line-through font-bold'>{props?.data?.tickets[0]?.display_price}
                                                            {/*{`(${t('PerPax')})`}*/}
                                                        </span>
                                                        &nbsp;
                                                        <span className='md:hidden'><br /></span>
                                                        <span className={`text-white font-bold`}>{props?.data?.tickets[0]?.display_final_price}
                                                            {/*{`(${t('PerPax')})`}*/}
                                                        </span>
                                                        <span className='md:hidden'><br /></span>
                                                        {
                                                            props?.promotionObj?.display_discount_amount ?
                                                                <span className='text-[#919191]'>&nbsp; -{props?.promotionObj?.display_discount_amount}</span>
                                                                :
                                                                <span className='text-[#919191]'>&nbsp; -{props?.promotionObj?.display_discount_percentage}</span>
                                                        }
                                                    </div>
                                                )
                                                :
                                                <div className={`${open ? 'text-white' : 'text-ar-purple'} font-bold`}>{props?.data?.tickets[0]?.display_price}
                                                    {/*{`(${t('PerPax')})`}*/}
                                                </div>
                                        }
                                        <div className='text-[12px] md:text-[14px]'>
                                            {formatDateStartEnd(props?.data?.tickets[0]?.sales_start_date, props?.data?.tickets[0]?.sales_end_date)}
                                        </div>
                                    </div>
                                    :
                                    <>
                                        <div>{props?.data.name}</div>
                                        <div className={`${open ? 'text-white' : 'text-ar-purple'} font-bold`}>{props?.data?.display_price}</div>
                                    </>
                                }
                            </div>
                            <div>
                                <div className='flex mb-2'>
                                    {!props?.addOns
                                        ? <>
                                            <div className='mr-1'>{ticketLabelTag(props?.data?.tickets[0], props?.currentValue ?? 0)}</div>
                                            <div className={`flex items-center justify-end`}>
                                                {/*{props?.currentValue && <div className='text-base font-bold'>{props?.currentValue}</div>}*/}
                                                <span className={`${open ? 'rotate-270 transform' : 'rotate-180'} self-center max-w-[26px]`}>
                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.499 15L12.499 9L6.49902 15" stroke={open ? 'white' : '#CCC3FF'} strokeWidth="2" />
                                                </svg>
                                            </span>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className='mr-1'>{ticketLabelTag(props?.data, props?.currentValue ?? 0)}</div>
                                            <div className={`flex items-center justify-end`}>
                                                {/*{props?.currentValue && <div className='text-base font-bold'>{props?.currentValue}</div>}*/}
                                                <span className={`${open ? 'rotate-270 transform' : 'rotate-180'} self-center max-w-[26px]`}>
                                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.499 15L12.499 9L6.49902 15" stroke={open ? 'white' : '#CCC3FF'} strokeWidth="2" />
                                                </svg>
                                            </span>
                                            </div>
                                        </>
                                    }

                                </div>
                                <div className='mr-2'>
                                    {!props?.addOns
                                        ?
                                        (
                                            (currentDate >= new Date(props?.data?.tickets[0]?.sales_start_timestamp)) &&
                                            <QuantityController
                                                value={props?.currentValue}
                                                disabledMinus={props?.disabledMinus}
                                                disabledPlus={props?.disabledPlus}
                                                onPlus={props?.onPlus}
                                                onMinus={props?.onMinus}
                                            />
                                        )
                                        :
                                        (
                                            (currentDate >= new Date(props?.data?.sales_start_timestamp)) &&
                                            <QuantityController
                                                value={props?.currentValue}
                                                disabledMinus={props?.disabledMinus}
                                                disabledPlus={props?.disabledPlus}
                                                onPlus={props?.onPlus}
                                                onMinus={props?.onMinus}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </Disclosure.Button>
                        <Transition
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                        >
                            <Disclosure.Panel className={`px-[7.5px] first:${props?.bgColor ? `bg-${props?.bgColor}` : 'bg-[#F9F8FF]'} `}>
                                {!props?.addOns
                                    ?
                                    <div className='border-t-[0.5px] border-ar-purple pt-[10px] pb-[24px] mt-4'>
                                        <DangerouslySetInnerHTML
                                            classNames={'break-words editor-formatting'}
                                            htmlContent={props?.data?.tickets[0]?.description}
                                        />
                                    </div>
                                    :
                                    <div className='border-t-[0.5px] border-ar-purple pt-[10px] pb-[24px] mt-4'>
                                        <DangerouslySetInnerHTML
                                            classNames={'break-words editor-formatting'}
                                            htmlContent={props?.data?.description}
                                        />
                                    </div>
                                }
                            </Disclosure.Panel>
                        </Transition>
                    </div>
                )}
            </Disclosure>
        </div>
    )
}

export default Accordion;