import React, {useEffect, useState} from "react";
import FormWrapper from "./FormWrapper";
import ErrorMessage from "./errorMessage";
import { FormikProps, FormikValues } from "formik";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import {countries, createAccount} from "../../utils/apiRequestList/authServices";
import VerifyEmailBanner from '../../assets/images/verify-email-banner.png'
import {useAppDispatch, useAppSelector} from "../../hooks/useStore";
import {setAccessToken, setProfileInfo} from "../../redux/slices/authSlice";
import { InputMask } from '@react-input/mask';
import {useTranslation} from "react-i18next";
import Switch from "react-switch";
import {setLoading} from "../../redux/slices/commonSlice";
import i18n from "../../languages/i18n";
import Select from 'react-dropdown-select';
// manually import/add languages here if there is more in the future
import zhCN from "date-fns/locale/zh-CN";
import ja from "date-fns/locale/ja";
import EmailVerification from "../../screens/EmailVerificationPage/components/EmailVerification";

const RegisterForm = (props:any) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch()
    const [verifyEmailNotice, setVerifyEmailNotice] = useState(false);
    const [countriesOptions, setCountriesOptions] = useState<any>()
    const [countryName, setCountryName] = useState()
    const [errorMsg, setErrorMsg] = useState('');
    const { user }: any = useAppSelector((state) => state.userAuth);

    const initialValues = {
        firstName: '',
        lastName: '',
        emailAddress: '',
        dateOfBirth: '',
        countryId: '',
        emailSubscriptionAriaOrganisation: true,
    };

    const schema = Yup.object({
        firstName: Yup.string().required(t('MandatoryField')),
        lastName: Yup.string().required(t('MandatoryField')),
        emailAddress: Yup.string().email(t('InvalidEmailFormat')).required(t('MandatoryField')),
        dateOfBirth: Yup.string().required(t('MandatoryField')),
        countryId: Yup.string().required(t('MandatoryField')),
        emailSubscriptionAriaOrganisation: Yup.boolean(),
    });


    const getCountries = async () => {
        try {
            const response:any = await countries();
            if (response?.data?.success){
                setCountriesOptions(response?.data?.data)
            }
        }catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getCountries();
    }, []);

    const handleSubmit = async (values: any) => {
        dispatch(setLoading(true));
        try {
            const response:any = await createAccount({
                ...values,
                token: props?.registerToken,
            })
            if (response?.data?.success) {
                setVerifyEmailNotice(true);
                props?.hideCloseBtn(false);
                props?.hideModalPadding(true)
                const userProfile = {
                    ...response?.data?.data,
                    country_name: countryName
                };
                dispatch(setProfileInfo(userProfile))
                dispatch(setAccessToken({accessToken: response?.data?.data?.accessToken}));
                // window.location.reload();
            }
            dispatch(setLoading(false));
        }
        catch (error) {
            console.log(error)
            // @ts-ignore
            setErrorMsg(error?.response?.data?.message)
            dispatch(setLoading(false));
        }
    }

    const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => (
        (
            <div className='w-full pt-[41px] md:pt-[15px]'>
                <h2 className='text-[26px] mb-[15px]'>{t('CreateAccount')}</h2>
                <div className="mb-[20px]">
                    <label htmlFor="firstName">{t('FirstName')}</label>
                    <div className="text-[12px] mt-[-5px] mb-[5px]">Please provide the name on your photo ID for event entry verification.</div>
                    <input
                        id="firstName"
                        name="firstName"
                        type="text"
                        className={`input mb-[5px] ${(errors.firstName && touched.firstName) && "p-invalid"}`}
                        placeholder={t('FirstName')}
                        value={values.firstName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.firstName && touched.firstName ? (
                        <ErrorMessage text={errors.firstName} className="mt-[4px]" />
                    ) : null}
                </div>
                <div className="mb-[20px]">
                    <label htmlFor="lastName">{t('LastName')}</label>
                    <div className="text-[12px] mt-[-5px] mb-[5px]">Please provide the name on your photo ID for event
                        entry verification.
                    </div>
                    <input
                        id="lastName"
                        name="lastName"
                        type="text"
                        className={`input mb-[5px] ${(errors.lastName && touched.lastName) && "p-invalid"}`}
                        placeholder={t('LastName')}
                        value={values.lastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.lastName && touched.lastName ? (
                        <ErrorMessage text={errors.lastName} className="mt-[4px]"/>
                    ) : null}
                </div>
                <div className="mb-[20px]">
                    <label htmlFor="emailAddress">{t('Email')}</label>
                    <input
                        id="emailAddress"
                        name="emailAddress"
                        type="email"
                        className={`input mb-[5px] ${(errors.emailAddress && touched.emailAddress) && "p-invalid"}`}
                        placeholder={t('Email')}
                        value={values.emailAddress}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    {errors.emailAddress && touched.emailAddress ? (
                        <ErrorMessage text={errors.emailAddress} className="mt-[4px]" />
                    ) : null}
                    <ErrorMessage text={errorMsg} className="mt-[4px]" />
                    <p className='text-[14px] text-[#A5A5A5] mt-[8px] leading-[15px]'>{t('AVerifiedEmailEssential')}</p>
                </div>
                <div className='custom-datepicker dob mb-[20px]'>
                    <label htmlFor="emailAddress">{t('DateOfBirth')}</label>s
                    <DatePicker
                        value={values.dateOfBirth}
                        selected={values.dateOfBirth}
                        onChange={val => {
                            setFieldValue('dateOfBirth', val);
                        }}
                        className={`block ${(errors.dateOfBirth && touched.dateOfBirth) && "p-invalid"} mb-[5px] w-full text-[14px] rounded-md border-0 py-1.5 px-[9px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600`}
                        placeholderText={t('DdMmYyyy')}
                        dateFormat="dd/MM/yyyy"
                        customInput={ <InputMask mask="__/__/____" replacement={{ _: /\d/ }} />}
                        showYearDropdown
                        dropdownMode="select"
                        // manually add languages here if there is more in the future
                        locale={i18n.language.includes("zh") ? zhCN : i18n.language.includes("ja") ? ja : ''}
                    />
                    {errors.dateOfBirth && touched.dateOfBirth ? (
                        <ErrorMessage text={errors.dateOfBirth} />
                    ) : null}
                </div>
                <div className='mb-[20px]'>
                    <label htmlFor="countryId">Country</label>
                    <Select
                        options={countriesOptions}
                        values={[]}
                        labelField="name"
                        valueField="_id"
                        onChange={(values:any) => {
                            setFieldValue('countryId', values[0]?._id)
                            const selectedCountryName = countriesOptions.find((country:any) => country._id === values[0]?._id)?.name;
                            setCountryName(selectedCountryName);
                        }}
                        className={`aria-react-dropdown ${(errors.countryId && touched.countryId) && "!border-[1.5px] !border-[#FF6A6A]"}`}
                    />
                    {errors.countryId && touched.countryId ? (
                        <ErrorMessage text={errors.countryId} />
                    ) : null}
                </div>
                <div className='mb-[20px]'>
                <p className='text-[20px]'>{t('FindYourExp')}</p>
                    <p className='text-sm text-[#A5A5A5]'>{t('StayInTouchHappening')}</p>
                </div>
                <div className="flex justify-between mb-[20px]">
                    <div className="text-sm pt-[4px]">{t('EmailUpdatesFromAriaOrganisers')}</div>
                    <div>
                        <Switch
                            onChange={(e) => setFieldValue('emailSubscriptionAriaOrganisation', !values.emailSubscriptionAriaOrganisation)}
                            checked={values.emailSubscriptionAriaOrganisation}
                            className="react-switch"
                            checkedIcon={false}
                            uncheckedIcon={false}
                            height={22.4}
                            width={38.4}
                            onColor='#6366f1'
                            offColor='#d1d5db'
                            handleDiameter={15}
                        />
                    </div>
                </div>
            </div>
        )
    )


    return (
        <div>
            {!verifyEmailNotice ?
            <FormWrapper
                initialValues={initialValues}
                validationSchema={schema}
                formInputs={formInputs}
                onSubmit={handleSubmit}
                toggleHoneyPot={true}
                submitButton={
                    <div className='flex justify-center mb-[45px]'>
                        <button className="bg-ar-primary w-full py-[12px] rounded-[10px] text-white"  type="submit">
                            {t('CreateAccount')}
                        </button>
                    </div>
                }
            />
            :
                <div className='overflow-hidden'>
                    <EmailVerification closeModal={props?.closeModal} customerEmail={user.email_address}/>
                </div>
            }
        </div>
    )
}

export default RegisterForm;