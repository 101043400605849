import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import language from './index.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // Configure i18next options as needed
        detection: {
            // Order of fallback language detection
            order: ["navigator"],
        },
        fallbackLng: process.env.REACT_APP_SHOW_MUSIC_PLAYER,
        lng: window.localStorage.getItem('currentLanguage'),
        debug: true, // Enable debug mode to see console logs
        resources: {
            en: { translation: language.en },
            zh: { translation: language.zh },
            ja: { translation: language.ja }
        },
        interpolation: {
            escapeValue: false, // React already escapes values
        },
    });

export default i18n;