import React, { useState, useEffect } from "react";
import "share-api-polyfill";
import PageContainer from "../../components/PageContainer";
import EventInfo from "./components/Info";
import EventLineUp from "./components/LineUp";
import EventGallery from "./components/Gallery";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../components/Modal";
import CarouselWrapper from "../../components/CarouselWrapper";
import { SplideSlide } from "@splidejs/react-splide";
import { useTranslation } from "react-i18next";
import Share from "../../assets/images/icons/Share";
import BreadCrumb from "../../components/Breadcrumb";
import { Helmet } from "react-helmet-async";
import AuthPrivateEventModal from "../../components/AuthPrivateEventModal";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { setPrivateEvent } from "../../redux/slices/authSlice";
import { eventDetailInformation } from "../../utils/apiRequestList/eventServices";
import DangerouslySetInnerHTML from "../../components/DangerouslySetInnerHTML";
import formatDateRange from "../../utils/formatDateRange";
import Spinner from "../../components/Spinner";

const EventDetail = (props: any) => {
    const dispatch = useAppDispatch();
    const { privateEvent }: any = useAppSelector((state) => state.userAuth);
    const params: any = useParams()
    const { t } = useTranslation();
    const [innerData, setInnerData] = useState<any>({});
    const [permission, setPermission] = useState<boolean>(true);
    const [showAuthModal, setShowAuthModal] = useState<any>(false)
    const [refresh, setRefresh] = useState<boolean>(false);
    const [tabActive, setTabActive] = useState<any>(1);
    const [active, setActive] = useState<any>(0);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [currentPlayIndex, setCurrentPlayIndex] = useState<any>(null);
    const [modalGalleryPhotos, setModalGalleryPhotos]: any = useState([]);
    const [isVisible, setIsVisible] = useState(false);
    const mainHeaderBannerFade = innerData?.backgroundImages ? innerData?.backgroundImages.sort((a: any, b: any) => a.sort_order - b.sort_order) : ''
    const [mapUrl, setMapUrl]: any = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [carouselOption, setCarouselOption] = useState({
        type: 'slide',
        perPage: 1,
        arrows: true,
        start: 0,
        pagination: false,
        drag: false
    });
    const carouselBannerOption = {
        type: 'fade',
        rewind: true,
        perPage: 1,
        arrows: false,
        start: 0,
        interval: 10000,
        autoplay: true,
        pagination: false,
        pauseOnHover: false,
        pauseOnFocus: false,
        drag: false
    };
    const tab = [
        {
            name: t('InfoSubHeader'),
            value: 1
        },
        {
            name: t('Line-upSubHeader'),
            value: 2
        },
        {
            name: t('GallerySubHeader'),
            value: 3
        }
    ]

    useEffect(() => {
        if (refresh) {
            setPermission(true)
            setShowAuthModal(false);
            dispatch(setPrivateEvent([params.slug]));
        }
    }, [refresh]);

    const getEventDetails = async () => {
        setIsLoading(true)
        const response = await eventDetailInformation(params.slug, 'bypass=access')
        if (response?.data?.success) {
            setInnerData(response?.data?.data)
        }
        setIsLoading(false)
    }

    const handleScroll = () => {
        if (window.scrollY > 100) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    useEffect(() => {
        getEventDetails()
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        // const path = location.pathname?.split('/')[2]

        if (innerData?.isPrivate && !privateEvent?.includes(params.slug)) {
            setShowAuthModal(true)
            setPermission(false)
            return
        }

        if (privateEvent?.includes(params.slug)) {
            getEventDetails();
        }

        // if (innerData?.eventInfo?.is_private && path !== 'private') {
        //     navigate('/')
        //     console.log('1')
        //     // navigate(`/events/${params.slug}`)
        // }

        // if (!innerData?.eventInfo?.is_private && path === 'private') {
        //     navigate(`/events/${params.slug}`)
        //     console.log('2')
        // }

    }, [])

    const authModal = (val: boolean) => {
        setShowAuthModal(val);
    }

    useEffect(() => {
        if (showModal) {
            setTimeout(() => {
                const container = document.getElementById("bg-img");
                if (!container) return;

                const leftArrow: any = container.querySelector(".splide__arrow--prev");
                const rightArrow: any = container.querySelector(".splide__arrow--next");

                leftArrow.addEventListener("click", (event: any) => {
                    pauseVideo(currentPlayIndex)
                });
                rightArrow.addEventListener("click", (event: any) => {
                    pauseVideo(currentPlayIndex)
                });
            }, 0);
        }
    }, [showModal, currentPlayIndex]);

    const handleTabActive = (value: number, index: number) => {
        setActive(index)
        setTabActive(value)
    }

    const pauseVideo = (index: number) => {
        const video = document.querySelector(`#video-${index}`);
        if (video instanceof HTMLVideoElement) {
            video.pause();
        }
    };

    const playVideo = (index: number) => {
        const video = document.querySelector(`#video-${index}`);
        setCurrentPlayIndex(index)
        if (video instanceof HTMLVideoElement) {
            video.play();
        }
    };

    const handleShare = () => {
        window.navigator.share({
            title: document.title || 'Join me now!',
            text: "I'm joining Storm Music Festival Singapore!", // this value will need to be dynamic later, during the integration
            url: window.location.href
        })
            .then(_ => {
                console.log('Shared!')
            })
            .catch(error => console.log('Something went wrong', error));
    }

    const BuyNowComponent = (props: any) => {
        return <button onClick={() => navigate(`/events/${params.slug}/event-purchase-ticket`)} className={`h-12 px-3 py-2 text-base font-bold text-white rounded-full bg-ar-primary ${props.width}`}>
            {props.text ? props.text : t('BuyTicketButton')}
        </button>
    }

    // const baseURL = process.env.REACT_APP_DOMAIN ?? ''
    // console.log(`baseURL: ${baseURL}`)
    // console.log(`window.location.origin: ${window.location.origin}`)

    return <>
        <Helmet>
            <title>{`${innerData?.eventInfo?.title} ${(innerData?.eventInfo?.startDate && innerData?.eventInfo?.endDate) ? formatDateRange(new Date(innerData?.eventInfo?.startDate), new Date(innerData?.eventInfo?.endDate)) : ''}`}
            </title>
            <meta property="og:title"
                  content={`${innerData?.eventInfo?.title} ${(innerData?.eventInfo?.startDate && innerData?.eventInfo?.endDate) ? formatDateRange(new Date(innerData?.eventInfo?.startDate), new Date(innerData?.eventInfo?.endDate)) : ''} ${innerData?.eventInfo?.eventVenue?.name}`}
            />
            <meta property="og:description" content={innerData?.eventInfo?.description}/>
            <meta property="og:image" content={innerData?.eventInfo?.cover_image_mobile}/>
            <meta property="og:type" content="website"/>
        </Helmet>

        <div className="md:max-h-[65vh] overflow-hidden">
            <CarouselWrapper options={carouselBannerOption} customClass="event-detail-carousel md:max-h-[65vh]">
                {mainHeaderBannerFade?.length > 0 ? mainHeaderBannerFade.map((item: any, index: number) => {
                        return <SplideSlide key={index}>
                            <img className="hidden object-contain w-full h-full md:block" src={item?.landscape_media_url}
                                 alt=''/>
                            <img className="block object-contain w-full h-full md:hidden" src={item?.portrait_media_url}
                                 alt=''/>
                        </SplideSlide>
                    }) :
                    <div
                        className={`bg-[url('../assets/images/hero-banner.png')] bg-no-repeat bg-cover bg-center w-full h-full md:h-[478px] z-index`}>
                    </div>
                }
            </CarouselWrapper>
        </div>
        <div
            className={`md:mt-[64px] md:mb-[70px] w-full mb-[70px] mt-[20px]  xl:w-[1080px] bg-ar-black md:bg-opacity-90 md:backdrop-blur-[3.5px] m-auto relative lg:shadow-[0_9px_10px_3px_rgba(0,0,0,0.5)] `}>
            {
                (innerData && permission) ?
                    <>
                        <div
                            className="items-center justify-between px-[18px] md:px-[30px] pt-[30px] mb-2 md:mb-0 pt- md:flex">
                            <div>
                                <h2 className="text-xl font-semibold text-white mb-2">{innerData?.eventInfo?.title}</h2>
                                <div
                                    className="text-base font-normal leading-4 text-white">{innerData?.eventInfo?.eventVenue.country}</div>
                            </div>
                            <div>
                                <button onClick={() => navigate(`/events/${params.slug}/event-purchase-ticket`)}
                                        className="h-12 px-8 py-2 text-sm font-bold text-white rounded-full bg-ar-primary min-w-max hidden md:block">{t('BuyTicketButton')}</button>
                            </div>
                        </div>

                        <div className={`block fixed bottom-0 left-0 shadow-none lg:hidden w-full transition-all z-30`}>
                            <div
                                className="flex items-center justify-between p-3 left-0 right-0 m-auto bg-ar-black md:hidden">
                                <BuyNowComponent width={'w-[200px] m-auto'}/>
                            </div>
                        </div>

                        <div
                            className="m-0 px-[18px] md:px-0 md:mx-[30px] pt-4 md:pt-6 border-b-[0.5px] border-ar-purple flex justify-between relative">
                            <div className="flex justify-items-start">
                                {tab.map((item: any, index: number) => {
                                    return <div key={index}
                                                className={"mr-5 border-b-[5px] pb-2 text-base md:text-lg cursor-pointer text-ar-purple " + (active === index ? 'border-white text-white font-semibold' : 'border-transparent')}
                                                onClick={() => handleTabActive(item.value, index)}>{item.name}</div>
                                })}
                            </div>

                            <div
                                className="flex items-center justify-center rounded-full cursor-pointer bg-[#15141E] w-9 h-9 lg:hidden absolute right-5 top-1/2 -translate-y-1/2"
                                onClick={handleShare}>
                                <Share/>
                            </div>
                        </div>

                        {
                            isLoading ? <div className="min-h-[300px] flex justify-center items-center">
                                <Spinner/>
                            </div> : <>
                                {tabActive === 1 && <EventInfo
                                    eventInfo={innerData?.eventInfo}
                                    setShowAddress={setShowAddress}
                                    setMapUrl={setMapUrl}
                                />}
                                {tabActive === 2 && <EventLineUp slug={params.slug}/>}
                                {tabActive === 3 && <EventGallery
                                    slug={params.slug}
                                    setCarouselOption={setCarouselOption}
                                    setShowModal={setShowModal}
                                    carouselOption={carouselOption}
                                    setModalGalleryPhotos={setModalGalleryPhotos}
                                />}
                            </>
                        }
                    </>
                    :
                    <div className="pt-16 pb-16 text-center">
                        <svg className="m-auto mb-6" width="48" height="48" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#a)">
                                <path
                                    d="M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24h19.884A4.117 4.117 0 0 0 48 43.884V24C48 10.745 37.255 0 24 0Z"
                                    fill="#6650F2"/>
                                <path
                                    d="M21.713 27.914c0-3.206 1.766-4.35 3.305-5.366 1.21-.818 2.29-1.538 2.29-3.074 0-1.736-1.18-2.715-2.88-2.715-1.702 0-2.912 1.015-2.912 2.813v.262H17.82v-.394c0-3.862 2.846-6.315 6.708-6.315s6.708 2.389 6.708 6.315c0 3.403-1.865 4.483-3.468 5.431-1.277.754-2.422 1.407-2.422 3.043v.327h-3.63v-.327h-.003Zm-.72 4.875c0-1.472 1.111-2.585 2.585-2.585 1.473 0 2.584 1.111 2.584 2.585 0 1.473-1.11 2.584-2.584 2.584s-2.585-1.11-2.585-2.584Z"
                                    fill="#fff"/>
                            </g>
                            <defs>
                                <clipPath id="a">
                                    <path fill="#fff" d="M0 0h48v48H0z"/>
                                </clipPath>
                            </defs>
                        </svg>
                        <div className="mb-2 text-2xl font-semibold text-ar-purple">{t('NoResultsFound')}</div>
                        <div className="text-base font-normal">{t('WeCouldntSearch')}<br/>{t('TrySearchAgain')}</div>
                    </div>
            }
        </div>

        {showModal && <Modal
            showModal={showModal}
            setShowModal={setShowModal}
            showCloseBtn={true}
            footer={false}
            header={true}
            paddingx={'px-0'}
            paddingTop={'pt-0'}
            paddingBottom={'pb-0'}
            closeBtnPosition={'right-[15px] top-[15px]'}
            closeButton={<svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="13.5" fill="#211F2E"/>
                <path d="m10.5 19.5 9-9M19.5 19.5l-9-9" stroke="#9B8BFF"/>
            </svg>}
        >
            <div id='bg-img' className="md:w-[75vw]">
                <CarouselWrapper options={carouselOption} customClass="gallery-carousel">
                    {modalGalleryPhotos.length > 0 && modalGalleryPhotos.map((item: any, index: number) => {
                        return <SplideSlide key={index}>
                            <div className="relative bg-ar-black">
                                <div
                                    className="flex items-center justify-center rounded-full cursor-pointer bg-[#211f2e] w-[22.5px] h-[22.5px] lg:hidden absolute right-[17.5px] top-[55.5px]"
                                    onClick={handleShare}>
                                    <Share/>
                                </div>
                                {item.type === '651b90d91630d2545c7d526d' && <>
                                    <div className="flex items-center justify-center h-[100vh] md:h-[75vh]">
                                        <img className="object-cover w-full h-auto md:h-full md:object-contain"
                                             src={item.url} alt=''/>
                                    </div>
                                    <div
                                        className="absolute w-full py-6 bg-ar-black bg-opacity-60 backdrop-blur-[1px] bottom-14 px-14">
                                        <div className="text-xs font-normal text-white">{item?.folderName}</div>
                                        <DangerouslySetInnerHTML
                                            classNames={'text-base font-normal text-white editor-formatting'}
                                            htmlContent={item?.description}
                                        />
                                    </div>
                                </>}
                                {item.type === '651b90d91630d2545c7d526e' && <>
                                    <div className="flex items-center justify-center h-[100vh] md:h-[75vh]">
                                        <div className="relative custom-video">
                                            <video
                                                id={`video-${index}`}
                                                className="w-full h-auto m-auto md:h-[500px]"
                                                onPause={() => pauseVideo(index)}
                                                onPlay={() => playVideo(index)}
                                                controls={true}
                                            >
                                                <source
                                                    src={item?.url}
                                                    type="video/mp4"
                                                />
                                                Your browser does not support the video tag.
                                            </video>
                                            <div className={`video-text absolute bottom-16 left-5`}>
                                                <div
                                                    className="text-xs font-normal text-white ">{item?.folderName}</div>
                                                <DangerouslySetInnerHTML
                                                    classNames={'text-base font-normal text-white editor-formatting'}
                                                    htmlContent={item?.description}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </>}
                            </div>
                        </SplideSlide>
                    })}
                </CarouselWrapper>
            </div>
        </Modal>
        }

        {
            showAddress && <Modal
                showModal={showAddress}
                setShowModal={setShowAddress}
                showCloseBtn={true}
                footer={false}
                header={true}
                paddingx={'p-0'}
                paddingTop={'pt-0'}
            >
                <div className="w-full h-full lg:w-[1000px] md:h-[450px] full-iframe">

                    {mapUrl.url
                        ?
                        mapUrl.type === 'google'
                            ?
                            <div dangerouslySetInnerHTML={{__html: mapUrl.url}}></div> // Dompurify case the google iframe not working, temp remove component
                            :
                            <iframe src={mapUrl.url} width="100%" height="100%" loading="lazy" title='test'></iframe>
                        :
                        <div className="flex items-center justify-center w-full h-full"> No Map Information </div>
                    }
                </div>
            </Modal>
        }

        <AuthPrivateEventModal
            showAuthModal={showAuthModal}
            authModal={(val: any) => {
                authModal(val)
            }}
            params={params.slug}
            setRefresh={(val: any) => {
                setRefresh(val)
            }}
            data={(val: any) => setInnerData(val)}
        />
    </>
}

export default EventDetail;