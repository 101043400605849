import React from 'react';
import ExceptionQuestion from '../../assets/images/icons/exception-question.png';

const ExceptionToast = (props:any) => {
    return(
        <div className="toast__inner">
            <div className='toast__inner-left'>
                <img src={ExceptionQuestion} alt=""/>
            </div>
            <div className="toast__inner-right">
                {/* <p className='title'>No results found</p> */}
                <p className='msg'>{props?.message}</p>
            </div>
        </div>
    )

}

export default ExceptionToast;