import React, { useEffect } from "react";

const Modal = (props: any) => {
    const [showModal, setShowModal] = React.useState(false);

    useEffect(() => {
        setShowModal(props.showModal)
        if (props.showModal) {
            document.body.classList.add('overflow-hidden');
        } else {
            document.body.classList.remove('overflow-hidden');
        }
    }, [props])

    const closeModal = () => {
        props.setShowModal(false)
        document.body.classList.remove('overflow-hidden');
    }

    return <>
        {showModal ? (
            <>
                <div className={props?.backdrop && 'fixed w-[100vw] h-[100vh] fixed left-0 bg-ar-black/90 backdrop-blur-[5px] z-50'}>
                    <div
                        onClick={()=> {
                            if (props?.backdropClose) closeModal()
                        }}
                        className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed text-white inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative md:w-auto lg:w-auto md:h-auto md:my-6 md:mx-auto w-full mx-0 my-0 h-full"
                             onClick={(e) => e.stopPropagation()}>
                            <div className={
                                "border-0 rounded-none shadow-lg relative flex flex-col bg-ar-black outline-none focus:outline-none md:max-h-[85vh] md:h-auto h-full "
                                + (props?.borderRadius ? props.borderRadius + ' ' : 'md:rounded-lg')
                                + (props.paddingx ? props.paddingx + ' ' : 'px-[30px] ')
                                + (props.paddingTop ? props.paddingTop + ' ' : 'pt-[15px] ')
                                + (props.paddingBottom ? props.paddingBottom + ' ' : 'pb-[30px] ')
                                + (props?.width ? props?.width + ' ' : 'w-full ' )
                                + (props?.className ? props?.className + ' ' : '' )
                            } style={{padding: props?.noModalPadding ? '0px 0px 0px 0px' : ''}}>
                                {/*header*/}
                                {props.header && <button
                                    className={`absolute ${props?.closeBtnPosition ? props?.closeBtnPosition : 'right-8 top-4'} ${props?.showCloseBtn ? 'block' : 'hidden'}  z-20`}
                                    onClick={() => closeModal()}
                                >
                                    {props.closeButton ? props.closeButton : <svg width="30" height="30" fill="#6650F2" xmlns="http://www.w3.org/2000/svg"><g stroke="white"><circle cx="15" cy="15" r="11.25" /><path d="m11.25 18.75 7.5-7.5M18.75 18.75l-7.5-7.5" /></g></svg>}
                                </button>}
                                {/*body*/}
                                {props?.children}
                                {/*footer*/}
                                {props.footer && <div>props.footer</div>}
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black overflow-hidden"></div>
                </div>
            </>
        ) : null}
    </>
}

export default Modal;