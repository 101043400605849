import { Decimal } from "decimal.js";

const zeroDecimalCurrencies = [
    'bif',
    'clp',
    'djf',
    'gnf',
    'jpy',
    'kmf',
    'krw',
    'mga',
    'pyg',
    'rwf',
    'ugx',
    'vnd',
    'vuv',
    'xaf',
    'xof',
    'xpf',
]

const threeDecimalCurrencies = [
    'bhd',
    'jod',
    'kwd',
    'omr',
    'tnd',
]

export default function (amount: Decimal.Value, currencyCode: String) {
    const currency = currencyCode.toLowerCase()

    if (zeroDecimalCurrencies.includes(currency)) {
        return new Decimal(amount).toDecimalPlaces(0, Decimal.ROUND_FLOOR).toNumber()
    }
    else if (threeDecimalCurrencies.includes(currency)) {
        return new Decimal(amount).times(1000).toNumber()
    }
    else {
        return new Decimal(amount).times(100).toNumber()
    }
}