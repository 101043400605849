import React from "react";
import {FormikProps, FormikValues} from "formik";
import * as Yup from 'yup';
import ErrorMessage from "./errorMessage";
import FormWrapper from "./FormWrapper";
import {useTranslation} from "react-i18next";


const SignupSchema = Yup.object({
    firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(50, 'Too Long!')
        .required('Required'),
    email: Yup.string().typeError('type error1')
        .matches(/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/, 'typing error2').required('Required'),
});

const LoginForm = () => {
    const { t } = useTranslation();

    const formInputs = ({errors, touched, values, handleBlur, handleChange}: FormikProps<FormikValues>) => (
        <>
            <div>
                <label htmlFor="firstName" className="block text-sm font-medium leading-6 text-gray-900">First Name</label>
                <input type="text" name="firstName" id="firstName"
                       value={values.firstName}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                       placeholder="First Name"/>
                {errors.firstName && touched.firstName ? (
                    <ErrorMessage text={errors.firstName}/>
                ) : null}
            </div>
            <div>
                <label htmlFor="lastName" className="block text-sm font-medium leading-6 text-gray-900">Last Name</label>
                <input type="text" name="lastName" id="lastName"
                       value={values.lastName}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                       placeholder="Last Name"/>
                {errors.lastName && touched.lastName ? (
                    <ErrorMessage text={errors.lastName}/>
                ) : null}
            </div>
            <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">Email</label>
                <input type="text" name="email" id="email"
                       value={values.email}
                       onChange={handleChange}
                       onBlur={handleBlur}
                       className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                       placeholder={t('Email')}/>
                {errors.email && touched.email ? <ErrorMessage text={errors.email}/> : null}
            </div>
        </>
    )

    return <FormWrapper
        initialValues={{
            firstName: '',
            lastName: '',
            email: '',
        }}
        validationSchema={SignupSchema}
        formInputs={formInputs}
        onSubmit={values => {
            // same shape as initial values
            console.log('check what values here?', values);
        }}
        toggleHoneyPot={true}
        // submitButton={<button type='submit'>testing</button>}
    />
}

export default LoginForm;