import React, {useEffect} from "react";
import {SplideSlide } from "@splidejs/react-splide";
import CarouselWrapper from "../../../components/CarouselWrapper";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import NoIMage from "../../../assets/images/no-image.png";
import ViewMoreBtn from "../../../components/Buttons/ViewMore";

const NewEventsCarousel = (props:any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    useEffect(() => {
        const container = document.getElementById("new-events-carousel");
        if (!container) return;

        const leftArrow = container.querySelector(".splide__arrow--prev");
        const rightArrow = container.querySelector(".splide__arrow--next");

        const handleMouseMove = (e: MouseEvent) => {
            const threshold = 133;
            if (e.target instanceof HTMLElement) {
                if (e.x <= threshold) {
                    leftArrow?.setAttribute("style", "background-color: rgba(33,31,46,0.9)");
                } else if (e.x >= container.offsetWidth - threshold) {
                    rightArrow?.setAttribute("style", "background-color: rgba(33,31,46,0.9)");
                } else {
                    leftArrow?.setAttribute("style", "background-color: rgba(33,31,46,0.6)");
                    rightArrow?.setAttribute("style", "background-color: rgba(33,31,46,0.6)");
                }
            }
        };
        container?.addEventListener("mousemove", handleMouseMove);

        return () => {
            container.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    const data = props?.newEvents;

    const formatDate = (startDate:any, endDate:any) =>{
        let resultDate = ''

        if ( moment(startDate).format("D MMM YYY") === moment(endDate).format("D MMM YYY") ) { //same date/month/year
            const start = moment(startDate).format("D MMM YYYY");

            resultDate = `${start}`
        } else if ( moment(startDate).format("MMM YYYY") === moment(endDate).format("MMM YYYY") ) { //same month/year
            const start = moment(startDate).format("D");
            const end = moment(endDate).format("D MMM YYYY");

            resultDate = `${start} - ${end}`
        } else if ( moment(startDate).format("YYYY") !== moment(endDate).format("YYYY") ){ // diff year
            const start = moment(startDate).format("D MMM YYYY");
            const end = moment(endDate).format("D MMM YYYY");

            resultDate = `${start} - ${end}`
        }else {
            const start = moment(startDate).format("D MMM");
            const end = moment(endDate).format("D MMM YYYY");

            resultDate = `${start} - ${end}`
        }

        return resultDate
    }

    return(
        <>
            <div className='bg-ar-black py-[58px]'>
                <div className='container mx-auto xl:max-w-[1080px] max-w-[calc(100%-60px)]'>
                    <h3 className="text-[26px] md:text-[34px] font-semibold text-center text-ar-purple">{t('NewEventsHeader')} </h3>
                </div>
                <div className='mx-auto pt-[43px]' id='new-events-carousel'>
                    <CarouselWrapper
                        options={{
                            type: "slide",
                            padding: { left: "100px", right: "100px"},
                            arrows: false,
                            // perPage: 4,
                            gap: "1rem",
                            pagination: false,
                            drag: false,
                            fixedWidth: '330px',
                            // fixedHeight: '185px',
                            breakpoints: {
                                768: {
                                    // perPage: 2,
                                    arrows: false,
                                    drag: true,
                                    padding: { left: "34px", right: "34px"},
                                    fixedWidth: '150px',
                                    // fixedHeight: '150px',
                                },
                                1280: {
                                    // perPage: 4,
                                },
                            }
                        }}
                    >
                        {data?.map((item:any, i:number) => (
                            <SplideSlide key={i}>
                                <div className='cursor-pointer flex flex-col justify-between'>
                                    <div className='h-[100%]' onClick={() => navigate(`/events/${item?.slug}`)}>
                                        <img
                                            src={item?.cover_image_desktop || NoIMage}
                                            alt={item?.title}
                                            className={`object-cover hidden md:block w-[330px] h-[185px]  
                                                        ${item?.cover_image_desktop ? '' : 'border-[#727272] border'}
                                                       `}
                                            style={{maxWidth: "100%"}}
                                        />
                                        <img
                                            src={item?.cover_image_mobile || NoIMage}
                                            alt={item?.title}
                                            className={`md:hidden w-[150px] h-[150px] object-cover
                                                        ${item?.cover_image_mobile ? '' : 'border-[#727272] border'}
                                                       `}
                                            style={{maxWidth: "100%"}}
                                        />
                                    </div>
                                    <div className='mt-[18px]'>
                                        <div className='text-[16px] md:text-[18px] font-bold'>{item?.title}</div>
                                        <div
                                            className='text-[16px] md:text-[18px]'>{`${item?.event_venue_id?.country_id?.code}, ${item?.event_venue_id?.name}`}</div>
                                        <div className='text-[14px] md:text-[16px]'>
                                        {formatDate(item?.start_date, item?.end_date)}
                                        </div>
                                    </div>
                                </div>
                            </SplideSlide>
                        ))}
                    </CarouselWrapper>
                </div>
            </div>
            <div className="mb-[58px]" onClick={()=> navigate('/events')}>
                <ViewMoreBtn title={t('ViewMoreEventsButton')} />
            </div>
        </>
    )

}
export default NewEventsCarousel;

