const ViewMoreBtn = (props:any) => {

    return(
        <>
            <div className="text-sm text-center">
                <button className="bg-ar-primary py-[13px] px-[19px] border border-ar-primary rounded-full hover:opacity-60 transition-all">
                    {props?.title}
                </button>
            </div>
        </>
    )

}

export default ViewMoreBtn;