import { useState } from "react";
import Modal from "./Modal";
import { Link } from "react-router-dom";
import Back from "../../assets/images/icons/Back";

const OTPForm = ({ emailTarget }: { emailTarget: string }) => {
    const length = 4;

    return (
        <form className="text-white bg-ar-black">
            <h3 className="text-[26px] font-semibold mb-2">OTP Requested</h3>
            <p className="mb-4">
                An email with a one-time pin (OTP) was sent to{" "}
                <span className="text-[#28D7FE]">{emailTarget}</span>
            </p>
            <div className="flex h-16 border">
                {Array(length)
                    .fill(null)
                    .map((_, i) => (
                        <input
                            type="text"
                            className="flex-1 block max-w-full min-w-0 text-xl font-bold text-center bg-transparent outline-none"
                            key={i}
                            placeholder="-"
                            maxLength={1}
                        />
                    ))}
            </div>
        </form>
    );
};

const LoginForm = ({ onSubmit }: { onSubmit: (data: string) => void }) => {
    const [email, setEmail] = useState("");
    return (
        <div>
            <form className="mt-4" onSubmit={() => onSubmit(email)}>
                <label htmlFor="">Email</label>
                <input
                    type="email"
                    className="input"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                />
                <input
                    type="submit"
                    value="Log In"
                    className="flex items-center justify-center w-full h-12 mt-4 text-white rounded-lg cursor-pointer bg-ar-primary"
                />
            </form>
            <div className="mt-5 text-center">
                <Link to="/" className="text-ar-primary">
                    Login with mobile
                </Link>
                <p>
                    New user? Register{" "}
                    <Link to="/" className="text-ar-primary">
                        here
                    </Link>{" "}
                    with your mobile number
                </p>
            </div>
        </div>
    );
};

export default function EmailAuth({ isOpen, setIsOpen }: any) {
    const [email, setEmail] = useState("");

    return (
        <div>
            <Modal
                isOpen={false}
                title={
                    email ? (
                        <div className="cursor-pointer" onClick={() => setEmail("")}>
                            <Back />
                        </div>
                    ) : (
                        `Login with email`
                    )
                }
                closeModal={() => setIsOpen(false)}
            >
                {email ? (
                    <OTPForm emailTarget={email} />
                ) : (
                    <LoginForm onSubmit={(data) => setEmail(data)} />
                )}
            </Modal>
        </div>
    );
}
