import React from 'react';
const TagLabel = (props:any) => {

    return(
        <>
            <div style={{background: `${props?.bgColor}`, color: `${props?.color}`}} className={`text-[12px] mr-[4px] rounded-[15px] py-[3px] px-[9px] whitespace-nowrap`}>
                {props?.label}
            </div>
        </>
    )

}

export default TagLabel;