import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import formatDateRange from "../../../utils/formatDateRange";
import { useSelector } from "react-redux";
import { getCurrentLocation } from "../../../redux/slices/locationSlice";
import DangerouslySetInnerHTML from "../../../components/DangerouslySetInnerHTML";

const EventInfo = (props: any) => {
    const currentLocation = useSelector(getCurrentLocation)
    const { t } = useTranslation();
    const [readMore, setReadMore] = useState(false);
    const [currentInfoHeight, setCurrentInfoHeight] = useState(0);
    const myDivRef: any = useRef();

    useEffect(() => {
        const divHeight = myDivRef.current.clientHeight;
        setCurrentInfoHeight(divHeight);
    }, []);

    const openMap = (baidu: string, google: string) => {
        props.setShowAddress(true)
        const url = currentLocation.countryCode === 'CN' ? baidu : google;
        props.setMapUrl({
            url: url,
            type: currentLocation.countryCode === 'CN' ? 'baidu' : 'google'
        })
    }


    return <div className="p-[30px] md:flex md:flex-row-reverse md:px-[30px] md:mt-10">
        <div className="mb-4 md:w-[409px]">
            <div className="flex items-center mb-2 md:block">
                <div className="hidden text-base font-bold md:block">{t('Date')}</div>
                <div className="flex items-baseline">
                    <div className="w-[10px]">
                        <svg className="w-[10px]" width="10" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.583 6.75a.538.538 0 0 1-.396-.16.538.538 0 0 1-.16-.396c0-.157.054-.289.16-.395a.538.538 0 0 1 .396-.16c.158 0 .29.053.396.16.107.106.16.238.16.395 0 .158-.053.29-.16.396a.538.538 0 0 1-.396.16Zm2.459 0a.538.538 0 0 1-.396-.16.538.538 0 0 1-.16-.396c0-.157.053-.289.16-.395a.538.538 0 0 1 .396-.16c.157 0 .289.053.396.16.106.106.16.238.16.395 0 .158-.054.29-.16.396a.538.538 0 0 1-.396.16Zm2.36 0a.538.538 0 0 1-.395-.16.538.538 0 0 1-.16-.396c0-.157.053-.289.16-.395a.538.538 0 0 1 .396-.16c.157 0 .29.053.396.16.106.106.16.238.16.395 0 .158-.054.29-.16.396a.538.538 0 0 1-.396.16ZM.834 11.111a.8.8 0 0 1-.583-.25.8.8 0 0 1-.25-.583V1.667a.8.8 0 0 1 .25-.584.8.8 0 0 1 .583-.25h.903V0h.903v.833H7.36V0h.903v.833h.903a.8.8 0 0 1 .583.25.8.8 0 0 1 .25.584v8.61a.8.8 0 0 1-.25.584.8.8 0 0 1-.583.25H.833Zm0-.833h8.334V4.306H.833v5.972Zm0-6.806h8.334V1.667H.833v1.805Z" fill="white" /></svg>
                    </div>
                    <div className="ml-2">
                        {
                            (props?.eventInfo?.startDate && props?.eventInfo?.endDate) ?
                            formatDateRange(new Date(props?.eventInfo?.startDate), new Date(props?.eventInfo?.endDate)) :
                            ''
                        }
                    </div>
                </div>
            </div>
            <div className="flex items-center md:block">
                <div className="hidden text-base font-bold md:block">{t('Venue')}</div>
                <div className="flex items-baseline">
                    <div className="w-[10px]">
                        <svg width="10" height="12" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)"><path d="M8.536 1.5c-1.953-2-5.119-2-7.071 0-1.953 2-1.953 5.242 0 7.242l2.929 3a.843.843 0 0 0 1.212 0l2.93-3c1.953-2 1.953-5.242 0-7.242ZM3.353 6.809a2.427 2.427 0 0 1 0-3.375 2.29 2.29 0 0 1 3.294 0c.91.932.91 2.443 0 3.375a2.29 2.29 0 0 1-3.295 0Z" fill="#fff" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h10v12H0z" /></clipPath></defs></svg>
                    </div>
                    <div onClick={() => openMap(props?.eventInfo?.eventVenue?.baiduMapUrl, props?.eventInfo?.eventVenue?.googleMapUrl)} className="ml-2 text-ar-purple decoration-solid cursor-pointer">
                        <span className="text-white">{props?.eventInfo?.eventVenue?.name}</span> <br /> {props?.eventInfo?.eventVenue?.address} {props?.eventInfo?.eventVenue?.city} {props?.eventInfo?.eventVenue?.postalCode}</div>
                </div>
            </div>
        </div>
        <div className="event-info md:border-r md:border-ar-purple md:pr-16 md:mr-6 w-full">

            <div ref={myDivRef}>
                <DangerouslySetInnerHTML
                    classNames={`editor-formatting ${currentInfoHeight >= 300 ? !readMore ? 'overflow-hidden max-h-[300px]' : 'overflow-none' : 'overflow-hidden max-h-[300px]'}`}
                    htmlContent={props?.eventInfo?.description}
                />
            </div>

            {currentInfoHeight >= 300 && <div className="text-base font-bold underline cursor-pointer mt-3" onClick={() => setReadMore(!readMore)}>{readMore ? 'Read less' : 'Read more'}</div>}
        </div>
    </div >
}

export default EventInfo;