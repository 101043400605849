import React, { useState } from 'react';


interface QtyCtrlProps {
    value: number | undefined | null,
    disabledMinus: boolean,
    disabledPlus: boolean,
    onPlus?: () => void;
    onMinus?: () => void;
}

const QuantityController = (props: QtyCtrlProps) => {
    const [isScaledMinus, setIsScaledMinus] = useState(false);
    const [isScaledPlus, setIsScaledPlus] = useState(false);


    const minusHandler = (e: any) => {
        e?.stopPropagation();
        if (props?.onMinus) props?.onMinus()
    }
    const plusHandler = (e: any) => {
        e?.stopPropagation();
        if (props?.onPlus) props?.onPlus()
    }

    const handleMouseDownMinus = () => {
        if (!props.disabledMinus) setIsScaledMinus(true);
    };
    const handleMouseUpMinus = () => {
        setIsScaledMinus(false);
    };

    const handleMouseDownPlus = () => {
        if (!props.disabledPlus) setIsScaledPlus(true);
    };
    const handleMouseUpPlus = () => {
        setIsScaledPlus(false);
    };

    return (
        <div className='quantity-controller'>
            <button disabled={props?.disabledMinus} onClick={minusHandler} onMouseDown={handleMouseDownMinus} onMouseUp={handleMouseUpMinus}
                className={`minus ${isScaledMinus ? 'scale-110' : ''}`}
            ></button>
            <div className='quantity font-bold'>{props?.value ?? 0}</div>
            <button disabled={props?.disabledPlus} onClick={plusHandler} onMouseDown={handleMouseDownPlus} onMouseUp={handleMouseUpPlus}
                className={`plus ${isScaledPlus ? 'scale-110' : ''}`}
            ></button>
        </div>
    )
}
export default QuantityController;