import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { getProfile } from "../../utils/apiRequestList/profileService";
import ProfilePageForm from "./profileForm";


const ProfilePage = () => {
    const [profileInfo, setProfileInfo] = useState<any>(null)


    const fetchData = async ()=>{
        try {
            const response = await getProfile();
            setProfileInfo(response?.data?.data)
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    const refresh = ()=> {
        fetchData();
    }

    return <>
        <Helmet>
            <title>My Profile | Aria </title>
            <meta property="og:title" content="My Profile | Aria " />
            <meta property="og:description" content="Manage your personal information and newsletter subscriptions here." />
            <meta property="og:type" content="website" />
        </Helmet>
        {profileInfo &&
            <ProfilePageForm profileInfo={profileInfo} refresh={refresh} />
        }
    </>
}

export default ProfilePage;