import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';
import { addSeconds } from "date-fns";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import { useAppSelector } from "../../hooks/useStore";
import CheckoutForm from "./CheckoutForm";
import ExpiredCheckout from "./components/ExpiredCheckout";
import FailedCheckout from "./components/FailedCheckout";
import stripeAmountFormatByCurrency from "../../utils/stripeAmountFormatByCurrency";
import i18n from "../../languages/i18n";
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env?.REACT_APP_STRIPE_PUBLIC_KEY ?? '');

export default function Checkout() {
  const navigate = useNavigate();
  const { ticketCheckout }: any = useAppSelector((state) => state.ticket);
  const totalAmount = ticketCheckout ? stripeAmountFormatByCurrency(ticketCheckout?.total ?? 0, ticketCheckout?.currency?.toLowerCase()) : 0;
  const [showModal, setShowModal] = useState(false)
  const [isExpired, setIsExpired] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [expiredTimer, setExpiredTimer] = useState<any>(null)
  const [isValidCheckout, setIsValidCheckout] = useState(false);
  let submitting: Boolean = false;

  const paymentOptions: StripeElementsOptions = {
    mode: 'payment',
    amount: totalAmount,
    currency: ticketCheckout?.currency?.toLowerCase(),
    paymentMethodCreation: 'manual',
    // paymentMethodTypes: ['card', 'alipay'],
    // Fully customizable with appearance API.
    // @ts-ignore
    locale: i18n?.language?.toLowerCase() ?? undefined,
    appearance: {
      rules: {
        '.Label': {
          color: '#fff'
        }
      }
    },
  };

  useEffect(() => {
    if (ticketCheckout && ticketCheckout?.session_expired) {
      const expireTime = new Date(ticketCheckout?.session_expired);
      const currentTime = new Date()

      if (currentTime > expireTime) {
        navigate('/events')
      } else {
        setIsValidCheckout(true)
      }
    } else {
      navigate('/events')
    }
  }, []);

  useEffect(() => {
    if (isExpired) {
      setExpiredTimer(addSeconds(new Date(), 10))
    }
  }, [isExpired])

  const expiredStatus = (status: boolean) => {
    setShowModal(true)
    setIsExpired(status)
    setIsFailed(false)
  }

  const failedStatus = (status: boolean) => {
    if (!isExpired) {
      setIsFailed(status)
      setShowModal(true)
    }
  }

  const reselectTicket = (status: boolean) => {
    setShowModal(false)
    setIsExpired(status)
    navigate("/events")
  }

  const reCheckout = (status: boolean) => {
    setShowModal(false)
    setIsFailed(status)
    setIsExpired(false)
  }

  return (
    <>
      {
          isValidCheckout &&
          <>

            <Helmet>
              <title>{`${ticketCheckout?.event?.title} Pay in your preferred way`}</title>
              <meta property="og:title" content={`${ticketCheckout?.event?.title} Pay in your preferred way`} />
              <meta property="og:description" content="Finalize your ticket purchase with ease. Complete your order with your preferred payment method and get ready for an unforgettable experience!" />
              <meta property="og:type" content="website" />
            </Helmet>

            <Elements stripe={stripePromise} options={paymentOptions}>
              <CheckoutForm setSubmitting={(bool: Boolean) => {
                submitting = bool
              }} ticketCheckout={ticketCheckout} expiredStatus={expiredStatus} failedStatus={failedStatus} />
            </Elements>
            <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                showCloseBtn={false}
                backdrop={true}
                footer={false}
                header={false}>
              <>
                {isExpired && <ExpiredCheckout reselectTicket={reselectTicket} expiredTimer={expiredTimer} />}
                {isFailed && <FailedCheckout reCheckout={reCheckout} />}
              </>
            </Modal>
          </>
      }
    </>
  )
}