import React, { useState, useEffect } from "react";
import SearchInput from "../../../components/SearchInput";
import { useNavigate, useLocation } from "react-router-dom";
import { eventDetailLineUp } from "../../../utils/apiRequestList/eventServices";
import { useDebouncedValue } from "@mantine/hooks";
import Spinner from "../../../components/Spinner";
import InfiniteScroll from "react-infinite-scroller";
import NoIMage from '../../../assets/images/no-image.png'
import {useTranslation} from "react-i18next";


const EventLineUp = (props: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [artistList, setArtistList]: any = useState([]);
    const [query, setQuery] = useState<string>("");
    const [debouncedQuery] = useDebouncedValue(query, 1000);
    const [loading, setLoading] = useState(true);
    const [infiniteLoader, setInfiniteLoader] = useState({
        page: 1,
        hasMore: false,
    });

    const fetchData = async () => {
        if (infiniteLoader.page === 1) { setLoading(true); }
        let sortName: any = '';

        try {
            if (debouncedQuery) {
                sortName += `&searchByName=${debouncedQuery}`
            }
            const response: any = await eventDetailLineUp(props.slug, `records=20&page=${infiniteLoader.page}${sortName}`);
            if (response?.data?.data) {
                const data = response?.data?.data?.docs
                setArtistList((prevData: any) => [...prevData, ...data]);
                setInfiniteLoader({
                    page: response?.data?.data?.nextPage,
                    hasMore: response?.data?.data?.hasNextPage
                });
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            }
        } catch (error) {
            setLoading(false);
            setArtistList([])
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData();
    }, [debouncedQuery]);


    const handleResetPage = () => {
        setQuery('')
        setArtistList([])
        setInfiniteLoader({ page: 1, hasMore: false })
        setLoading(true)
    }

    return <>
        <div className='relative p-5 md:px-8'>
            <div className="mb-2">
                <SearchInput handleResetPage={handleResetPage} setQuery={setQuery} />
            </div>

            {loading ?
                <div className="min-h-[300px] flex justify-center items-center">
                    <Spinner />
                </div>
                :
                <div className="">
                    <InfiniteScroll
                        pageStart={infiniteLoader.page}
                        loadMore={fetchData}
                        hasMore={infiniteLoader.hasMore}
                        threshold={200}
                        loader={<h4 className="text-center">Loading...</h4>}
                    >
                        <div className="flex-wrap items-baseline justify-start block md:flex">
                            {artistList.map((item: any, index: number) => (
                                <div key={index} className="flex cursor-pointer items-center justify-between py-3 pr-[16px] md:pr-3 px-2 md:px-0 md:pb-10 border-b-[0.5px] border-b-[#9B8BFF] md:w-1/4 lg:w-1/6 md:border-none md:block" onClick={() => { navigate(`/artistes/${item?.slug}?redirectPath=${encodeURI(pathname ?? '')}`) }}>
                                    <img className={`min-w-[35px] w-[35px] mr-4 h-[35px] md:w-32 md:h-32 md:m-auto object-cover object-center rounded-full overflow-hidden md:mb-2 ${item?.profilePicture_thumb ? '' : 'border border-ar-purple'}`} src={item?.profilePicture_thumb || NoIMage} alt={item?.artistName} />
                                    <div className="w-full line-clamp-3 md:w-full xl:w-32 md:m-auto">
                                        <div className="w-full text-sm font-normal text-left md:text-base md:text-center md:min-w-32">
                                            {item?.artistName}
                                        </div>
                                    </div>
                                    <svg className="block md:hidden" width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m9 18 6-6-6-6" stroke="#9B8BFF" strokeWidth="2" /></svg>
                                </div>
                            ))}
                        </div>
                    </InfiniteScroll>
                    {!artistList.length && <div className="w-full pb-16 pt-16 md:pt-0 text-center">
                        <svg className="m-auto mb-6" width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)"><path d="M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24h19.884A4.117 4.117 0 0 0 48 43.884V24C48 10.745 37.255 0 24 0Z" fill="#6650F2" /><path d="M21.713 27.914c0-3.206 1.766-4.35 3.305-5.366 1.21-.818 2.29-1.538 2.29-3.074 0-1.736-1.18-2.715-2.88-2.715-1.702 0-2.912 1.015-2.912 2.813v.262H17.82v-.394c0-3.862 2.846-6.315 6.708-6.315s6.708 2.389 6.708 6.315c0 3.403-1.865 4.483-3.468 5.431-1.277.754-2.422 1.407-2.422 3.043v.327h-3.63v-.327h-.003Zm-.72 4.875c0-1.472 1.111-2.585 2.585-2.585 1.473 0 2.584 1.111 2.584 2.585 0 1.473-1.11 2.584-2.584 2.584s-2.585-1.11-2.585-2.584Z" fill="#fff" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h48v48H0z" /></clipPath></defs></svg>
                        <div className="mb-2 text-2xl font-semibold text-ar-purple">{t('NoResultsFound')}</div>
                        <div className="text-base font-normal">{t('WeCouldntSearch')}<br />{t('TrySearchAgain')} </div>
                    </div>
                    }
                </div>
            }
        </div>
    </>
}

export default EventLineUp;