import mmddyyyyTimeFormat from '../../../../utils/mmddyyyyTimeFormat';
import { useTranslation } from 'react-i18next';
import { Button } from 'primereact/button';

const MobileView = (props: any) => {
    const { t } = useTranslation();
    const borderBottom = {
        borderBottom: "0.5px solid var(--filter-tag-stroke-grey, #919191)"
    };

    return <div className=' mt-7'>
        {props?.data?.length > 0 ? props?.data.map((res: any, index: number) => {
            return <div key={res?.order_id + index} className=' mb-5'>
                <div className='flex items-center'>
                    <label className='md:min-w-[120px] md:max-w-[120px] min-w-[72px] max-w-[72px] w-full mr-[15px] text-sm font-normal text-[#CACACA]'>{t('OrderNo')}</label>
                    <div className='w-full text-sm font-normal text-white px-[15px] py-[20px] custom-border break-words'>
                        {res?.order_id}
                    </div>
                </div>
                <div className='flex items-center'>
                    <label className='md:min-w-[120px] md:max-w-[120px] min-w-[72px] max-w-[72px] w-full mr-[15px] text-sm font-normal text-[#CACACA]'>{t('OrderDate')}</label>
                    {res?.order_date && <div className='w-[100%] text-sm font-normal text-white px-[15px] py-[20px] custom-border'>
                        {mmddyyyyTimeFormat(res?.order_date)}
                    </div>}
                </div>
                <div className='flex items-center'>
                    <label className='md:min-w-[120px] md:max-w-[120px] min-w-[72px] max-w-[72px] w-full mr-[15px] text-sm font-normal text-[#CACACA]'>{t('PurchaseInformation')}</label>
                    <div className='w-[100%] text-sm font-normal text-white px-[15px] py-[20px] custom-border'>
                        {res?.event_name}
                        <div>{res?.event_venue?.name}</div>
                    </div>
                </div>
                <div className='flex items-center'>
                    <label className='md:min-w-[120px] md:max-w-[120px] min-w-[72px] max-w-[72px] w-full mr-[15px] text-sm font-normal text-[#CACACA]'></label>
                    <div className='w-[100%] flex items-center flex-nowrap gap-x-[10px] px-[12px] py-[20px] custom-border' style={borderBottom}>
                        <Button
                            label={t('ViewReceipt')}
                            className="bg-[#6650F2] disabled:bg-[#4B4B4B] rounded-[20px] px-[12px] py-[5px] text-[14px] text-[#FFFFFF] disabled:text-[#919191] font-normal font-['Nunito']"
                            onClick={() => props.navigateMyOrder(res?._id)}
                        />
                        <Button
                            label={t('ViewTicket')}
                            className="bg-[#6650F2] disabled:bg-[#4B4B4B] rounded-[20px] px-[12px] py-[5px] text-[14px] text-[#FFFFFF] disabled:text-[#919191] font-[400] font-['Nunito']"
                            // onClick={() => props.navigateMyTicketDetails(res?._id)}
                            disabled
                        />
                    </div>
                </div>

            </div>
        }) : <>
            <div className='flex items-center'>
                <label className='md:min-w-[120px] md:max-w-[120px] min-w-[72px] max-w-[72px] w-full mr-[15px] text-sm font-normal text-[#CACACA]'>{t('OrderNo')}</label>
                <div className='w-full text-sm font-normal text-white px-[15px] py-[20px] custom-border break-words'>
                    {t('NoResultsFound')}
                </div>
            </div>
            <div className='flex items-center'>
                <label className='md:min-w-[120px] md:max-w-[120px] min-w-[72px] max-w-[72px] w-full mr-[15px] text-sm font-normal text-[#CACACA]'>{t('OrderDate')}</label>
                <div className='w-[100%] text-sm font-normal text-white px-[15px] py-[20px] custom-border'>
                    {t('NoResultsFound')}
                </div>
            </div>
            <div className='flex items-center'>
                <label className='md:min-w-[120px] md:max-w-[120px] min-w-[72px] max-w-[72px] w-full mr-[15px] text-sm font-normal text-[#CACACA]'>{t('PurchaseInformation')}</label>
                <div className='w-[100%] text-sm font-normal text-white px-[15px] py-[20px] custom-border' style={borderBottom}>
                    {t('NoResultsFound')}
                </div>
            </div>
        </>}
    </div >
}

export default MobileView;