import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import {setCountry, setLocales, setLoggedIn} from "../../redux/slices/commonSlice";
import { get } from "../../utils/AxiosRequest";
import { countries } from "../../utils/apiRequestList/authServices";
import Footer from "../Footer";
import HeaderNavigation from "../HeaderNavigation";
import {releaseCheckoutTickets} from "../../utils/apiRequestList/ticketServices";
import {resetTicketCheckout} from "../../redux/slices/ticketSlice";
import useAuth from "../../hooks/useAuth";

const MainLayout = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { loading } = useAppSelector((state) => state.common);
  const { ticketCheckout }: any = useAppSelector((state) => state.ticket);
  const { accessToken } = useAuth()

  const getData = async () => {
    try {
      const [
        countriesResponse,
        localesResponse,
      ] = await Promise.all([
        countries(),
        get(`/locales`),
      ])
      const countriesMsg: any = []
      if (countriesResponse?.data?.data) {
        countriesResponse.data.data.forEach((item: any) => {
          let singleCountry = {
            value: item._id,
            name: item.dial_code,
            country: item.name,
            searchText: `${item.dial_code} ${item.name}`,
            code: item.code,
            flag: `https://flagcdn.com/${item.code.toLowerCase()}.svg`
          }
          countriesMsg.push(singleCountry);
        });
      }
      dispatch(setCountry(countriesMsg));
      dispatch(setLocales(localesResponse?.data?.data?.available_languages))
    }
    catch (error) {}
  }

  useEffect(() => {
    getData();
    dispatch(setLoggedIn(false))
    setTimeout(() => {
      document.body.classList.remove('overflow-hidden');
    }, 333)
  }, []);

  useEffect(() => {
    if (!location?.pathname.includes('/checkout') && ticketCheckout && accessToken) {
      dispatch(resetTicketCheckout())
      releaseCheckoutTickets()
    }

    window.scrollTo(0, 0);
  }, [location]);

  return (
    <div>
      <div className="max-w-[1920px] mx-auto bg-ar-black text-white flex min-h-[100vh] flex-col">
        <HeaderNavigation />
        <div id='calendar-portal' className='custom-datepicker z-30'></div>
        <div className={`${loading ? 'flex' : 'hidden'} justify-center items-center w-full h-full fixed z-50 top-0 left-0 bg-black opacity-40`} onClick={(e) => e.stopPropagation()}>
          <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
        </div>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default MainLayout;
