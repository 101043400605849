import { format } from 'date-fns';
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import NoIMage from "../../assets/images/no-image.png";
import DangerouslySetInnerHTML from "../../components/DangerouslySetInnerHTML";
import { artistBySlug } from "../../utils/apiRequestList/artistServices";
import Player from "./Player";

export default function ArtistDetail() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { redirectPath } = Object.fromEntries(searchParams.entries());
  const navigate = useNavigate();
  const [artistData, setArtistData] = useState<any>();
  const params = useParams();
  const SHOW_PLAYER = useMemo(
    () =>
      process.env.REACT_APP_SHOW_MUSIC_PLAYER &&
      parseInt(process.env.REACT_APP_SHOW_MUSIC_PLAYER, 10),
    []
  );

  const fetchData = async () => {
    const response: any = await artistBySlug(params?.artiste_id || "");
    if (response?.data?.data) {
      setArtistData(response?.data?.data);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="">
      <Helmet>
        <title>{`${artistData?.name} Featured events | Aria`}</title>
        <meta property="og:title" content={artistData?.name} />
        <meta property="og:description" content={`Learn about upcoming performances and delve into ${artistData?.name}'s musical journey.`} />
        <meta property="og:type" content="website" />
      </Helmet>

      <div
        className="!bg-transparent lg:px-4 xl:px-0 relative"
        style={{
          background:
            "linear-gradient(to bottom, #6650F2 0%, #3D337E 39.58%, #211F2E 100%)",
        }}
      // className="bg-gradient-to-b from-indigo-600 via-purple-700 to-gray-900"
      >
        {/* <div className="max-w-[1015px] px-5 pt-3 mx-auto absolute z-[1] lg:static">
          <BreadCrumb detailInfo={{ label: artistData?.name }} color="#fff" />
        </div> */}
        <div
          className="absolute text-xs font-bold items-center justify-start left-5 z-[1]  max-w-[1015px] px-5 pt-4 mx-auto lg:static"
        >
          <span className="flex cursor-pointer items-center w-fit" onClick={() => {
            navigate(redirectPath ?? -1);
          }}>
            <svg className=" mr-1" width="8" height="11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6.875.75 2.125 5.5l4.75 4.75" stroke="#fff" strokeWidth="2" /></svg> {t('Back')}
          </span>
        </div>
        <div className="lg:flex gap-[69px] max-w-[1080px] mx-auto lg:pt-8 pb-[70px]">
          <div className="w-full h-[375px] basis-[491px] lg:h-[491px] aspect-square relative grow-0 shrink-0">
            <div className="relative lg:rounded-[20px] overflow-hidden h-full w-full">
              <img
                src={artistData?.profilePicture || NoIMage}
                alt={artistData?.name}
                className={`object-cover object-top w-full h-full bg-indigo-300 lg:aspect-square ${artistData?.profilePicture ? '' : 'border border-ar-purple'}`}
              />

              <div
                className=" h-[148px] w-full absolute bottom-0 left-0"
                style={{
                  background:
                    "linear-gradient(180deg, rgba(95, 75, 224, 0) 0%, #211F2E 100%)",
                }}
              />
              <div className="absolute bottom-[41px] left-0 w-full text-center text-white lg:hidden">
                <div className="text-sm font-bold">
                  {artistData?.genreList[0]?.name}
                </div>
                <h3 className="text-[26px] font-semibold">
                  {artistData?.name}
                </h3>
              </div>
              {SHOW_PLAYER ? (
                <Player
                  artist={artistData?.name}
                  song={t("SongName")}
                  className="hidden absolute bottom-[26px] w-[309px] left-1/2 -translate-x-1/2 lg:flex"
                />
              ) : null}
            </div>
          </div>
          <section className="text-white bg-ar-black lg:bg-transparent shrink grow">
            <div className="relative px-8 lg:px-0">
              {SHOW_PLAYER ? (
                <Player
                  artist={artistData?.name}
                  song={t("SongName")}
                  className="lg:hidden"
                />
              ) : null}

              <div className="hidden lg:block">
                <div className="text-sm font-bold">
                  {artistData?.genreList[0]?.name}
                </div>

                <h3 className="text-[26px] font-semibold">
                  {artistData?.name}
                </h3>
              </div>

              <div className="pt-8 mb-5 lg:pt-5">
                <DangerouslySetInnerHTML
                  classNames={'break-words editor-formatting'}
                  htmlContent={artistData?.biography}
                />
              </div>
            </div>

            {artistData?.eventData.length > 0 ?
              <>
                <h3 className="font-bold mb-5 text-ar-purple px-8 md:px-0">
                  {t("FeaturedEvents")}
                </h3>
                {artistData?.eventData
                  .map((event: any, i: number) => (
                    <Link
                      to={`/events/${event.slug}`}
                      key={i}
                      className={`${i === 0 ? "border-t-[.5px]" : ""
                        } text-white lg:bg-transparent  flex items-center pr-[14px] pl-[14px] md:pr-[24px] border-b-[.5px] border-ar-purple mx-[10px] lg:mx-0 py-3`}
                    >
                      <img
                        src={event.cover_image_desktop || NoIMage}
                        alt=""
                        className={`overflow-hidden rounded-xl w-[60px] h-[60px] mr-[17px] object-cover ${event.cover_image_desktop ? '' : 'border border-ar-purple'}`}
                      />
                      <div className="flex items-center justify-between flex-1">
                        <div className="text-sm">
                          <h4 className="font-bold">{event.translations[0]?.title}</h4>
                          {event?.event_venue_id && (
                            <div>{event?.event_venue_id?.address}, {event?.event_venue_id?.country}</div>
                          )}
                          <div className="text-xs">{format(new Date(event?.start_date), 'dd MMM yyyy')} - {format(new Date(event?.end_date), 'dd MMM yyyy')}</div>
                        </div>
                        <div className=" min-w-[24px] min-h-[24px]">
                          <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m9 18 6-6-6-6" stroke="#9B8BFF" strokeWidth="2" /></svg>
                        </div>
                      </div>
                    </Link>
                  ))}
              </>
              :
              null
            }
          </section>
        </div>
      </div >

      <div className="pt-12 pb-16 text-center lg:text-right bg-ar-black">
        <div className="lg:max-w-[1080px] mx-auto lg:px-4 xl:px-0">
          <Link
            to="/artistes"
            className="py-[13px] px-[19px] bg-ar-primary border-ar-primary border rounded-full hover:opacity-60 transition-all inline-flex text-white"
          >
            {t("ViewArtistesDirectoryButton")}
          </Link>
        </div>
      </div>
    </div >
  );
}
