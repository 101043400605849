import FailedIcon from "../../../assets/images/icons/failed_icon.png";
import { useTranslation } from "react-i18next";

const FailedCheckout = (props: any) => {
    const { t } = useTranslation();
    return <>
        <div className="w-full lg:w-[708px] px-12 py-16 text-center h-full lg:h-auto">
            <img className="w-[78px] h-[78px] m-auto" src={FailedIcon} alt="success icon" />
            <div className=" text-2xl font-semibold mt-8 md:px-20">{t('TransactionFailed')}</div>
            <button onClick={() => props.reCheckout(false)} className=" mt-4 rounded-full px-5 py-3 bg-ar-primary text-sm font-normal">{t('TryAgain')}</button>
            <div className=" mt-4 text-base font-normal flex items-center justify-center">
                <span className="ml-2 px-14 text-[#CACACA]">{t('WeTriedToProcess')}</span>
            </div>
        </div>
    </>
}

export default FailedCheckout