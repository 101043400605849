import PageContainer from "../../components/PageContainer";
import DangerouslySetInnerHTML from "../../components/DangerouslySetInnerHTML";
import React, {useState, useEffect} from "react";
// import { Helmet } from "react-helmet-async";
import {privacyPolicyData} from "../../utils/apiRequestList/generalServices";

const AboutUs = () => {

    return (
        <>
            <div className="bg-[url('../assets/images/tnc_bg-mobile.png')]  md:bg-[url('../assets/images/tnc_bg.png')] h-[100vh] bg-no-repeat bg-cover bg-center w-full md:h-[478px] top-[45px] md:top-0 absolute md:relative">
            </div>
            <PageContainer customTop={'mt-[90px] top-0 md:mt-[-220px]'}>
                <div className="p-5 md:p-20">
                    <h2 className="text-[28px] mb-[22px]">Discover | Book | Party | Grow with Aria</h2>
                    <p className="mb-[20px]">Aria is your ultimate destination for discovering, booking, and partying at the most exciting festivals and events across Asia. We're dedicated to putting the power back in hands of fans!
                        Our platform is more than just a means to secure entry; it is also a vibrant community where festival-goers come together to celebrate life, music, and culture.</p>
                    <p className="mb-[20px]">We understand the importance of fair and secure ticketing, which is why we're committed to implementing robust anti-scalping measures. With Aria, you can rest assured that your ticket purchase supports the integrity of the event and helps create a safer, more inclusive environment for all attendees.</p>
                </div>
            </PageContainer>
        </>
    )
}

export default AboutUs;