import React from "react";
import SuccessIcon from "../../../assets/images/icons/success-icon.png";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DangerouslySetInnerHTML from "../../../components/DangerouslySetInnerHTML";

const Verified = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    return <div>
        <img className="w-[78px] h-[78px] m-auto" src={SuccessIcon} alt="success icon" />
        <div className=" text-2xl font-semibold mt-8">{t("CheersVerified")}</div>
        <button onClick={() => navigate('/')} className=" mt-4 rounded-full px-5 py-3 bg-ar-primary text-sm font-normal">{t("MakeSomePlans")}</button>
        <DangerouslySetInnerHTML
            classNames={'mt-4 text-base font-normal editor-formatting'}
            htmlContent={t('ItsGoTime')}
        />
    </div>
}

export default Verified;
