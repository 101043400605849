import { getI18n } from "react-i18next";
import i18n from "../languages/i18n";

// eslint-disable-next-line import/no-anonymous-default-export
export default function (inputDateString: any) {
    const { t } = getI18n();
    const inputDate = new Date(inputDateString);

    const startDay = inputDate.getDate().toString().padStart(2, '0');
    const startMonth = inputDate.toLocaleString('en-GB', { month: 'short' });
    const time = inputDate.toLocaleString('en-GB', {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
    });
    const year = inputDate.getFullYear();
    const dayUnit = i18n.language.includes("zh") ? '日' : i18n.language.includes("ja") ? '日' : ''
    const yearUnit = i18n.language.includes("zh") ? '年' : i18n.language.includes("ja") ? '年' : ''

    if (i18n.language.includes("zh") || i18n.language.includes("ja")) {
        return `${t(startMonth)} ${startDay.replace(',', '')}${dayUnit}, ${year}${yearUnit} ${time}`;
    } else {
        return `${t(startMonth)} ${startDay.replace(',', '')}, ${year} ${time}`;
    }
}