import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import { addDays, setDay, isSaturday, isSunday, startOfDay } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";
import { setStartDate, setEndDate } from "../redux/slices/eventFilterSlice";
import { useAppDispatch } from "../hooks/useStore";
import i18n from "../languages/i18n";
// manually import/add languages here if there is more in the future
import zhCN from "date-fns/locale/zh-CN";
import ja from "date-fns/locale/ja";

const Calendar = (props: any) => {
    const dispatch = useAppDispatch();
    const onChange = (dates: any) => {
        const today = new Date();
        if (dates === 'weekend') {
            let nextSaturday = setDay(today, 6);
            let nextSunday = addDays(nextSaturday, 1);
            if (isSaturday(today)) {
                nextSaturday = addDays(nextSaturday, 0);
                nextSunday = addDays(nextSunday, 0);
            }
            if (isSunday(today)) {
                nextSaturday = addDays(nextSaturday, 0);
                nextSunday = addDays(nextSunday, 0);
            }
            props.setTempStartDate(null);
            dispatch(setStartDate(nextSaturday));
            dispatch(setEndDate(nextSunday));
        } else if (dates === 'tomorrow') {
            const tomorrow = startOfDay(addDays(today, 1));
            props.setTempStartDate(null);
            dispatch(setStartDate(tomorrow));
            dispatch(setEndDate(addDays(tomorrow, 0)));
            // props.setEndDate(tomorrow);
        } else if (dates === 'today') {
            props.setTempStartDate(null);
            dispatch(setStartDate(today));
            dispatch(setEndDate(addDays(today, 0)));
            // props.setEndDate(today);
        } else if (dates instanceof Array && dates.length === 2) {
            const [start, end] = dates;
            props.setCalendarSelectedDate('')
            props.setTempStartDate(start);
            dispatch(setStartDate(start));
            dispatch(setEndDate(end));
        }
    };
    const today = new Date();

    useEffect(() => {
        onChange(props.calendarSelectedDate)
    }, [props.calendarSelectedDate])

    return <div className="custom-datepicker">
        <DatePicker
            selected={props.tempStartDate ? props.tempStartDate : props.startDate}
            onChange={onChange}
            startDate={props.tempStartDate ? props.tempStartDate : props.startDate}
            endDate={props.endDate}
            selectsRange
            minDate={today}
            inline
            showDisabledMonthNavigation
            // manually add languages here if there is more in the future
            locale={i18n.language.includes("zh") ? zhCN : i18n.language.includes("ja") ? ja : ''}
        />
    </div>
}

export default Calendar;