import React from "react";
import PageContainer from "../../components/PageContainer";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

const NotFound = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();

    return <>
        <div className='relative'>
            <div className="bg-[url('../assets/images/page-not-found-bg.png')] h-[100vh] bg-no-repeat bg-cover bg-center w-full  md:h-[565px] relative">
            </div>
            <div className='absolute md:relative w-full top-[50%] translate-y-[-50%] md:top-[0px] md:translate-y-[0px]'>
                <PageContainer
                    maxWidth={'max-w-[400px] md:max-w-[893px] px-[15px]'}
                    customTop={'mt-[0px] top-0 md:mt-[-189px]'}
                    mobileTransparent
                >
                    <div className="py-[50px] px-[26px] md:pt-[83px] md:pb-[131px] flex flex-col items-center">
                        <div className='text-center md:max-w-[320px]'>
                            <h2 className='text-ar-purple text-[26px] md:text-[34px] font-bold leading-[42px]'>{t('PageNotFound')}</h2>
                            <h3 className='text-[20px] md:text-[26px] font-semibold'>{t('NotFoundTitle')}</h3>
                            <p className='mb-[20px] text-sm md:text-[16px]'>{t('NotFoundSubtitle')}</p>
                            <button onClick={()=> navigate('/')} className="mb-[20px] rounded-full px-5 py-3 bg-ar-primary text-sm font-normal">
                                {t('NotFoundCTA')}
                            </button>
                            <p className='text-sm md:text-[16px]'>{t('NotFoundCopy')}</p>
                        </div>
                    </div>
                </PageContainer>
            </div>
        </div>
    </>
}

export default NotFound;