import React from "react";
import FailedIcon from "../../../assets/images/icons/failed_icon.png";
import { useTranslation } from "react-i18next";
const FailedVerified = (props: any) => {
    const { t } = useTranslation();
    return <div>
        <img className="w-[78px] h-[78px] m-auto" src={FailedIcon} alt="success icon" />
        <div className=" text-2xl font-semibold mt-8">{t("LinkIsExpired")}</div>
        <button onClick={() => props.resendVerificationHandle(true, 'failed')} className=" mt-4 rounded-full px-5 py-3 bg-ar-primary text-sm font-normal">{t('ResendVerificaion')}</button>
        <a href={`mailto:${process.env.REACT_APP_CUSTOMER_SERVICE_EMAIL}`} className=" mt-4 text-base font-normal flex items-center justify-center no-underline">
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><ellipse cx="12" cy="12" rx="7" ry="8" fill="#fff" /><path d="M11.95 18c.35 0 .646-.12.888-.363.241-.241.362-.537.362-.887s-.12-.646-.362-.887a1.207 1.207 0 0 0-.888-.363c-.35 0-.646.12-.887.363a1.207 1.207 0 0 0-.363.887c0 .35.12.646.363.887.241.242.537.363.887.363Zm-.9-3.85h1.85c0-.55.063-.983.188-1.3.125-.317.479-.75 1.062-1.3a7.494 7.494 0 0 0 1.025-1.238c.25-.391.375-.862.375-1.412 0-.933-.342-1.65-1.025-2.15-.683-.5-1.492-.75-2.425-.75-.95 0-1.72.25-2.313.75-.591.5-1.004 1.1-1.237 1.8l1.65.65c.083-.3.27-.625.563-.975.291-.35.737-.525 1.337-.525.533 0 .933.146 1.2.437.267.292.4.613.4.963 0 .333-.1.646-.3.938-.2.291-.45.562-.75.812-.733.65-1.183 1.142-1.35 1.475-.167.333-.25.942-.25 1.825ZM12 22a9.738 9.738 0 0 1-3.9-.788 10.099 10.099 0 0 1-3.175-2.137c-.9-.9-1.612-1.958-2.137-3.175A9.738 9.738 0 0 1 2 12c0-1.383.263-2.683.788-3.9a10.099 10.099 0 0 1 2.137-3.175c.9-.9 1.958-1.612 3.175-2.137A9.738 9.738 0 0 1 12 2c1.383 0 2.683.263 3.9.788a10.098 10.098 0 0 1 3.175 2.137c.9.9 1.613 1.958 2.137 3.175A9.738 9.738 0 0 1 22 12a9.738 9.738 0 0 1-.788 3.9 10.098 10.098 0 0 1-2.137 3.175c-.9.9-1.958 1.613-3.175 2.137A9.738 9.738 0 0 1 12 22Z" fill="#6650F2" /></svg>
            <span className="ml-2">{t("NeedHelp")}</span>
        </a>
    </div>
}

export default FailedVerified;
