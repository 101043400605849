export default function ChevronRight({ className }: { className: string }) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M9 18L15 12L9 6" stroke="currentColor" strokeWidth={2} />
    </svg>
  );
}
