import { FormikProps, FormikValues, useFormik } from "formik";
import React, { ReactNode, useEffect } from "react";
import * as Yup from "yup";
import { useAppDispatch } from "../../hooks/useStore";
import { setCSRF } from "../../redux/slices/authSlice";
import { get } from "../../utils/AxiosRequest";

export interface FormWrapperProps {
    initialValues: object,
    validationSchema: Yup.AnyObjectSchema,
    formStyles?: object,
    formInputs: (formikProps: FormikProps<FormikValues>) => ReactNode,
    submitButton?: ReactNode,
    onSubmit: (values: any) => void,
    toggleHoneyPot?: boolean,
}

const FormWrapper = (props: FormWrapperProps) => {
    const dispatch = useAppDispatch()
    const formik = useFormik({
        initialValues: Object.assign(props?.toggleHoneyPot ? {
            firstNamePot: '',
            lastNamePot: '',
            countryPot: '',
            statePot: '',
            cityPot: '',
        } : {}, props.initialValues),
        validationSchema: props?.validationSchema?.shape({
            firstNamePot: Yup.string().nullable().length(0, 'Bot is not Allowed'),
            lastNamePot: Yup.string().nullable().length(0, 'Bot is not Allowed'),
            countryPot: Yup.string().nullable().length(0, 'Bot is not Allowed'),
            statePot: Yup.string().nullable().length(0, 'Bot is not Allowed'),
            cityPot: Yup.string().nullable().length(0, 'Bot is not Allowed'),
        }),
        onSubmit: (values) => {
            props?.onSubmit(values)
        }
    })

    const getData = async () => {
        try {
            const { data } = await get(`/form`);
            if (data?.success && data?.data) {
                dispatch(setCSRF({csrf: data.data}));
            }
        }
        catch (error) {
            dispatch(setCSRF({csrf: null}));
        }
    }

    useEffect(() => {
        getData();
    }, []);

    return <>
        {
            formik && <form style={props?.formStyles} onSubmit={formik?.handleSubmit}>
                {props.formInputs(formik)}

                {
                    props?.toggleHoneyPot &&
                    <div style={{
                        opacity: 0,
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: 0,
                        width: 0,
                        zIndex: -1,
                    }}>
                        <input type='text'
                               onChange={formik?.handleChange} onBlur={formik?.handleBlur}
                                value={formik.values?.firstNamePot} name="firstNamePot" id="firstNamePot" />
                        <input type='text'
                               onChange={formik?.handleChange} onBlur={formik?.handleBlur}
                                value={formik.values?.lastNamePot} name="lastNamePot" id="lastNamePot" />
                        <input type='text'
                               onChange={formik?.handleChange} onBlur={formik?.handleBlur}
                                value={formik.values?.countryPot} name="countryPot" id="countryPot" />
                        <input type='text'
                               onChange={formik?.handleChange} onBlur={formik?.handleBlur}
                                value={formik.values?.statePot} name="statePot" id="statePot" />
                        <input type='text'
                               onChange={formik?.handleChange} onBlur={formik?.handleBlur}
                                value={formik.values?.cityPot} name="cityPot" id="cityPot" />
                    </div>
                }

                {props?.submitButton || <button type='submit' className="mt-4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Submit
                </button>}
            </form>
        }
    </>
}

export default FormWrapper;