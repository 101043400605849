export default function Search({ className = "text-white" }: { className?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      className={className}
    >
      <path
        d="M7.99472 15.9894C5.76223 15.9894 3.87166 15.2151 2.32301 13.6665C0.774336 12.1178 0 10.2272 0 7.99472C0 5.76223 0.774336 3.87165 2.32301 2.32298C3.87166 0.774325 5.76223 0 7.99472 0C10.2272 0 12.1178 0.774325 13.6665 2.32298C15.2151 3.87165 15.9895 5.76223 15.9895 7.99472C15.9895 8.92828 15.8328 9.81993 15.5193 10.6697C15.2059 11.5194 14.7877 12.2586 14.2648 12.8871L19.7221 17.8745C19.9031 18.0555 19.9957 18.283 19.9999 18.5571C20.0041 18.8311 19.9115 19.0628 19.7221 19.2522C19.5327 19.4416 19.3031 19.5362 19.0332 19.5362C18.7634 19.5362 18.5338 19.4416 18.3444 19.2522L12.8871 14.2648C12.2334 14.8045 11.4817 15.2268 10.632 15.5319C9.78222 15.8369 8.90313 15.9894 7.99472 15.9894ZM7.99472 14.0285C9.67916 14.0285 11.1059 13.444 12.2749 12.2749C13.444 11.1059 14.0285 9.67916 14.0285 7.99472C14.0285 6.31028 13.444 4.88354 12.2749 3.71449C11.1059 2.54544 9.67916 1.96091 7.99472 1.96091C6.31028 1.96091 4.88354 2.54544 3.71449 3.71449C2.54546 4.88354 1.96095 6.31028 1.96095 7.99472C1.96095 9.67916 2.54546 11.1059 3.71449 12.2749C4.88354 13.444 6.31028 14.0285 7.99472 14.0285Z"
        fill="currentColor"
      />
    </svg>
  );
}
