import PageContainer from "../../components/PageContainer";
import DangerouslySetInnerHTML from "../../components/DangerouslySetInnerHTML";
import React, {useState, useEffect} from "react";
// import { Helmet } from "react-helmet-async";
import {privacyPolicyData} from "../../utils/apiRequestList/generalServices";

const PrivacyPolicy = () => {
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        const fetchData = async () => {
            const response = await privacyPolicyData();
            setData(response?.data?.data)
        }
        fetchData()
    }, []);

    return (
        <>
            {/* <Helmet>
                <title>Privacy Policy | Aria</title>
                <meta property="og:title" content="Privacy policy | Aria" />
                <meta property="og:description" content="Navigate Aria's rulebook and dance through the terms. Groove responsibly with clarity on how to make the most of your music journey." />
                <meta property="og:type" content="website" />
            </Helmet> */}
            <div className="bg-[url('../assets/images/tnc_bg-mobile.png')]  md:bg-[url('../assets/images/tnc_bg.png')] h-[100vh] bg-no-repeat bg-cover bg-center w-full md:h-[478px] top-[45px] md:top-0 absolute md:relative">
            </div>
            <PageContainer customTop={'mt-[90px] top-0 md:mt-[-220px]'}>
                {
                    data &&
                    <DangerouslySetInnerHTML
                        classNames={'p-5 md:p-12 tnc-format'}
                        htmlContent={data?.content ?? ''}
                    />
                }
            </PageContainer>
        </>
    )
}

export default PrivacyPolicy;