import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../../components/Form/loginForm";

const Login = () => {
    const navigate = useNavigate();
    const [isLogin, setIsLogin] = useState(false);
    const [clickCount, setClickCount] = useState(0);
    const [capthchaStatus, setCapthchaStatus] = useState(false);

    const submitLogin = async () => {
        if (clickCount <= 3) {
            if (!capthchaStatus) {
                setIsLogin(true);
                // const responseLogin = await login({
                //     "email": "",
                //     "password": ""
                // })
                // if (responseLogin?.status) {
                //     toast.success("Login Success", {
                //         position: toast.POSITION.TOP_CENTER,
                //         autoClose: 2000
                //     });
                //     navigate("/");
                // } else {
                //     toast.error("Sorry please come back in a few minutes", {
                //         position: toast.POSITION.TOP_CENTER,
                //         autoClose: 2000
                //     });
                // }
            }
        } else {
            if (!capthchaStatus) {

            } else {
                setIsLogin(true);
                // navigate("/");
                setCapthchaStatus(false);
            }
        }

        setClickCount(clickCount + 1);
    };

    return <div className="dark:text-white">
        <div className="flex flex-col w-full items-center transition duration-1000 ease-out">
            <div className='flex flex-col items-center justify-center'>
                <LoginForm />
            </div>

            <p className='text-blue-400 mt-4 text-sm'>Don't have an account?</p>
            <p className='text-blue-400 mb-4 text-sm font-medium cursor-pointer' onClick={() => setIsLogin(false)}>Create a New Account?</p>
        </div>
    </div>
}

export default Login;