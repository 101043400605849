import { useTranslation } from "react-i18next";
import BookingItems from "./BookingItems";

const Items = (props: any) => {
    const { t } = useTranslation()
    return <>
        <div className="text-[16px] text-[#CACACA] font-[400] font-['Nunito'] mb-[13px] ml-[9px]">{t('Item')}</div>

        <div className="py-[16px] px-[8px] md:py-[20px] md:px-[20px] rounded-[12px] border-solid border-[#9B8BFF] border-[0.8px] flex flex-col gap-y-[20px] mb-[20px]">
           {props && <BookingItems data={props} />} 
        </div>
    </>
}

export default Items;