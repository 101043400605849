import React, { useEffect, useState, useRef } from 'react';
import { FormikProps, FormikValues } from "formik";
import * as Yup from 'yup';
import FormWrapper from "../../components/Form/FormWrapper";
import ErrorMessage from "../../components/Form/errorMessage";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import { Dropdown } from "primereact/dropdown";
import { InputSwitch } from "primereact/inputswitch";
import Switch from "react-switch";
import { countries, resendEmail, verifyOTP } from "../../utils/apiRequestList/authServices";
import {
    setProfile,
    updateMobile,
    changeEmail,
    verifyNumberOtp,
    subscriptionUpdate
} from "../../utils/apiRequestList/profileService";
import moment from "moment";
import CheckCircle from '../../assets/images/icons/check-circle.png';
import SentVerification from "../EmailVerificationPage/components/SentVerification";
import Modal from "../../components/Modal";
import { useSelector } from "react-redux";
import { getCurrentLocation } from "../../redux/slices/locationSlice";
import OtpInput from "react-otp-input";
import { Disclosure, Transition } from "@headlessui/react";
import CallCodeInput from "../../components/Form/CallCodeInput";
import { InputMask } from "@react-input/mask";
import { useAppDispatch } from "../../hooks/useStore";
import { setLoading } from "../../redux/slices/commonSlice";
import i18n from "../../languages/i18n";
import { Portal } from "react-overlays";
// manually import/add languages calendar here if there is more in the future
import zhCN from "date-fns/locale/zh-CN";
import ja from "date-fns/locale/ja";
import {setProfileInfo, setUpdateEmail} from "../../redux/slices/authSlice";
import Select from "react-dropdown-select";

const ProfilePageForm = (props: any) => {
    const dispatch = useAppDispatch();
    const currentLocation = useSelector(getCurrentLocation)
    const profileInfo = props.profileInfo;
    const { t } = useTranslation();
    const [editInfo, setInfo] = useState(false);
    const [editEmailStatus, setEditEmailStatus] = useState<boolean>(false)
    const [editMobileStatus, setEditMobileStatus] = useState<boolean>(false);
    const [newEmail, setNewEmail] = useState('');
    const [verificationScreen, setVerificationScreen] = useState(false);
    const [seconds, setSeconds] = useState<any>(null);
    const [mobileSeconds, setMobileSeconds] = useState<any>(null);
    const [sentEmail, setSentEmail] = useState(false);
    const [otpScreen, setOtpScreen] = useState(false)
    const [submitedValues, setSubmitedValues] = useState<any>({ dialCode: '', phoneNumber: '' });
    const [otp, setOtp] = useState('');
    const [otpVerify, setOtpVerify] = useState<boolean>();
    const [attemptsExceeded2, setAttemptsExceeded2] = useState('');
    const [attemptsExceeded, setAttemptsExceeded] = useState('');
    const [countriesOptions, setCountriesOptions] = useState<any>()
    const [countryName, setCountryName] = useState<any>()

    const [subscriptions, setSubscriptions] = useState<any>({
        ariaOrganisationSubscription: profileInfo?.email_updates_aria_organisation
    });

    const initialValues = {
        firstName: profileInfo?.first_name ?? '',
        lastName: profileInfo?.last_name ?? '',
        dateOfBirth: profileInfo?.date_of_birth ? new Date(profileInfo?.date_of_birth) : '', //moment(profileInfo?.date_of_birth).format('DD/MM/YYYY'),
        countryId: profileInfo?.country_id?._id ?? '',
    }
    const ProfileSchema = Yup.object({
        firstName: Yup.string().required(t('MandatoryField')),
        lastName: Yup.string().required(t('MandatoryField')),
        dateOfBirth: Yup.string().required(t('MandatoryField')),
        countryId: Yup.string().required(t('MandatoryField'))
    });

    const emailInitialValues = {
        emailAddress: '',
    }
    const EmailSchema = Yup.object({
        emailAddress: Yup.string().email(t('InvalidEmailFormat')).required(t('MandatoryField')),
    })

    const mobileInitialValues = {
        newCallCode: '',
        newPhoneNumber: profileInfo?.contact_no ?? null,
    }
    const mobileSchema = Yup.object({
        newCallCode: Yup.string().required(t('MandatoryField')),
        newPhoneNumber: Yup.string().required(t('MandatoryField')),
    })

    const getCountries = async () => {
        try {
            const response:any = await countries();
            if (response?.data?.success){
                setCountriesOptions(response?.data?.data)
            }
        }catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        getCountries();
    }, []);

    const CalendarContainer = ({ children }: any) => {
        const el = document.getElementById("calendar-portal");
        return <Portal container={el}>{children}</Portal>;
    };

    useEffect(() => {
        if (seconds !== null) {
            const interval = setInterval(() => {
                if (seconds <= 0) {
                    clearInterval(interval);
                    setSentEmail(false)
                } else {
                    setSeconds((prevSeconds: any) => prevSeconds - 1);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [seconds]);

    useEffect(() => {
        if (mobileSeconds !== null) {
            const interval = setInterval(() => {
                if (mobileSeconds <= 0) {
                    clearInterval(interval);
                    setSentEmail(false)
                } else {
                    setMobileSeconds((prevSeconds: any) => prevSeconds - 1);
                }
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [mobileSeconds]);


    const isFirstRender = useRef(true) // prevent for initial load
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        handleSubcriptions();
    }, [subscriptions]);

    const handleSubcriptions = async () => {
        dispatch(setLoading(true));
        try {
            const response = await subscriptionUpdate({
                // emailSubscriptionAria: subscriptions.emailSubscriptionAria,
                // emailSubscriptionOrganisation: subscriptions.emailSubscriptionOrganisation,
                emailSubscriptionAriaOrganisation: subscriptions.ariaOrganisationSubscription
            })
            dispatch(setLoading(false));
        } catch (e) {
            console.log(e)
            dispatch(setLoading(false));
        }
    }

    const updateMobileNo = async (values: any) => {
        dispatch(setLoading(true))
        setMobileSeconds(process.env.REACT_APP_OTP_EXPIRY_DURATION);
        try {
            const response = await updateMobile({
                newCallCode: values.newCallCode,
                newPhoneNumber: values.newPhoneNumber,
            })
            if (response?.data?.success) {
                setOtpScreen(true);
                setSubmitedValues({ ...submitedValues, dialCode: values.newCallCode, phoneNumber: values.newPhoneNumber })
            }
            dispatch(setLoading(false))
        } catch (e) {
            console.log(e)
            dispatch(setLoading(false))
        }
    }

    const resendMobileOtp = async () => {
        try {
            const response = await updateMobile({
                newCallCode: submitedValues.dialCode,
                newPhoneNumber: submitedValues.phoneNumber,
            })
            if (response?.data?.success) {
                setMobileSeconds(process.env.REACT_APP_OTP_EXPIRY_DURATION);
            }
        } catch (e) {
            console.log(e)
            // @ts-ignore
            setAttemptsExceeded(error?.response?.data?.message)
        }
    }

    const sendVerificationCode = async (value: any) => {
        try {
            const response = await changeEmail({
                newEmailAddress: value.emailAddress
            })
            if (response?.data?.success) {
                setNewEmail(value.emailAddress);
                setVerificationScreen(true)
                //@ts-ignore
                dispatch(setUpdateEmail(value.emailAddress))
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleSubmit = async (values: any) => {
        try {
            const response: any = await setProfile(values)
            if (response?.data?.success) {
                props?.refresh();
                setInfo(false);
                dispatch(setProfileInfo(response?.data?.data))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleEmailVerify = async () => {
        setSentEmail(true);
        setSeconds(process.env.REACT_APP_OTP_EXPIRY_DURATION);
        try {
            const response: any = await resendEmail({
                emailAddress: profileInfo?.email_address
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    const verifyOTPAction = async () => {
        try {
            const response: any = await verifyNumberOtp({
                otpCode: otp,
                phoneNumber: submitedValues.phoneNumber,
                codeNumber: submitedValues.dialCode
            })
            if (response?.data?.success) {
                setOtpVerify(true);
                setOtpScreen(false);
                document.body.classList.remove('overflow-hidden');
                props?.refresh();
                setEditMobileStatus(false);
            }
            setOtp('')
        } catch (error) {
            setOtpVerify(false);
            // @ts-ignore
            setAttemptsExceeded2(error?.response?.data?.message)
            console.log(error)
        }
    };

    const handleBack = () => {
        setOtpScreen(false)
        setOtp('')
        setOtpVerify(true)
        setAttemptsExceeded('')
        setAttemptsExceeded2('')
    }

    useEffect(() => {
        if (otp.length >= 4) {
            verifyOTPAction();
        }
    }, [otp]);

    const formInputs = ({ errors, touched, values, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => (
        <>
            <hr className='my-[25px] border-t border-[#7c7c7c]' />
            <div className='flex justify-between items-center mb-[10px]'>
                <div>{t('PersonalInfo')}</div>
                <div className='cursor-pointer text-sm text-ar-purple' onClick={() => setInfo((current: boolean) => !current)}>
                    {editInfo ? t('Cancel') : t('Edit')}
                </div>
            </div>
            {editInfo ?
                <>
                    <div className='mb-[20px]'>
                        <label htmlFor="firstName" className="leading-6">
                            {t('FirstName')}
                            <span className='text-[#D04EFF]'>*</span>
                        </label>
                        <div className="text-[12px] mt-[-5px] mb-[5px]">Please provide the name on your photo ID for
                            event
                            entry verification.
                        </div>
                        <input type="text" name='firstName' id='firstName'
                               value={values.firstName}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               className={`${(errors.firstName && touched.firstName) && "p-invalid"} block mb-[5px] w-full rounded-md border-0 py-1.5 px-[9px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                               placeholder={t('FirstName')}
                        />
                        {errors.firstName && touched.firstName ? (
                            <ErrorMessage text={errors.firstName}/>
                        ) : null}
                    </div>
                    <div className='mb-[20px]'>
                        <label htmlFor="lastName" className="leading-6">
                            {t('LastName')}
                            <span className='text-[#D04EFF]'>*</span>
                        </label>
                        <div className="text-[12px] mt-[-5px] mb-[5px]">Please provide the name on your photo ID for
                            event
                            entry verification.
                        </div>
                        <input type="text" name='lastName' id='lastName'
                               value={values.lastName}
                               onChange={handleChange}
                               onBlur={handleBlur}
                               className={`${(errors.lastName && touched.lastName) && "p-invalid"} block mb-[5px] w-full rounded-md border-0 py-1.5 px-[9px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                               placeholder={t('LastName')}
                        />
                        {errors.lastName && touched.lastName ? (
                            <ErrorMessage text={errors.lastName}/>
                        ) : null}
                    </div>
                    <div className='mb-[20px]'>
                        <label htmlFor="dateOfBirth" className="leading-6">
                            {t('DateOfBirth')}
                            <span className='text-[#D04EFF]'>*</span>
                        </label>
                        <div className='text-ar-black custom-datepicker dob'>
                            <DatePicker
                                value={values.dateOfBirth}
                                selected={values.dateOfBirth}
                                onChange={val => {
                                    setFieldValue('dateOfBirth', val);
                                }}
                                className={`block ${(errors.dateOfBirth && touched.dateOfBirth) && "p-invalid"} mb-[5px] w-full rounded-md border-0 py-1.5 px-[9px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                placeholderText="DD/MM/YYYY"
                                dateFormat="dd/MM/yyyy"
                                customInput={<InputMask mask="__/__/____" replacement={{_: /\d/}}/>}
                                showYearDropdown
                                dropdownMode="select"
                                popperContainer={CalendarContainer}
                                // manually add languages here if there is more in the future
                                locale={i18n.language.includes("zh") ? zhCN : i18n.language.includes("ja") ? ja : ''}
                            />
                            {errors.dateOfBirth && touched.dateOfBirth ? (
                                <ErrorMessage text={errors.dateOfBirth}/>
                            ) : null}
                        </div>
                    </div>
                    <div className='mb-[20px]'>
                        <label htmlFor="countryId" className="leading-6">
                            Country
                            <span className='text-[#D04EFF]'>*</span>
                        </label>
                        <Select
                            options={countriesOptions}
                            values={[
                                {
                                    _id: profileInfo?.country_id?._id,
                                    name: profileInfo?.country_id?.name
                                }
                            ]}
                            labelField="name"
                            valueField="_id"
                            onChange={(values:any) => {
                                setFieldValue('countryId', values[0]._id)
                                const selectedCountryName = countriesOptions.find((country:any) => country._id === values[0]?._id)?.name;
                                setCountryName(selectedCountryName);
                            }}
                            className={`aria-react-dropdown ${(errors.countryId && touched.countryId) && "!border-[1.5px] !border-[#FF6A6A]"}`}
                        />
                        {errors.countryId && touched.countryId ? (
                            <ErrorMessage text={errors.countryId} />
                        ) : null}
                    </div>
                </>
                :
                <>
                    <div className='text-sm'>
                        <span>{profileInfo?.first_name} </span><span>{profileInfo?.last_name}</span></div>
                    <div
                        className='text-sm'>{profileInfo?.date_of_birth ? moment(profileInfo?.date_of_birth).format('DD/MM/YYYY') : ''}</div>
                    <div className='text-sm'>
                        <span>{profileInfo?.country_id?.name}</span>
                    </div>
                </>
            }
        </>
    )

    const emailFormInputs = ({
                                 errors,
                                 touched,
                                 values,
                                 handleBlur,
                                 handleChange,
                                 setFieldValue
                             }: FormikProps<FormikValues>) => (
        <>
            <div className='w-full mr-[6px]'>
                {!editEmailStatus ?
                    <div>
                        <div className='text-sm flex items-center'>
                            {profileInfo?.email_address}
                            {profileInfo?.is_email_verified &&
                                <span className='w-[24px] ml-[8px]'>
                                    <img className='w-full' src={CheckCircle} alt=""/>
                                </span>
                            }
                        </div>
                        <div className={`${profileInfo?.is_email_verified ? "text-[#28D7FE]" : "text-ar-purple cursor-pointer"} text-sm`}
                            onClick={(!profileInfo?.is_email_verified && !sentEmail) ? handleEmailVerify : undefined}
                        >
                            {profileInfo?.is_email_verified ? (
                                sentEmail ?? (
                                    ''
                                )
                            ) : (
                                sentEmail ? (
                                    `${t('ResendIn')} ${seconds}${t('S')}`
                                ) : (
                                    t('VerifyYourEmailProfile')
                                )
                            )}
                        </div>
                    </div>
                    :
                    <div>
                        <label htmlFor="emailAddress" className="leading-6">
                            {t('NewEmail')}
                        </label>
                        <input type="text" name='emailAddress' id='emailAddress'
                            value={values.emailAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="block h-[40px] mb-[5px] w-full rounded-md border-0 py-1.5 px-[9px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder={t('Email')}
                        />
                        {errors.emailAddress && touched.emailAddress ? (
                            <ErrorMessage text={errors.emailAddress} />
                        ) : null}
                    </div>
                }
            </div>
        </>
    )

    const mobileFormInputs = ({ errors, touched, values, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => (
        <>
            {!editMobileStatus ?
                <div>
                    <div className='text-sm flex items-center'>
                        <span className='mr-[5px]'>{profileInfo?.calling_code}</span><span>{profileInfo?.contact_no}</span>
                        <span className='w-[24px] ml-[8px]'><img className='w-full' src={CheckCircle} alt="" /></span>
                    </div>
                </div>
                :
                <div className='w-full'>
                    <div className='flex justify-between'>
                        <div className='mr-[10px]'>
                            <CallCodeInput
                                inputId="newCallCode"
                                name="newCallCode"
                                optionLabel="name"
                                onChange={(e: any) => {
                                    setFieldValue('newCallCode', e[0].name)
                                }}
                                errors={errors.newCallCode}
                                touched={touched.newCallCode}
                            />
                        </div>
                        <div className='w-full'>
                            <input
                                id="newPhoneNumber"
                                name="newPhoneNumber"
                                type="tel"
                                className="input mb-[5px] !text-[16px]"
                                placeholder={t('MobileNo.')}
                                value={values.newPhoneNumber}
                                onBlur={handleBlur}
                                onChange={(e) => {
                                    setFieldValue('newPhoneNumber', e.target.value);
                                    // setNewMobile((prevObject) => {
                                    //     return { ...prevObject, newPhoneNumber: e.target.value };
                                    // });
                                }}
                            />
                            {errors.newPhoneNumber && touched.newPhoneNumber ? (
                                <ErrorMessage text={errors.newPhoneNumber} />
                            ) : null}
                        </div>
                    </div>
                </div>
            }
        </>
    )

    return (
        <>
            <div className='relative'>
                <div className="bg-[url('../assets/images/hero-banner.png')] bg-no-repeat bg-cover bg-center w-full md:h-[478px] relative">
                    <div className="w-full px-5 xl:w-[1015px] pt-2 lg:pt-3 left-0 right-0 m-auto absolute top-0">
                    </div>
                </div>
                <div className='relative m-auto z-10 md:mb-[70px] md:mt-[-418px] rounded-[15px] md:p-[35px] px-[18px] py-[56px] md:shadow-[0_9px_10px_3px_rgba(0,0,0,0.50)] w-full max-w-[480px] bg-ar-black/[.93] backdrop-blur-[3.5px]'>
                    <h2 className="w-100 text-[26px] text-white">
                        {t('MyProfilePage')}
                    </h2>
                    <FormWrapper
                        toggleHoneyPot={true}
                        initialValues={initialValues}
                        validationSchema={ProfileSchema}
                        formInputs={formInputs}
                        onSubmit={handleSubmit}
                        submitButton={editInfo ?
                            <div className='flex justify-center mb-[20px]'>
                                <button className="bg-ar-primary w-full py-[12px] rounded-[10px] text-white"
                                        type="submit">
                                    {t('Save')}
                                </button>
                            </div>
                            : <></>
                        }
                    />
                    <hr className='my-[25px] border-t border-[#7c7c7c]'/>

                    <div>
                        <div className='flex justify-between items-center mb-[10px]'>
                            <div>{t('Email')}</div>
                            <div className='cursor-pointer text-sm text-ar-purple'
                                 onClick={() => setEditEmailStatus((current: boolean) => !current)}>
                                {!editEmailStatus ? t('Edit') : t('Cancel')}
                            </div>
                        </div>
                        <FormWrapper initialValues={emailInitialValues} validationSchema={EmailSchema}
                                     formInputs={emailFormInputs}
                                     formStyles={{display: 'flex'}}
                                     onSubmit={sendVerificationCode}
                                     submitButton={editEmailStatus ?
                                         <div className='flex justify-center mt-[24px] h-[40px]'>
                                             <button
                                                 className="bg-ar-primary whitespace-nowrap text-sm w-full min-w-[150px] py-[9px] px-[14px] rounded-[6px] text-white"
                                                 type="submit">
                                                 {t('SendVerification')}
                                             </button>
                                         </div>
                                         : <></>
                                     }
                        />
                    </div>

                    <hr className='my-[25px] border-t border-[#7c7c7c]'/>

                    <div>
                        <div className='flex justify-between items-center mb-[10px]'>
                            <div>{t('Mobile')}</div>
                            <div className='cursor-pointer text-sm text-ar-purple'
                                 onClick={() => setEditMobileStatus((current: boolean) => !current)}>
                                {!editMobileStatus ? t('Edit') : t('Cancel')}
                            </div>
                        </div>
                        <FormWrapper initialValues={mobileInitialValues} validationSchema={mobileSchema}
                                     formInputs={mobileFormInputs}
                                     onSubmit={updateMobileNo}
                                     submitButton={editMobileStatus ?
                                         <div className='flex justify-end h-[40px] mt-[7px]'>
                                             <button
                                                 className="bg-ar-primary whitespace-nowrap text-sm w-full md:max-w-[135px] py-[9px] px-[14px] rounded-[6px] text-white"
                                                 type="submit">
                                                 {t('SendCode')}
                                             </button>
                                         </div>
                                         : <></>
                                     }
                        />
                    </div>

                    <hr className='my-[25px] border-t border-[#7c7c7c]'/>

                    <div className="flex justify-between items-center">
                        <div className='text-sm'>{t('EmailUpdatesFromAriaOrganisers')}</div>
                        <div>
                            <Switch
                                onChange={(bool) => {
                                    setSubscriptions((prevState: any) => ({
                                        ...prevState,
                                        ariaOrganisationSubscription: bool,
                                    }))
                                }}
                                checked={subscriptions.ariaOrganisationSubscription}
                                className="react-switch"
                                checkedIcon={false}
                                uncheckedIcon={false}
                                height={22.4}
                                width={38.4}
                                onColor='#6366f1'
                                offColor='#d1d5db'
                                handleDiameter={15}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {verificationScreen &&
                <Modal
                    backdrop={true}
                    width={'md:w-[480px]'}
                    showModal={verificationScreen}
                    setShowModal={(bool: boolean) => {
                        setVerificationScreen(bool);
                    }}
                    footer={false}
                    header={true}
                    closeBtnPosition={'right-[30px] top-[59px] md:right-[34px] md:top-[31px]'}
                    borderRadius={'md:rounded-[15px]'}
                >
                    <SentVerification customerEmail={newEmail}/>
                </Modal>
            }

            {otpScreen &&
                <div className='fixed w-[100vw] h-[100vh] bg-ar-black/90 backdrop-blur-[5px] z-50 text-left'>
                    <Modal
                        width={'md:w-[480px]'}
                        showModal={otpScreen}
                        setShowModal={(bool: boolean) => {
                            handleBack()
                        }}
                        footer={false}
                        header={true}
                        showCloseBtn={true}
                        closeBtnPosition={'right-[30px] top-[57px] md:right-[34px] md:top-[31px]'}
                        borderRadius={'md:rounded-[15px]'}
                    >
                        <div className='pt-[41px] md:pt-[15px]'>
                            <div onClick={() => handleBack()} className='cursor-pointer mb-[25px]'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="22" viewBox="0 0 27 22" fill="none">
                                    <path d="M1 11.1333L0.717159 11.4161L0.434316 11.1333L0.717159 10.8504L1 11.1333ZM26.3333 10.7333C26.5543 10.7333 26.7333 10.9124 26.7333 11.1333C26.7333 11.3542 26.5543 11.5333 26.3333 11.5333L26.3333 10.7333ZM10.8505 21.5494L0.717159 11.4161L1.28284 10.8504L11.4162 20.9838L10.8505 21.5494ZM0.717159 10.8504L10.8505 0.717096L11.4162 1.28278L1.28284 11.4161L0.717159 10.8504ZM1 10.7333L26.3333 10.7333L26.3333 11.5333L1 11.5333L1 10.7333Z" fill="#9B8BFF" />
                                </svg>
                            </div>
                            <h2 className='text-[26px] mb-[10px]'>{t('CodeRequested')}</h2>
                            <p className='mb-[20px]'>{t('fourDigitSent')}<br />
                                <span className="text-ar-cyan">
                                    <span className='font-bold'>{submitedValues.dialCode} </span>
                                    <span className='font-bold'>{submitedValues.phoneNumber}</span>
                                </span>
                            </p>
                            <div className='mb-[20px]'>
                                <OtpInput
                                    inputType='tel'
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={4}
                                    placeholder="●●●●"
                                    // renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}
                                    containerStyle={{ display: 'flex', justifyContent: 'space-between', padding: "20px 44px", border: '1px solid white' }}
                                    inputStyle={{ backgroundColor: 'transparent', fontSize: '26px' }}
                                />
                                {otpVerify === false &&
                                    <div className='mt-[5px]'>
                                        <ErrorMessage text={t('InvalidCodeTryAgain')} />
                                    </div>
                                }
                                {attemptsExceeded2 && attemptsExceeded2 !== 'Invalid data' &&
                                    <div className='mt-[5px]'>
                                        <ErrorMessage text={attemptsExceeded2} />
                                    </div>
                                }
                            </div>
                            <div className='mb-[20px] flex items-center'>
                                <div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <path d="M7.7999 21.6998C7.36657 21.6998 7.00824 21.5581 6.7249 21.2748C6.44157 20.9915 6.2999 20.6331 6.2999 20.1998V14.9998H6.9999V18.6498H16.9999V5.3498H6.9999V8.9998H6.2999V3.7998C6.2999 3.36647 6.44157 3.00814 6.7249 2.7248C7.00824 2.44147 7.36657 2.2998 7.7999 2.2998H16.1999C16.6332 2.2998 16.9916 2.44147 17.2749 2.7248C17.5582 3.00814 17.6999 3.36647 17.6999 3.7998V20.1998C17.6999 20.6331 17.5582 20.9915 17.2749 21.2748C16.9916 21.5581 16.6332 21.6998 16.1999 21.6998H7.7999ZM6.9999 19.3498V20.1998C6.9999 20.3998 7.08324 20.5831 7.2499 20.7498C7.41657 20.9165 7.5999 20.9998 7.7999 20.9998H16.1999C16.3999 20.9998 16.5832 20.9165 16.7499 20.7498C16.9166 20.5831 16.9999 20.3998 16.9999 20.1998V19.3498H6.9999ZM9.9999 15.0998L9.4999 14.5998L11.7499 12.3498H2.6499V11.6498H11.7499L9.4999 9.3998L9.9999 8.8998L13.0999 11.9998L9.9999 15.0998ZM6.9999 4.6498H16.9999V3.7998C16.9999 3.5998 16.9166 3.41647 16.7499 3.2498C16.5832 3.08314 16.3999 2.9998 16.1999 2.9998H7.7999C7.5999 2.9998 7.41657 3.08314 7.2499 3.2498C7.08324 3.41647 6.9999 3.5998 6.9999 3.7998V4.6498Z" fill="white" />
                                    </svg>
                                </div>
                                <div>
                                    <p className={`${mobileSeconds ? 'opacity-20 cursor-not-allowed' : 'opacity-100 cursor-pointer'}`} onClick={() => {
                                        if (mobileSeconds <= 0) {
                                            // resendOtp()
                                            // updateMobileNo({});
                                            resendMobileOtp();
                                        }
                                    }}>{t('RequestNewCode')}</p>
                                    {mobileSeconds ?
                                        <p className='text-ar-cyan'>Available again in <span>{mobileSeconds}</span> seconds</p>
                                        :
                                        <></>
                                    }
                                    {attemptsExceeded &&
                                        <p className='text-[#FF6A6A]'>{attemptsExceeded}</p>
                                    }
                                </div>
                            </div>
                            <div>
                                <Disclosure>
                                    {({ open }) => (
                                        <>
                                            <Disclosure.Button className="flex w-full text-left mb-[21px]">
                                                <span className="text-ar-purple">{t('DintReceiveCode')}</span>
                                                <span className={`${open ? 'rotate-180 transform' : ''} self-center max-w-[26px]`}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                        <path d="M18 15L12 9L6 15" stroke="#9B8BFF" strokeWidth="2" />
                                                    </svg>
                                                </span>
                                            </Disclosure.Button>
                                            <Transition
                                                enter="transition duration-100 ease-out"
                                                enterFrom="transform scale-95 opacity-0"
                                                enterTo="transform scale-100 opacity-100"
                                                leave="transition duration-75 ease-out"
                                                leaveFrom="transform scale-100 opacity-100"
                                                leaveTo="transform scale-95 opacity-0"
                                            >
                                                <div className="grid grid-cols-1 divide-y divide-[#919191] border-solid border border-[#919191]">
                                                    <Disclosure.Panel className="pl-[15px] pr-[65px] py-[26px]">
                                                        Check your mobile for typos. You’re only human!
                                                    </Disclosure.Panel>
                                                    <Disclosure.Panel className="pl-[15px] pr-[65px] py-[26px]">
                                                        Move to an area with strong reception.
                                                    </Disclosure.Panel>
                                                    <Disclosure.Panel className="pl-[15px] pr-[65px] py-[26px]">
                                                        Check your phone accepts texts from unknown senders.
                                                    </Disclosure.Panel>
                                                    <Disclosure.Panel className="pl-[15px] pr-[65px] py-[26px]">
                                                        Ensure your text inbox isn’t full.
                                                    </Disclosure.Panel>
                                                </div>
                                            </Transition>
                                        </>
                                    )}
                                </Disclosure>
                            </div>
                        </div>
                    </Modal>
                </div>
            }

        </>
    )
}

export default ProfilePageForm;