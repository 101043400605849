import React from "react";
import { Outlet } from "react-router-dom";
import ArtistDetail from "../screens/ArtistDetail";
import ArtistListing from "../screens/ArtistListing";
import Checkout from "../screens/Checkout";
import EventDetail from "../screens/EventDetail";
import EventListing from "../screens/EventListing";
import EventPurchaseTicket from "../screens/EventPurchaseTicket";
import ForgotPassword from "../screens/ForgotPassword";
import HomePage from "../screens/HomePage";
import Login from "../screens/Login";
import NotFound from "../screens/NotFound";
import Register from "../screens/Register";
import ResetPassword from "../screens/ResetPassword";
import TNC from "../screens/TNC";
import PrivacyPolicy from "../screens/PrivacyPolicy";
import AboutUs from "../screens/AboutUs";

const publicRoutes = [
  {
    name: "Home page",
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/events",
    element: <Outlet />,
    handle: {
      crumb: {
        label: "Events",
        url: "/events",
      },
    },
    children: [
      {
        index: true,
        element: <EventListing />,
        // errorElement: <Navigate to='/' />,
        // loader: async function () {
        //   const eventList = await getEventListing({
        //     byCountry: "",
        //     byDate: "",
        //     byCategory: "",
        //     page: 1,
        //     hasMore: false,
        //   });
        //
        //   const eventCountryDropdown = await getEventCountryDropdown()
        //
        //   return {
        //     list: eventList?.data?.data,
        //     countryDropdown: eventCountryDropdown?.data?.data
        //   };
        // },
      },
      {
        path: ":slug",
        element: <EventDetail />,
        handle: {
          crumb: {
            url: "/events/:slug/",
            needOverwrite: true,
          },
        },
        // loader: async function ({ params }: any) {
        //   const response:any = await eventDetailInformation(params.slug)
        //     return response?.data?.data
        // },
        // errorElement: <EventDetail />,
      },
      // {
      //   path: "private/:slug",
      //   element: <EventDetail />,
      //   handle: {
      //     crumb: {
      //       url: "/events/private/:slug",
      //       needOverwrite: true,
      //     },
      //   },
      //   loader: async function ({ params }: any) {
      //     const response = await eventDetailInformation(params.slug, `records=10&sortBy=createdAt&sortOrder=desc`)
      //     return response?.data?.data
      //   },
      //   // errorElement: <EventDetail />,
      // },
    ],
  },
  {
    path: "/artistes",
    element: <Outlet />,
    handle: {
      crumb: {
        label: "Artistes",
        url: "/artistes",
      },
    },
    children: [
      {
        index: true,
        element: <ArtistListing />,
      },
      {
        path: ":artiste_id",
        element: <ArtistDetail />,
        handle: {
          crumb: {
            label: "Artiste detail",
            url: "/artistes/:artiste_id",
            needOverwrite: true,
          },
        },
      },
    ],
  },
  // {
  //   name: "Contact",
  //   path: "/contact",
  //   element: <ContactPage />,
  //   handle: {
  //     crumb: {
  //       label: "Contact",
  //       url: "/contact",
  //     },
  //   },
  // },

  {
    name: "Event purchase ticket",
    path: "/events/:slug/event-purchase-ticket",
    element: <EventPurchaseTicket />,
    handle: {
      crumb: {
        label: "Event purchase ticket",
        url: "/event-purchase-ticket",
      },
    },
  },
  // {
  //   name: "Payment-old",
  //   path: "/payment",
  //   element: <Payment-old />,
  //   handle: {
  //     crumb: {
  //       label: "Payment-old",
  //       url: "/payment",
  //     },
  //   },
  // },
  {
    name: "Checkout",
    path: "/checkout",
    element: <Checkout />,
    handle: {
      crumb: {
        label: "Checkout",
        url: "/checkout",
      },
    },
  },
  {
    name: "Forgot password",
    path: "/forgot-password",
    element: <ForgotPassword />,
    handle: {
      crumb: {
        label: "Forgot password",
        url: "/forgot-password",
      },
    },
  },
  {
    name: "Reset password",
    path: "/reset-password",
    element: <ResetPassword />,
    handle: {
      crumb: {
        label: "Reset password",
        url: "/reset-password",
      },
    },
  },
  {
    name: "Register",
    path: "/register",
    element: <Register />,
    handle: {
      crumb: {
        label: "Register",
        url: "/register",
      },
    },
  },
  {
    name: "Login",
    path: "/login",
    element: <Login />,
    handle: {
      crumb: {
        label: "Login",
        url: "/login",
      },
    },
  },
  {
    name: "TNC",
    path: "/term-and-condition",
    element: <TNC />,
    // loader:
    //   async function () {
    //     const response = await tncData();
    //     return response?.data?.data
    //   },
    handle: {
      crumb: {
        label: "Term and Condition",
        url: "/term-and-condition",
      },
    },
  },
  {
    name: "Privacy Policy",
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
    handle: {
      crumb: {
        label: "Privacy policy",
        url: "/privacy-policy",
      },
    },
  },
  {
    name: "About Us",
    path: "/about-us",
    element: <AboutUs/>,
    handle: {
      crumb: {
        label: "About us",
        url: "/about-us",
      },
    },
  },
  {
    name: "Not found",
    path: "*",
    element: <NotFound />,
    handle: {
      crumb: {
        label: "Not found",
        url: "*",
      },
    },
  },
];

export default publicRoutes;
