import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SuccessIcon from "../../assets/images/icons/success-icon.png";
import DangerouslySetInnerHTML from "../../components/DangerouslySetInnerHTML";
import PageContainer from "../../components/PageContainer";
import { useAppDispatch, useAppSelector } from "../../hooks/useStore";
import { resetItemSlice } from "../../redux/slices/ticketSlice";
import { purchasedCustomContent, receiptContent } from "../../utils/apiRequestList/ticketServices";
import OrderSummary from "../../components/Receipt/OrderSummary";
import PaymentDetails from "../../components/Receipt/PaymentDetails";
import Items from "../../components/Receipt/Items";
import EventDetails from "../../components/Receipt/EventDetails";
import { policyCustomContent } from "../../utils/apiRequestList/ticketServices";

const CheckoutResult = () => {
    const { t } = useTranslation();
    const { slug, tId }: any = useParams();
    const { search } = useLocation();
    const { ticketCheckout }: any = useAppSelector((state) => state.ticket);
    const navigate = useNavigate();
    const [customContent, setCustomContent] = useState<any>({});
    const [purchase, setPurchase] = useState<any>({});
    const [eventTitle, setEventTitle] = useState<any>('')
    const dispatch = useAppDispatch()
    const [policyContent, setPolicyContent] = useState<any>(null)

    const fetchCustomContent = async () => {
        const query = new URLSearchParams(search)
        try {
            const [
                receiptDataResponse,
                customContentResponse,
                policyResponse
            ] = await Promise.all([
                receiptContent(tId),
                purchasedCustomContent(slug),
                policyCustomContent(slug)
            ])

            setCustomContent(customContentResponse?.data?.data)
            setPurchase({
                ...receiptDataResponse?.data?.data,
                payment_status_checkout: query.get('redirect_status'),
                payment_method_checkout: query.get('pm')
            })
            setPolicyContent(policyResponse?.data?.data)
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        const query = new URLSearchParams(search)
        if (!ticketCheckout || !query.get('redirect_status')) {
            navigate('/events')
            return
        }

        dispatch(resetItemSlice())
        fetchCustomContent()
        setEventTitle(query.get('event'))
    }, [])

    return <>
        <Helmet>
            <title>Thank You for Your Purchase!</title>
            <meta property="og:title" content="Thank You for Your Purchase!" />
            <meta property="og:description" content={`You’ve got plans! Thank you for booking ${eventTitle}. Your tickets will be emailed to you within 2 weeks of the event.`} />
            <meta property="og:type" content="website" />
        </Helmet>
        <div className="bg-[url('../assets/images/purchased-bg.jpeg')] h-[100vh] bg-no-repeat bg-cover bg-center w-full md:h-[500px] md:relative md:top-0 absolute top-[45px]">
        </div>
        <PageContainer maxWidth="md:w-[708px] w-full" customTop={'mt-[90px] top-0 md:mt-[-200px]'}>
            <div className="flex items-center justify-center">
                <div className=" px-2 md:px-28 py-9">
                    <div>
                        <div className="text-center px-3">
                            <img className="w-[78px] h-[78px] m-auto" src={SuccessIcon} alt="success icon" />
                            <div className=" text-2xl font-semibold mt-5">{t("ThankYouForMakingPlans")}</div>
                            <div className=" text-base font-normal mt-2 mb-8">
                                {/* <div>{orderID}</div> */}
                                <div className="mt-5">{t('YouWillReceiveConfirmation')}</div>
                                {/* <DangerouslySetInnerHTML
                                    classNames={'mt-5 text-base font-normal editor-formatting purchased-ticket-link'}
                                    htmlContent={t('VisitYourProfileToCheckBooking')}
                                /> */}
                            </div>
                        </div>
                        <OrderSummary order_id={purchase?.order_id} order_date={purchase?.order_date} />
                        <PaymentDetails payment_method={purchase?.payment_method_checkout ? purchase?.payment_method_checkout : purchase?.payment_method} payment_status={purchase?.payment_status_checkout ? t(purchase?.payment_status_checkout) : purchase?.payment_status} />
                        <Items order_summary={purchase?.order_summary} />
                        <EventDetails event={purchase?.event} />
                        <div className="mb-[20px]">
                            {customContent && <DangerouslySetInnerHTML
                                classNames={'mt-5 text-base font-normal editor-formatting mb-[20px]'}
                                htmlContent={customContent?.description}
                            />}
                            {(policyContent?.purchase_policy || policyContent?.event_policy) && <div className="mb-[20px]">
                                {policyContent?.purchase_policy && <>
                                    <div className="mb-[20px]">
                                        <div className=" text-base font-normal text-[#CACACA] border-b border-[#9B8BFF] mb-[20px] pb-[6px]">{t('PurchasePolicy')}</div>
                                        <div className="text-[14px] font-normal text-[#919191]">
                                            <DangerouslySetInnerHTML
                                                classNames={'break-words editor-formatting overflow-auto max-h-[300px] mb-8'}
                                                htmlContent={policyContent?.purchase_policy}
                                            />
                                            {/* <ul className='list-disc ml-[18px]'>
                                            <li>{t("TicketPolicyPoint1")}</li>
                                            <li>{t("TicketPolicyPoint2")}</li>
                                            <li>{t("TicketPolicyPoint3")}</li>
                                            <li>{t("TicketPolicyPoint4")}</li>
                                            <li>{t("TicketPolicyPoint5")}</li>
                                        </ul> */}
                                        </div>
                                    </div>
                                </>}
                                {policyContent?.event_policy && <>
                                    <div>
                                        <div className=" text-base font-normal text-[#CACACA] border-b border-[#9B8BFF] mb-[20px] pb-[6px]">{t('EventPolicy')}</div>
                                        <div className="text-[14px] font-normal text-[#919191]">
                                            <DangerouslySetInnerHTML
                                                classNames={'break-words editor-formatting overflow-auto max-h-[300px]'}
                                                htmlContent={policyContent?.event_policy}
                                            />
                                            {/* <ul className='list-disc ml-[18px]'>
                                            <li>{t("EventPolicyPoint1")}</li>
                                            <li>{t("EventPolicyPoint2")}</li>
                                            <li>{t("EventPolicyPoint3")}</li>
                                            <li>{t("EventPolicyPoint4")}</li>
                                            <li>{t("EventPolicyPoint5")}</li>
                                            <li>{t("EventPolicyPoint6")}</li>
                                        </ul> */}
                                        </div>
                                    </div>
                                </>}
                            </div>}
                        </div>
                        <div className="text-center">
                            <button onClick={() => navigate('/events')} className=" mt-5 rounded-full px-5 py-3 bg-ar-primary text-sm font-normal">{t("BackToEvent")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </PageContainer>
    </>
}

export default CheckoutResult;