import React, { useState, useEffect, useRef } from "react";
import Clear from "../assets/images/icons/Clear";
import { useClickOutside } from "@mantine/hooks";

const SearchInput = (props: any) => {
    const [expanded, setExpanded] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [searchText, setSearchText] = useState('');
    const formRef = useClickOutside(() => setExpanded(false));

    useEffect(() => {
        if (!expanded) return;
        inputRef.current?.focus();
    }, [expanded]);

    const handleInputChange = (event: any) => {
        const sanitizedQuery = event.target.value;
        const disallowedChars = /[^\p{L}\p{N}\s]/u;
        if (disallowedChars.test(sanitizedQuery)) {
            return;
        }
        props.handleResetPage()
        props.setQuery(sanitizedQuery)

        setSearchText(event.target.value)
    }

    return <form ref={formRef}>
        <div className={`${expanded ? "flex items-center md:w-[300px]" : "block md:w-9 md:h-[42px]"
            } bg-ar-dark-purple pr-3 md:pr-0 h-[42px] rounded-full flex overflow-hidden items-center  relative md:cursor-pointer transition-all ease-in-out focus-within:ring-2 ring-indigo-400`}>
            <div
                onClick={() => {
                    if (expanded) return;
                    setExpanded(true);
                }}
                className={`left-1/2 top-1/2}`}
            >
                <svg width="38" height="36" fill="none" xmlns="http://www.w3.org/2000/svg"><rect width="38" height="36" rx="18" fill="#332B64" /><path d="M17.996 22.992c-1.674 0-3.092-.58-4.254-1.742C12.581 20.088 12 18.67 12 16.996c0-1.674.58-3.092 1.742-4.254C14.904 11.581 16.322 11 17.996 11c1.674 0 3.092.58 4.254 1.742 1.161 1.162 1.742 2.58 1.742 4.254 0 .7-.117 1.369-.352 2.006a5.609 5.609 0 0 1-.941 1.663l4.093 3.74a.71.71 0 0 1 .208.513.693.693 0 0 1-.208.521.704.704 0 0 1-.517.213.703.703 0 0 1-.517-.213l-4.093-3.74a5.604 5.604 0 0 1-1.691.95 5.81 5.81 0 0 1-1.978.343Zm0-1.47c1.263 0 2.333-.439 3.21-1.316.877-.877 1.315-1.947 1.315-3.21s-.438-2.333-1.315-3.21c-.877-.877-1.947-1.315-3.21-1.315s-2.333.438-3.21 1.315c-.877.877-1.315 1.947-1.315 3.21s.438 2.333 1.315 3.21c.877.877 1.947 1.315 3.21 1.315Z" fill="white" /></svg>
            </div>
            <input
                ref={inputRef}
                type="text"
                value={searchText}
                onChange={handleInputChange}
                className={`${expanded ? "md:block" : "md:hidden"
                    } flex-1 h-full ml-2 outline-none transition-all ease-in-out bg-ar-dark-purple`}
            />

            <div
                className="cursor-pointer md:pr-2"
                onClick={() => setSearchText('')}
            >
                <svg width="15" height="15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="m7.5 8.332 2.426 2.426c.11.11.247.165.412.168a.558.558 0 0 0 .42-.168.566.566 0 0 0 .172-.416.566.566 0 0 0-.172-.416L8.332 7.5l2.426-2.426a.572.572 0 0 0 .168-.412.558.558 0 0 0-.168-.42.566.566 0 0 0-.416-.172.566.566 0 0 0-.416.172L7.5 6.668 5.074 4.242a.572.572 0 0 0-.412-.168.558.558 0 0 0-.42.168.566.566 0 0 0-.172.416c0 .163.058.301.172.416L6.668 7.5 4.242 9.926a.572.572 0 0 0-.168.412.558.558 0 0 0 .168.42.566.566 0 0 0 .416.172.566.566 0 0 0 .416-.172L7.5 8.332ZM7.501 15a7.306 7.306 0 0 1-2.925-.59 7.576 7.576 0 0 1-2.382-1.603 7.572 7.572 0 0 1-1.603-2.381A7.299 7.299 0 0 1 0 7.5C0 6.464.197 5.49.59 4.576a7.576 7.576 0 0 1 1.603-2.382A7.572 7.572 0 0 1 4.574.591 7.299 7.299 0 0 1 7.5 0c1.037 0 2.012.197 2.925.59a7.577 7.577 0 0 1 2.382 1.603 7.573 7.573 0 0 1 1.603 2.381c.394.913.591 1.887.591 2.925a7.306 7.306 0 0 1-.59 2.925 7.577 7.577 0 0 1-1.603 2.382 7.572 7.572 0 0 1-2.381 1.603A7.298 7.298 0 0 1 7.5 15Z" fill="#332B64" /></svg>
            </div>
            <input type="submit" value="" className="hidden" />
            <div
                onClick={() => {
                    props.handleResetPage()
                    setSearchText('')
                    inputRef.current?.focus()
                }}
                className={`cursor-pointer ${expanded && searchText ? "md:block" : "md:hidden"}`}
            >
                {searchText ? <Clear className=" text-[#fff] mr-3" /> : <span></span>}
            </div>
        </div>
    </form>
}

export default SearchInput;