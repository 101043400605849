import axios from "axios"
import {JSEncrypt} from "jsencrypt";
import i18n from "../languages/i18n"
import { store } from "../redux/store";

var encrypt = new JSEncrypt();

var publicKey = process.env.REACT_APP_ARIA_PUB_KEY ?? "";

encrypt.setPublicKey(publicKey);

function init() {
    axios.defaults.withCredentials = true;
    axios.defaults.baseURL = `${process.env.REACT_APP_SERVER_BASE_URL}/api/v1`;
    axios.defaults.headers.post["Content-Type"] = "application/json";
    axios.interceptors.request.use(
        (config) => {
          const token = store.getState().userAuth?.accessToken;
          if (token) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            config.headers["Authorization"] = `Bearer ${token}`;
          }
          return config;
        },
        (error) => {
          return Promise.reject(error);
        },
    );
    axios.interceptors.response.use(
        response => response,
        error => {
            return Promise.reject(error);
        }
    );
}

async function setToken() {
    const token = '';
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

async function post(path: string, payload: object, header = {}) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const headers = {
        ...{
            'sign': '',
            'X-Tz': encrypt.encrypt(timezone),
            'Content-Type': 'application/json',
            'Accept-Language': 'en',
            // 'Accept-Language': i18n.language || 'en', // todo uncomment this when we want to unlock localization
            'X-Country-Code': store.getState()?.location?.currentLocation?.country || 'SG'
        },
        ...header
    }
    let params: any = payload
    if (headers["Content-Type"] === 'application/json') {
        params = JSON.stringify(payload)
    }
    let response = axios.post(
        path,
        params,
        {
            headers
        }
    );
    return response;
}

async function put(path: string, payload: object, header = {}) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const headers = {
        ...{
            'sign': '',
            'X-Tz': encrypt.encrypt(timezone),
            'Content-Type': 'application/json',
            'Accept-Language': 'en',
            // 'Accept-Language': i18n.language || 'en', // todo uncomment this when we want to unlock localization
            'X-Country-Code': store.getState()?.location?.currentLocation?.country || 'SG'
        },
        ...header
    }
    let params: any = payload
    if (headers["Content-Type"] === 'application/json') {
        params = JSON.stringify(payload)
    }
    let response = axios.put(
        path,
        params,
        {
            headers
        }
    );
    return response;
}

async function get(path: string, header = {}) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    const headers = {
        ...{
            'sign': '',
            'X-Tz': encrypt.encrypt(timezone),
            'Content-Type': 'application/json',
            'Accept-Language': 'en',
            // 'Accept-Language': i18n.language || 'en', // todo uncomment this when we want to unlock localization
            'X-Country-Code': store.getState()?.location?.currentLocation?.country || 'SG'
        },
        ...header
    }
    let response = axios.get(
        path,
        {
            headers
        }
    );
    return response;
}


async function remove(path: string) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let response = await axios.delete(
        path,
        {
            headers: {
                'sign': '',
                'X-Tz': encrypt.encrypt(timezone),
                'Content-Type': 'application/json',
                'Accept-Language': 'en',
                // 'Accept-Language': i18n.language || 'en', // todo uncomment this when we want to unlock localization
                'X-Country-Code': store.getState()?.location?.currentLocation?.country || 'SG'
            }
        }
    );
    return response;
}

export {
    init,
    setToken,
    post,
    put,
    get,
    remove,
};