import { useClickOutside, useDebouncedValue } from "@mantine/hooks";
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import GenericAvatar from "../../assets/images/generic-avatar.png";
import ChevronRight from "../../assets/images/icons/ChevronRight";
import Clear from "../../assets/images/icons/Clear";
import Search from "../../assets/images/icons/Search";
import BreadCrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import { artistListing } from "../../utils/apiRequestList/artistServices";


const ArtistListing = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [artists, setArtists]: any = useState([]);
  const [loading, setLoading] = useState(true);
  const [query, setQuery] = useState<string>("");
  const [debouncedQuery] = useDebouncedValue(query, 300);
  const [infiniteLoader, setInfiniteLoader] = useState({
    page: 1,
    hasMore: false,
  });
  const ref = useClickOutside(() => setExpanded(false));


  useEffect(() => {
    if (!expanded) return;
    inputRef.current?.focus();
  }, [expanded]);

  const fetchData = async () => {
    if (infiniteLoader.page === 1) { setLoading(true); }
    try {
      const response: any = await artistListing(
        `records=20&page=${infiniteLoader.page}&searchByName=${query}`
      );
      if (response?.data?.data) {
        const data = response?.data?.data?.docs

        if (query) {
          setArtists(data)
        } else {
          setArtists((prevData: any) => [...prevData, ...data]);
        }

        setInfiniteLoader({
          page: response?.data?.data?.nextPage ?? 1,
          hasMore: response?.data?.data?.hasNextPage
        });
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setArtists([])
      console.error(error);
    }
  };

  const handleChange = (e: any) => {
    const sanitizedQuery = e.target.value;

    const disallowedChars = /[^\p{L}\p{N}\s]/u; 
    if (disallowedChars.test(sanitizedQuery)) {
      return;
    } else {
      setLoading(true)
    }

    setQuery(sanitizedQuery)
    setArtists([])
    setInfiniteLoader({ page: 1, hasMore: false })
  }

  const clearSearch = () => {
    setQuery("");
    setArtists([])
    setInfiniteLoader({ page: 1, hasMore: false })
    inputRef.current?.focus()
    setLoading(true)
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, [debouncedQuery]);

  return (
    <div className="">
      <Helmet>
        <title>Artiste Directory | Aria</title>
        <meta property="og:title" content="Artiste Directory | Aria" />
        <meta property="og:description" content="Explore the upcoming shows for your favourite artiste. Browse our artist directory, book tickets, and elevate your event on Aria!" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="bg-[url('../assets/images/artist-listing-hero.png')] bg-no-repeat bg-cover bg-top w-full h-[375px] md:h-[478px]">
        {/* <div className="max-w-[1015px] px-5 pt-3 mx-auto">
          <BreadCrumb />
        </div> */}
      </div>

      <div className="bg-ar-black border-none md:border  pb-[40px] max-w-[1080px] md:-mt-[220px] md:p-[30px] md:rounded-[15px]  md:bg-opacity-[93%] md:shadow-[0_9px_10px_3px_rgba(0,0,0,0.50)] md:backdrop-filter backdrop-blur-[3.5px] md:mb-[70px] md:mx-6 xl:mx-auto">
        <div className="bg-[f8f8f8] px-8 md:px-0 py-[30px] md:flex md:justify-between md:gap-0 md:items-center">
          <h2 className=" text-white text-[26px] md:text-7 font-semibold mb-8 md:mb-0">
            {t("ArtisteDirectoryHeader")}
          </h2>
          <div className={`${expanded ? "md:w-[300px]" : 'md:w-[42px]'} transition-all ease-in-out`}>
            <form
              ref={ref}
              onClick={() => {
                if (expanded) return;
                setExpanded(true);
              }}
              className={`bg-ar-dark-purple h-[42px] rounded-full flex overflow-hidden items-center pl-4 pr-3  relative md:cursor-pointer focus-within:ring-2 ring-indigo-400`}
            >
              <div
                className={` left-1/2 top-1/2 ${!expanded
                  ? "md:absolute md:-translate-x-1/2 md:-translate-y-1/2"
                  : "md:w-[16px] md:h-[16px] "
                  } `}
              >
                <Search />
              </div>
              <input
                ref={inputRef}
                onChange={(e) => handleChange(e)}
                value={query}
                type="search"
                className={`${expanded ? "md:block" : "md:hidden"
                  } flex-1 h-full ml-3 outline-none transition-all  bg-ar-dark-purple text-white`}
              />

              <div
                className={`cursor-pointer ${expanded && query ? "block" : "hidden"}`}
                onClick={clearSearch}
              >
                <Clear className=" text-[#fff]" />
              </div>
              <input type="submit" value="" className="hidden" />
            </form>
          </div>
        </div>

        {loading ? (
          <div className="min-h-[300px] flex justify-center items-center">
            <Spinner />
          </div>
        ) : artists && artists.length ? (

          <InfiniteScroll
            pageStart={infiniteLoader.page}
            loadMore={fetchData}
            hasMore={infiniteLoader.hasMore}
            threshold={200}
            loader={<h4 key='artist-loader' className="text-center">Loading...</h4>}
          >
            <div className="gap-x-4 md:grid md:grid-cols-2">
              {artists.map((artist: any) => (
                <Link
                  to={`/artistes/${artist.slug}?redirectPath=${encodeURI('/artistes')}`}
                  key={artist.artistId}
                  className="bg-ar-black text-white md:bg-transparent  flex h-[60px] items-center px-[34px] md:pr-[18px] md:pl-[5px] border-b-[.5px] border-[#9B8BFF]"
                >
                  <img
                    src={artist?.profilePicture_thumb || GenericAvatar}
                    alt={`${artist?.artistName}`}
                    className="overflow-hidden rounded-full mr-[28px] w-[35px] h-[35px] object-cover object-center"
                  />
                  <div className="flex justify-between flex-1 items-center">
                    <div className="overflow-hidden line-clamp-2">{artist.artistName}</div>
                    <div className=" w-6"><ChevronRight className="text-[#9B8BFF]" /></div>
                  </div>
                </Link>
              ))}
            </div>
          </InfiniteScroll>
        ) : (
          <div className="text-center min-h-[200px] flex items-center justify-center dark:text-white">
            {t('NotFoundArtist')}
          </div>
        )}
      </div>
    </div>
  );
};

export default ArtistListing;
