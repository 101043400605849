import React from 'react';
import Modal from "../components/Modal";
import * as Yup from "yup";
import {FormikProps, FormikValues} from "formik";
import ErrorMessage from "../components/Form/errorMessage";
import FormWrapper from "../components/Form/FormWrapper";
import {eventDetailInformation} from "../utils/apiRequestList/eventServices";
import {useTranslation} from "react-i18next";

const AuthPrivateEventModal = (props:any)=>{
	const { t } = useTranslation();
	const initialValues = {
		password: '',
	}

	const schema = Yup.object({
		password: Yup.string().required(t('MandatoryField')),
	});

	const formInputs = ({ errors, values, touched, handleBlur, handleChange, setFieldValue }: FormikProps<FormikValues>) => (
		<>
			<div>
				<input
					id="password"
					name="password"
					type="password"
					value={values.password}
					onBlur={handleBlur}
					onChange={handleChange}
					className="text-[#000000]"
				/>
				{errors.password && touched.password ? (
					<ErrorMessage text={errors.password} />
				) : null}
			</div>
		</>
	)

	const handleSubmit = async (values: any) => {
		try {
			const searchParams = new URLSearchParams({password: values.password});
			const response = await eventDetailInformation(props?.params, `${searchParams.toString()}`)
			if (response?.data?.success){
				props?.setRefresh(true);
				props?.data({
					...response?.data?.data
				})
			}
		} catch (error) {
			console.log(error)
		}
	}

	return <>
		<Modal
			backdrop={true}
			width={'md:w-[480px]'}
			showModal={props?.showAuthModal}
			setShowModal={(bool:boolean)=> {
				props?.authModal(bool)
			}}
			footer={false}
			header={true}
			closeBtnPosition={'right-[30px] top-[59px] md:right-[34px] md:top-[31px]'}
			borderRadius={'md:rounded-[15px]'}
		>
			<FormWrapper
				initialValues={initialValues}
				validationSchema={schema}
				formInputs={formInputs}
				onSubmit={handleSubmit}
			/>
		</Modal>
	</>
}
export default AuthPrivateEventModal;
