import { combineReducers } from 'redux';
import { persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import {parse, stringify} from 'flatted';

 
import authSlice from './slices/authSlice';
import locationSlice from './slices/locationSlice';
import commonSlice from './slices/commonSlice';
import ticketSlice from './slices/ticketSlice';
import eventFilterSlice from './slices/eventFilterSlice';

const rootReducer = combineReducers({
    userAuth: authSlice,
    location: locationSlice,
    common: commonSlice,
    ticket: ticketSlice,
    eventFilter: eventFilterSlice,
});

const circularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value:any) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return '[Circular Reference]';
        }
        seen.add(value);
      }
      return value;
    };
  };

const customTransform = createTransform(
    (inboundState, key) => stringify(inboundState, circularReplacer),
    (outboundState, key) => parse(outboundState),
    { whitelist: ['eventFilter'] }
);


const persistConfig = {
    timeout: 500,
    key: `root-${process.env.REACT_APP_VERSION}`, // key to store the persisted state ;; increase package.json version to flush data
    storage,
    transforms: [customTransform]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;