import React, {useEffect, useState} from 'react'
import {Helmet} from "react-helmet-async";
import {Outlet, useNavigate} from "react-router-dom";
import { NavLink } from "react-router-dom";
import { ReactComponent as ProfileIcon} from "../../assets/images/icons/profile-icon.svg";
import { ReactComponent as Tickets} from "../../assets/images/icons/tickets-icon.svg";
import { ReactComponent as Settings} from "../../assets/images/icons/settings-icon.svg";




const SocialProfileLayout = () => {
    const navigate = useNavigate();

    const navLink = [
        {
            label: 'Profile',
            url: '/social-profile',
            icon: <ProfileIcon />,
            end: true,
        },
        {
            label: 'Tickets',
            url: '/social-profile/tickets',
            icon: <Tickets />,
            end: false,
        },
        {
            label: 'Settings',
            url: '/social-profile/settings',
            icon: <Settings />,
            end: false,
        },
    ]

    return(
        <>
            <Helmet>
                <title>My Profile | Aria </title>
                <meta property="og:title" content="My Social Profile | Aria " />
                <meta property="og:description" content="Manage your personal information and newsletter subscriptions here." />
                <meta property="og:type" content="website" />
            </Helmet>

            <div className='relative'>
                <div className="bg-[url('../assets/images/hero-banner.png')] bg-no-repeat bg-cover bg-center w-full h-[250px] md:h-[478px] relative">
                    <div className="w-full px-5 xl:w-[1015px] pt-2 lg:pt-3 left-0 right-0 m-auto absolute top-0">
                    </div>
                </div>
                <div className='relative m-auto mb-[50px] z-10 md:mb-[70px] mt-[-74px] md:mt-[-288px] rounded-[15px] md:shadow-[0_9px_10px_3px_rgba(0,0,0,0.50)] w-[97%] lg:w-full max-w-[1080px] bg-ar-black/[.93] backdrop-blur-[3.5px] shadow-lg'>

                    <div className='flex w-full max-w-[1080px] min-h-[450px] flex-col md:flex-row'>
                        <div className='w-full md:min-w-[185px] md:max-w-[185px] border-b-[0.5px] border-b-[#9B8BFF] md:border-b-0 sp-panel-left'>
                            <div className='flex justify-between md:justify-normal md:flex-col md:pt-[46px] md:pl-[36px] md:pr-[0px] px-[18px] py-[10px] pb-0'>
                                {
                                    navLink.map((item:any, index:number)=> (
                                        <div className='md:w-auto w-[32px] min-h-[35px] md:mb-[18px] relative' key={index}>
                                            <NavLink
                                                onClick={() => navigate(`${item.url}`)}
                                                to={item.url}
                                                end={item.end}
                                                className={({isActive, isPending}) =>
                                                    [
                                                        isPending ? "pending" : "",
                                                        isActive ? "active" : "",
                                                        "nav-sp flex items-center mb-[10px] md:mb-0 relative"
                                                    ].join(" ")
                                                }
                                            >
                                                <div className='nav-sp-icon w-[30px] mr-[0px] md:mr-[15px]'>
                                                    {item.icon}
                                                </div>
                                                <span className='hidden md:block'>{item.label}</span>
                                            </NavLink>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <Outlet/>
                    </div>

                </div>
            </div>
        </>
    )

}

export default SocialProfileLayout;