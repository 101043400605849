import React, { useState, useEffect } from "react";
import CapsuleButton from "../../../components/CapsuleButton";
import { useTranslation } from "react-i18next";
import { eventDetailGallery } from "../../../utils/apiRequestList/eventServices";
import Spinner from "../../../components/Spinner";
import InfiniteScroll from "react-infinite-scroller";

const EventGallery = (props: any) => {
    const iconToTop = <svg width="52" height="52" fill="none" xmlns="http://www.w3.org/2000/svg"><g opacity=".7" filter="url(#a)"><circle cx="26" cy="22" r="22" fill="#F8F8F8" /></g><path d="m32 27-6-6-6 6M21 16h10" stroke="#6650F2" /><defs><filter id="a" x="0" y="0" width="52" height="52" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB"><feFlood floodOpacity="0" result="BackgroundImageFix" /><feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" /><feOffset dy="4" /><feGaussianBlur stdDeviation="2" /><feComposite in2="hardAlpha" operator="out" /><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" /><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_1749_2665" /><feBlend in="SourceGraphic" in2="effect1_dropShadow_1749_2665" result="shape" /></filter></defs></svg>
    const iconNoResult = <svg className="m-auto mb-6" width="48" height="48" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)"><path d="M24 0C10.745 0 0 10.745 0 24s10.745 24 24 24h19.884A4.117 4.117 0 0 0 48 43.884V24C48 10.745 37.255 0 24 0Z" fill="#6650F2" /><path d="M21.713 27.914c0-3.206 1.766-4.35 3.305-5.366 1.21-.818 2.29-1.538 2.29-3.074 0-1.736-1.18-2.715-2.88-2.715-1.702 0-2.912 1.015-2.912 2.813v.262H17.82v-.394c0-3.862 2.846-6.315 6.708-6.315s6.708 2.389 6.708 6.315c0 3.403-1.865 4.483-3.468 5.431-1.277.754-2.422 1.407-2.422 3.043v.327h-3.63v-.327h-.003Zm-.72 4.875c0-1.472 1.111-2.585 2.585-2.585 1.473 0 2.584 1.111 2.584 2.585 0 1.473-1.11 2.584-2.584 2.584s-2.585-1.11-2.585-2.584Z" fill="#fff" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h48v48H0z" /></clipPath></defs></svg>
    const iconVideoDesktop = <svg width="55" height="47" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)"><path d="M6.875 6.883c-9.166 9.177-9.166 24.057 0 33.234 9.166 9.177 24.028 9.177 33.194 0L53.822 26.35a4.032 4.032 0 0 0 0-5.7L40.069 6.882c-9.166-9.177-24.028-9.177-33.194 0v.002Z" fill="#6650F2" /><path d="m33.394 21.855-11.912-6.886c-1.265-.73-2.847.184-2.847 1.646v13.77c0 1.462 1.582 2.376 2.847 1.646l11.912-6.886c1.265-.73 1.265-2.56 0-3.29Z" fill="#fff" /></g><defs><clipPath id="a"><path fill="#fff" d="M0 0h55v47H0z" /></clipPath></defs></svg>
    const iconVideoMobile = <svg width="24" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#a)"><path d="M3.209 2.86c-3.777 3.78-3.777 9.911 0 13.692a9.663 9.663 0 0 0 13.676 0l5.666-5.673c.648-.649.648-1.7 0-2.348L16.885 2.86a9.663 9.663 0 0 0-13.676 0Z" fill="#6650F2" /><path d="M14.134 9.028 9.227 6.191a.782.782 0 0 0-1.173.678v5.674c0 .602.652.979 1.173.678l4.907-2.837a.783.783 0 0 0 0-1.356Z" fill="#fff" /></g><defs><clipPath id="a"><path fill="#fff" d="M.376.024h22.66v19.364H.376z" /></clipPath></defs></svg>
    const { t } = useTranslation();
    const { setCarouselOption, setShowModal, carouselOption } = props;
    const [isVisible, setIsVisible] = useState(false);
    const [activeButton, setActiveButton] = useState('1');
    const [galleryPhotos, setGalleryPhotos]: any = useState([]);
    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState(`&folder=${''}`)
    const [infiniteLoader, setInfiniteLoader] = useState({
        page: 1,
        hasMore: false,
    });
    const [filter, setFilter] = useState([
        {
            name: t('All'),
            id: "1"
        },
        {
            name: t('Photos'),
            id: "651b90d91630d2545c7d526d"
        },
        {
            name: t('Videos'),
            id: "651b90d91630d2545c7d526e"
        }
    ])

    const fetchData = async () => {
        if (infiniteLoader.page === 1) { setLoading(true); }
        try {
            const response: any = await eventDetailGallery(props.slug, `records=8&page=${infiniteLoader.page}${params}`);
            if (response?.data?.data) {
                const data = response?.data?.data?.docs
                let folderListData = response?.data?.data?.folders
                const updateData = data.map((item: any) => {
                    const folderInfo = folderListData.find((f: any) => f.id === item.event_gallery_folder_id);
                    const folderName = folderInfo ? folderInfo.name : 'Unknown Folder';

                    return {
                        ...item,
                        folderName
                    };
                });

                setGalleryPhotos((prevData: any) => [...prevData, ...updateData]);
                setFilter(prevFilter => {
                    const newFolders = folderListData || [];
                    const uniqueFolders = newFolders.filter((folder: any) => !prevFilter.some(item => item.id === folder.id));
                    return [...prevFilter, ...uniqueFolders];
                });
                setInfiniteLoader({
                    page: response?.data?.data?.nextPage,
                    hasMore: response?.data?.data?.hasNextPage
                });
                setTimeout(() => {
                    setLoading(false);
                }, 100);
            }
        } catch (error) {
            setGalleryPhotos([])
            console.error(error);
        }
    };

    const handleSelectFilter = (id: any) => {
        setActiveButton(id)
        if (id === '1') {
            setParams('')
        } else if (id === '651b90d91630d2545c7d526d' || id === '651b90d91630d2545c7d526e') {
            setParams(`&type=${id}`)
        } else {
            setParams(`&folder=${id}`)
        }
        handleResetPage()
    }

    const previewGallery = (index: number) => {
        props.setModalGalleryPhotos(galleryPhotos)
        setCarouselOption({ ...carouselOption, start: index })
        setShowModal(true)
    }

    const BackToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Smooth scrolling animation
        });
    }

    const handleScroll = () => {
        if (window.scrollY > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    const handleResetPage = () => {
        setGalleryPhotos([])
        setInfiniteLoader({ page: 1, hasMore: false })
        setLoading(true)
    }

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    return <>
        <div className="p-5">
            <ul className="flex items-center justify-start pb-4 overflow-hidden overflow-x-auto md:pb-7">
                {
                    filter.length > 0 && filter?.map((item: any, index: number) => {
                        return <li key={index} onClick={() => handleSelectFilter(item.id)} className={"mr-[16px] w-100 whitespace-nowrap text-xs md:text-base font-normal"}>
                            <CapsuleButton
                                key={item.name + index}
                                name={item.name}
                                icon={item.icon}
                                active={activeButton === item.id ? true : false}
                                activeBg={'bg-white'}
                                bgColor={'bg-transparent'}
                                border={'border border-ar-purple'}
                                paddingx={'px-2 md:px-5'}
                                paddingy={'py-1 md:py-3'} />
                        </li>
                    })
                }
            </ul>
            {loading ?
                <div className="min-h-[300px] flex justify-center items-center">
                    <Spinner />
                </div>
                :
                <div className="relative">
                    <InfiniteScroll
                        pageStart={infiniteLoader.page}
                        loadMore={fetchData}
                        hasMore={infiniteLoader.hasMore}
                        threshold={200}
                        loader={<h4 className="text-center">Loading...</h4>}
                    >
                        <div className="grid grid-cols-3 gap-1 lg:grid-cols-4">
                            {galleryPhotos.map((item: any, index: number) => {
                                return <div key={index} className="relative cursor-pointer aspect-square" onClick={() => previewGallery(index)}>
                                    {item?.type === '651b90d91630d2545c7d526e' && <> <div className="absolute flex items-center justify-center w-full h-full md:hidden">
                                        {iconVideoMobile}
                                    </div>
                                        <div className="absolute items-center justify-center hidden w-full h-full md:flex">
                                            {iconVideoDesktop}
                                        </div></>}
                                    <img className="object-cover object-center w-full h-full" src={item?.video_thumbnail_url ? item?.video_thumbnail_url : item?.url} alt={item.eventName} />
                                </div>
                            })
                            }
                        </div>
                    </InfiniteScroll>
                    {!galleryPhotos.length && <div className="w-full pb-16 pt-16 md:pt-0 text-center">
                        {iconNoResult}
                        <div className="mb-2 text-2xl font-semibold text-ar-purple">{t('NoResultsFound')}</div>
                        <div className="text-base font-normal">{t('WeCouldntSearch')}<br />{t('TrySearchAgain')}</div>
                    </div>
                    }
                    <div className={`block fixed top-[50%] right-[15px] shadow-none lg:hidden ${isVisible
                        ?
                        'opacity-100 z-30'
                        :
                        'opacity-0 pointer-events-none'}`} onClick={() => BackToTop()}>
                        {iconToTop}
                    </div>
                </div>}
        </div >
    </>
}

export default EventGallery;