import React, { useEffect, useRef, useState } from "react";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import PillButton from "../../../components/PillButton";
import { ReactComponent as FeaturedArtistIcon } from "../../../assets/images/icons/featured-artist.svg"
import ViewMoreBtn from "../../../components/Buttons/ViewMore";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from '@mantine/hooks';
import { useNavigate } from "react-router-dom";
import arrowArtist from '../../../assets/images/icons/arrow-artist.svg';

const FeaturedArtistCarousel = (props: any) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const splideRef = useRef(null);
    const [currentIndex, setCurrentIndex] = useState(0)
    const [currentPanel, setCurrentPanel] = useState<any>(null);
    const [splideInstanceState, setSplideInstanceState] = useState<any>(null);
    const matches = useMediaQuery("(min-width: 1024px)")
    const [data, setData] = useState([]);
    const [options, setOptions] = useState<any>({
        padding: { left: "160px", right: "160px" },
        gap: "2rem",
        //focus: data?.length > 0 ? 0 : 'center',
        drag: false,
        perMove: 1,
        pagination: false,
        arrows: true,
        arrowPath: "M26.665 11.0025L26.9479 10.7196L27.2307 11.0025L26.9479 11.2853L26.665 11.0025ZM1.3317 11.4025C1.11078 11.4025 0.931699 11.2234 0.931699 11.0025C0.931699 10.7816 1.11078 10.6025 1.3317 10.6025L1.3317 11.4025ZM16.8145 0.586295L26.9479 10.7196L26.3822 11.2853L16.2489 1.15198L16.8145 0.586295ZM26.9479 11.2853L16.8145 21.4186L16.2489 20.853L26.3822 10.7196L26.9479 11.2853ZM26.665 11.4025L1.3317 11.4025L1.3317 10.6025L26.665 10.6025L26.665 11.4025Z",
    });

    useEffect(() => {
        if (typeof matches !== "undefined") {
            setOptions({
                ...options,
                ...(matches
                    ?
                    {
                        perPage: 4,
                        type: 'slide',
                    }
                    :
                    {
                        type: 'fade',
                        padding: { left: "34px", right: "34px" },
                        arrows: false
                    }
                )
            })
        }
    }, [matches]);

    const fetchData = () => {
        setTimeout(() => {
            setData(props?.featuredArtist)
        }, 333)
    }

    useEffect(() => {
        fetchData();
        // @ts-ignore
        const splideInstance = splideRef?.current?.splide;
        if (data?.length <= 4) {
            setOptions((prevOptions: any) => ({
                ...prevOptions,
                arrows: false,
            }));
        } else {
            setOptions((prevOptions: any) => ({
                ...prevOptions,
                arrows: true,
            }));
        }
        setSplideInstanceState(splideInstance)
    }, [data]);

    const handlePaginationClick = (index: any) => {
        splideInstanceState.go(index);
        setCurrentIndex(splideInstanceState.index)
    }

    const eventPanelPopUp = (index: any, e: any) => {
        if (e.currentTarget.classList.contains("event-controller")) {
            const siblings = Array.from(e.currentTarget.parentElement?.children || []);
            // @ts-ignore
            const hasActiveSibling = siblings.some(sibling => sibling.classList.contains("active"));
            if (!hasActiveSibling) {
                setCurrentPanel(index);
            } else {
                setCurrentPanel(null);
            }
        }
    }

    const handleOutsideClick = (e: MouseEvent) => {
        // Check if the clicked element is outside the popup panel
        if (e.target instanceof Node && !(e.target as Element).closest(".carousel-popup-panel") && !(e.target as Element).closest(".event-controller")) {
            setCurrentPanel(null);
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleOutsideClick);
        const container = document.getElementById("featured-artist-carousel");
        if (!container) return;

        const leftArrow = container.querySelector(".splide__arrow--prev");
        const rightArrow = container.querySelector(".splide__arrow--next");
        const handleMouseMove = (e: MouseEvent) => {
            const threshold = 133;
            if (e.target instanceof HTMLElement) {
                if (e.x <= threshold || e.target.closest(".is-prev")) {
                    leftArrow?.setAttribute("style", "background-color: rgba(33,31,46,0.9)");
                } else if (
                    e.x >= container.offsetWidth - threshold ||
                    e.target.closest(".is-next")
                ) {
                    rightArrow?.setAttribute("style", "background-color: rgba(33,31,46,0.9)");
                } else {
                    leftArrow?.setAttribute("style", " background-color: rgba(33,31,46,0.6)");
                    rightArrow?.setAttribute("style", "background-color: rgba(33,31,46,0.6)");
                }
            }
        };
        container?.addEventListener("mousemove", handleMouseMove);
        return () => {
            document.removeEventListener("click", handleOutsideClick);
            container.removeEventListener("mousemove", handleMouseMove);
        };
    }, [data]);



    const dateFormat = (start_date: any, end_date: any) => {
        const startDate = new Date(start_date);
        const endDate = new Date(end_date);

        const formattedStartDate = startDate.toLocaleDateString(undefined, { day: 'numeric' });
        const formattedEndDate = endDate.toLocaleDateString(undefined, { day: 'numeric', month: 'long', year: 'numeric' });
        const formattedDateRange = `${formattedStartDate} - ${formattedEndDate}`;

        return formattedDateRange
    }

    return (
        <>
            <div className='bg-ar-black pt-[39px] pb-[49px]  md:pt-[56px] md:pb-[70px]'>
                <div className='container mx-auto xl:max-w-[1080px] max-w-[calc(100%-60px)]'>
                    <h3 className="text-[26px] md:text-[34px] font-semibold text-center text-ar-purple">{t('FeatureArtistesHeader')}</h3>
                </div>
                <div id='featured-artist-carousel'>
                    {data?.length
                        ?
                        <div>
                            <div className='flex lg:hidden relative overflow-auto pt-[36px] pb-[8px] px-[34px]'>
                                {data.map((item: any, i: any) => (
                                    <PillButton key={i} item={item} onClick={() => handlePaginationClick(i)}
                                        className={currentIndex === i ? 'active' : ''}
                                    />
                                ))}
                            </div>
                            <div className='pt-[32px] pb-[40px] md:py-[51px]'>
                                <Splide
                                    className="artistes_carousel"
                                    ref={splideRef}
                                    aria-label="Popular artistes"
                                    options={options}
                                >
                                    {data.map((item: any, i: any) => (
                                        <SplideSlide key={i}>
                                            <div className=''>
                                                <div className='relative h-[308px]'>
                                                    <div className='absolute z-[2] left-1/2 bottom-[21px] mt-[30px] transform -translate-x-1/2 text-center font-semibold text-white w-full px-[15px]'>
                                                        {/* <div className='font-[400] leading-[23px]'>{item?.genre_ids[0]?.name}</div>
                                                        <div className='text-[18px] font-[700] leading-[20px] line-clamp-2'>{item?.name}</div> */}
                                                        <div onClick={() => navigate(`/artistes/${item?.slug}`)} className='cursor-pointer flex justify-center mt-[15px]'>
                                                            <div className='w-[30px] h-[30px] bg-ar-black/[.8] rounded-[50%] flex justify-center items-center'>
                                                                <img className='' src={arrowArtist} alt="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" absolute bg-black w-full z-[1] h-full opacity-[0.2]"></div>
                                                    <img
                                                        className='object-cover w-full h-full'
                                                        src={item?.artist_profile_image_url}
                                                        alt=""
                                                        style={{ maxWidth: "100%" }}
                                                    />
                                                </div>
                                                <div className={`carousel-popup-panel ${currentPanel === i ? 'active' : ''}`}>
                                                    {/* <div className='text-[14px] font-bold'>{`${item?.name} ${t('events')}`}</div> */}
                                                    <span className='bg-ar-purple mt-[9px] block' style={{ width: '100%', height: '1px' }}></span>
                                                    {
                                                        item?.eventData.length === 0 ? (
                                                            <div className='p-[6px] text-[14px] border-b border-ar-purple'>
                                                                {t('NoEvents')}
                                                            </div>
                                                        ) : (
                                                            item?.eventData.map((event: any, i: any) => (
                                                                <div key={i} className='art-event-list' onClick={() => navigate(`/events/${event?.slug}`)}>
                                                                    <div className='w-[43px] h-[43px] min-w-[43px] mr-[11.5px]'>
                                                                        <img className='w-full h-full object-cover' src={event?.cover_image_desktop} alt="aria-event" />
                                                                    </div>
                                                                    <div className='flex flex-col justify-center'>
                                                                        <div className='font-bold line-clamp-2 leading-[13px]'>{event?.title}</div>
                                                                        <div>{dateFormat(event?.start_date, event?.end_date)}</div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        )
                                                    }
                                                    <div className='text-center font-bold text-ar-purple mt-[11px] text-[14px] cursor-pointer' onClick={() => navigate(`/artistes/${item?.slug}`)}>
                                                        {t('ViewMore')}
                                                    </div>
                                                </div>
                                                <div onClick={(e) => eventPanelPopUp(i, e)} style={{ height: '130px', background: 'linear-gradient(180deg, #9B8BFF 0%, #6650F2 100%)' }} className=' event-controller p-[10px] flex flex-col justify-center items-center cursor-pointer'>
                                                    <div><FeaturedArtistIcon /></div>
                                                    <div className='text-center mt-[5px] line-clamp-3 underline'>{t('FeaturedEvents')}</div>
                                                </div>
                                            </div>
                                        </SplideSlide>
                                    ))}
                                </Splide>
                            </div>
                        </div>
                        :
                        null
                    }

                    <div onClick={() => navigate('/artistes')}>
                        <ViewMoreBtn title={t('SeeArtistesDirectoryButton')} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default FeaturedArtistCarousel;