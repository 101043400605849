// import { Button } from 'primereact/button';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/useStore';
import { resetReceiptID } from '../../../redux/slices/ticketSlice';
import { get } from '../../../utils/AxiosRequest';
import PageContainer from '../../../components/PageContainer';
import { useTranslation } from 'react-i18next';
import OrderSummary from '../../../components/Receipt/OrderSummary';
import PaymentDetails from '../../../components/Receipt/PaymentDetails';
import Items from '../../../components/Receipt/Items';
import EventDetails from '../../../components/Receipt/EventDetails';

const Receipt = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { receipt_id }: any = useAppSelector((state) => state.ticket);
    const [purchase, setPurchase] = useState<any>({});

    const fetchData = async () => {
        try {
            if (!receipt_id) {
                return navigate(`/404`)
            }
            const { data } = await get(`/event/purchase/${receipt_id}`);
            if (data?.success && data?.data) {
                setPurchase(data.data)
            }
        } catch (error) {
            console.error('Error:', error);
        }
    }

    useEffect(() => {
        fetchData();
        return () => {
            dispatch(resetReceiptID())
        }
    }, [receipt_id])

    return <>

        <div>

            <div className="px-[20px] py-[24px] md:px-[38px] md:py-[31px]">
                <div onClick={() => navigate(-1)}
                     className='inline-flex items-center justify-start font-bold top-0 cursor-pointer mb-[40px] ml-[0px] md:mt-0 md:ml-0'>
                    <svg width="19" height="19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.875 4.75 7.125 9.5l4.75 4.75" stroke="#fff" strokeWidth="2"/>
                    </svg>
                    {t('Back')}
                </div>
                <h2 className=' text-[26px] font-semibold'>{t('MyReceipt')}</h2>
                <div className=' flex items-center justify-between mb-[20px]'>
                    {purchase?.order_id &&
                        <div className=' text-ar-cyan text-[20px] font-semibold'>{purchase?.order_id}</div>}
                    {/* <div className=' text-ar-purple text-[16px] font-normal cursor-pointer'>Download</div> */}
                </div>
                <OrderSummary order_id={purchase?.order_id} order_date={purchase?.order_date}/>
                <PaymentDetails payment_method={purchase?.payment_method} payment_status={purchase?.payment_status}/>
                <Items order_summary={purchase?.order_summary}/>
                <EventDetails event={purchase?.event}/>
            </div>
        </div>
    </>
}


export default Receipt;
